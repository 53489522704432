import Home from "./home/pages/home/Home";
import "./styles/style.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Area from "./home/pages/area/Area";

import Properties from "./home/pages/properties/Properties";
import Contact from "./home/pages/contact/Contact";
import Propertydetails from "./home/pages/propertyDetails/Propertydetails";
import PropertyByArea from "./home/pages/propertyByArea/PropertyByArea";
import Layout from "./layout/Layout";
import RootLayout from "./layout/RootLayout";
import Developers from "./home/pages/developers/Developers";
import PropertyByDevelopers from "./home/pages/propertyByDevelopers/PropertyByDevelopers";
// import AdminLayout from "./layout/AdminLayout";
// import Dashboard from "./admin/pages/dashboard/Dashboard";
// import List from "./admin/pages/lists/List";
// import AreaList from "./admin/pages/lists/AreaList";
// import DeveloperList from "./admin/pages/lists/DevelopersList";
// import NewProperty from "./admin/pages/createProperty/NewProperty";
// import Login from "./admin/pages/login/Login";
// import NewArea from "./admin/pages/createArea/NewArea";
// import NewDeveloper from "./admin/pages/createDeveloper/NewDeveloper";
import Rent from "./home/pages/rent/Rent";
import Sale from "./home/pages/sale/Sale";
import Launch from "./home/pages/launch/Launch";
import ListingForm from "./home/pages/listingform/ListingForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <Home /> },
      { path: "areas", element: <Area /> },
      { path: "areas/:area", element: <PropertyByArea /> },
      { path: "developers", element: <Developers /> },
      { path: "developers/launching_soon", element: <Launch /> }, //temporary
      { path: "developers/:developerName", element: <PropertyByDevelopers /> },
      { path: "properties", element: <Properties /> },
      { path: "listingform", element: <ListingForm /> },
      { path: "properties-for-rent", element: <Rent /> },
      { path: "properties-for-sale", element: <Sale /> },
      { path: "properties/:name", element: <Propertydetails /> },
      { path: "contact", element: <Contact /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
