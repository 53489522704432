import { useEffect, useMemo, useState } from "react";
import Card from "../cardLoyout/card/Card";
import ListCard from "../cardLoyout/listCard/ListCard";
import Pagination from "../pagination/Pagination";
import "./propertyHandler.scss";

const ProperytHandler = ({ sort, view, pageSize, properties }) => {
  // custom pagination with components and hooks
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize, properties, sort]);
  //change page back to 1 when property or pagesize change. else page still remains in page n and gives 0 properties found
  // change page back to 1 when sort changes. else sort behaves differently
  const data = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return properties.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, properties]);

  //*********sorting properties by price************

  switch (sort) {
    case "priceh":
      data.sort((a, b) => a.price - b.price);
      break;
    case "pricel":
      data.sort((a, b) => b.price - a.price);
      break;

    default:
  }

  return (
    <>
      {data.length > 0 ? (
        <>
          <div className={view ? "listcards" : "cards"}>
            {view
              ? data.map((card) => <ListCard card={card} key={card.id} />)
              : data.map((card) => <Card card={card} key={card.id} />)}
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={properties.length}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      ) : (
        <h2>Sorry. No Properties Found.</h2>
      )}
    </>
  );
};

export default ProperytHandler;
