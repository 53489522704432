import { useState } from "react";
import { useParams } from "react-router-dom";
import PropertyHandler from "../../components/propertyHandler/PropertyHandler";
import ActionBar from "../../components/actionBar/ActionBar";
import { areas, data } from "../../../data/data";
import Guide from "../../components/guide/Guide";

const PropertyByArea = () => {
  const path = useParams().area;
  let properties = [];
  if (path) {
    properties = data.filter(
      (d) => d.area.toLowerCase().split(" ").join("-") === path
    );
  }
  // console.log(properties);

  const [sort, setSort] = useState("name");
  const [listView, setListView] = useState(false);
  const [pageSize, setPageSize] = useState(24);
  return (
    <div className="properties">
      <div className="properties--container">
        <h2>properties</h2>
        <ActionBar
          listView={listView}
          setSort={setSort}
          setListView={setListView}
          setPageSize={setPageSize}
        />
        <Guide data={areas.find((a) => a.link === path)} />
        <PropertyHandler
          view={listView}
          sort={sort}
          pageSize={pageSize}
          properties={properties}
        />
      </div>
    </div>
  );
};

export default PropertyByArea;
