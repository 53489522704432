import "./listCard.scss";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { CurrencyContext } from "../../../contexts/currencyContext/currencyContext";
const LoadingSpinner = () => {
  return <div className="loading-spinner"></div>;
};

const ListCard = ({ card }) => {
  const { currency, loading } = useContext(CurrencyContext);
  const priceString = (p) => {
    return `${currency.name} ${(p * currency.unit)
      .toLocaleString("en-US")
      .split(",")
      .join(" ")}`;
  };
  return (
    <div className="listcard">
      <div className="img">
        <div className="tag">{card.status}</div>
        <img
          src={
            card.img
              ? card.img
              : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
          }
          alt={card.title}
        />
      </div>
      <div className="right">
        <div className="row">
          <div className="content">
            <h3>{card.title}</h3>
            <p>{card.area}</p>
          </div>
          <span className="price">
            {card.price ? (
              loading ? (
                <LoadingSpinner />
              ) : (
                priceString(card.price)
              )
            ) : (
              ""
            )}
          </span>
        </div>
        <div>
          <p>{card.detail}</p>
          <p className="bold">
            {card.roomType === "studio"
              ? "Studio"
              : ` Bedrooms: ${card.bedrooms.toString()}`}
          </p>

          <p className="bold">Type: {card.type}</p>
        </div>

        <Link to={card.link}>
          <button className="btn">View details</button>
        </Link>
      </div>
    </div>
  );
};

export default ListCard;
