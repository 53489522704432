import BannerSearch from "./bannerSearch/BannerSearch";
import "./header.scss";

const Header = () => {
  return (
    <div className="header">
      <div className="banner">
        <div className="banner--left">
          <h1>The best way to find your home</h1>
          <h3>
            One-stop- solution for all your housing needs, from finding to
            connecting and managing with ease
          </h3>
          <div className="banner--btn">
            <svg
              x="0px"
              y="0px"
              viewBox="0 0 774 284"
              enableBackground="new 0 0 774 284"
            >
              <path
                strokeMiterlimit="10"
                d="M220.6,239.6
                          c-3.6-15.5-17.5-27.1-34.1-27.1h-150c-19.3,0-35,15.7-35,35c0,19.3,15.7,35,35,35c0,0,88,0,150,0c169,0,244.9-7.5,291-19
                          c41.3-10.2,114.1-33.7,118-83c4.2-53.5-59.4-67.5-102-54c-47.2,15-52.3,78.2,1,90c58.1,12.9,169.6-53.6,274.7-210"
              ></path>
            </svg>
            <div className="banner--btn__text">get started</div>
          </div>
        </div>
        <div className="banner--right">
          <BannerSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;
