import React, { forwardRef } from "react";
import { BiArea } from "react-icons/bi";
import { BsBuilding } from "react-icons/bs";
import { FaBath, FaCalendarAlt } from "react-icons/fa";
import { GiPlayerTime, GiVillage } from "react-icons/gi";
import { MdBedroomParent, MdLocationPin } from "react-icons/md";
import "./propertyPrint.scss";
import parse from "html-react-parser";

const PropertyPrint = forwardRef(({ property, amenties }, ref) => {
  return (
    <div className="propertyPrint" ref={ref}>
      <div className="logo">
        <img src="/assets/logo.png" alt="property logo" />
      </div>
      <div className="img">
        <img src={property.img} alt={property.title} />
      </div>
      <h3 className="title">
        {property.title} -{" "}
        {property.price.toLocaleString("en-US", {
          // minimumFractionDigits: 2,
          maximumFractionDigits: 0,
        })}{" "}
        AED
      </h3>
      <div className="groups">
        <div className="group">
          <MdLocationPin className="icon" />
          <span className="group-right">
            <span>Location</span>
            <span>{property.area}, Dubai</span>
          </span>
        </div>
        <div className="group">
          <GiVillage className="icon" />
          <span className="group-right">
            <span>Type</span>
            <span>{property.type}</span>
          </span>
        </div>

        <div className="group">
          <BiArea className="icon" />
          <span className="group-right">
            <span>Area</span>
            <span>{property.size} sq Ft</span>
          </span>
        </div>
        <div className="group">
          <BsBuilding className="icon" />
          <span className="group-right">
            <span>Developer</span>
            <span>{property.developer}</span>
          </span>
        </div>

        <div className="group">
          <MdBedroomParent className="icon" />
          <span className="group-right">
            <span>Bedrooms</span>
            <span>{property.bedrooms}</span>
          </span>
        </div>
        <div className="group">
          <FaBath className="icon" />
          <span className="group-right">
            <span>Bathrooms</span>
            <span>{property.bathrooms}</span>
          </span>
        </div>
        <div className="group">
          <GiPlayerTime className="icon" />
          <span className="group-right">
            <span>Status</span>
            <span>{property.status.replace("p", "-P")}</span>
          </span>
        </div>
        <div className="group">
          <FaCalendarAlt className="icon" />
          <span className="group-right">
            <span>Possession Time</span>
            <span>{property.possession}</span>
          </span>
        </div>
      </div>
      <div className="details--left bottomDetails--left">
        <div className="detail">{property.detail}</div>
        <div className="features">
          <h3>Features:</h3>
          <ul>
            {property.features.map((el, i) => (
              <li key={i}>
                <span>{el}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="description">
          <h3>Description:</h3>
          <div>
            {property.description
              ? parse(property.description)
              : property.detail}
          </div>
        </div>
        <div className="amenties">
          <h3>Amenties:</h3>
          <div className="boxes">
            {amenties.map((el) => (
              <div className="box" key={el.id}>
                <div className="img">
                  <img src={el.img} alt={el.title} />
                </div>
                <p>{el.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PropertyPrint;
