export const amentiesData = [
  {
    id: "gym",
    img: "/assets/amenties/gymnasium.svg",
    title: "Fully Equipped Gym ",
  },
  {
    id: "pool",
    img: "/assets/amenties/swimming-pool.png",
    title: "Swimming Pool ",
  },
  {
    id: "parking",
    img: "/assets/amenties/car-parking.svg",
    title: "Car Parking",
  },
  {
    id: "elevators",
    img: "/assets/amenties/elevators.svg",
    title: "High Speed Elevators",
  },
  {
    id: "jog",
    img: "/assets/amenties/jog.png",
    title: "Jogging Trails",
  },
  {
    id: "play",
    img: "/assets/amenties/play-area.png",
    title: "Kids Play Area",
  },
  {
    id: "garden",
    img: "/assets/amenties/garden.png",
    title: "communal garden",
  },
  {
    id: "ac",
    img: "/assets/amenties/central-ac.svg",
    title: "Central AC",
  },
  {
    id: "bbq",
    img: "/assets/amenties/bbq.svg",
    title: "BBQ Area",
  },
  {
    id: "power",
    img: "/assets/amenties/power-backup.svg",
    title: "Power Backup",
  },
  {
    id: "water",
    img: "/assets/amenties/water-supply.svg",
    title: "24 Hours Water Supply",
  },
  {
    id: "reception",
    img: "/assets/amenties/reception.svg",
    title: "Reception Area",
  },
  {
    id: "lobby",
    img: "/assets/amenties/lobby.svg",
    title: "Lobby Area",
  },
  {
    id: "sauna",
    img: "/assets/amenties/sauna.svg",
    title: "Sauna/Steam Rooms",
  },
  {
    id: "security",
    img: "/assets/amenties/security.svg",
    title: "24/7 Security",
  },
  {
    id: "shops",
    img: "/assets/amenties/shop.png",
    title: "Nearby Shops and Malls",
  },
  {
    id: "events",
    img: "/assets/amenties/events.svg",
    title: "Events Space",
  },
  {
    id: "pharmacy",
    img: "/assets/amenties/pharmacy.svg",
    title: "Pharmacy",
  },
  {
    id: "cctv",
    img: "/assets/amenties/cctv.svg",
    title: "CCTV Cameras",
  },
  {
    id: "deck",
    img: "/assets/amenties/deck.png",
    title: "Leisure Deck",
  },
  {
    id: "tram",
    img: "/assets/amenties/tram.png",
    title: "Dubai Tram",
  },
  {
    id: "squash",
    img: "/assets/amenties/squash.png",
    title: "Squash Court",
  },
  {
    id: "tennis",
    img: "/assets/amenties/squash.png",
    title: "Tennis Courts",
  },
  {
    id: "basketball",
    img: "/assets/amenties/basketball.png",
    title: "Basketball Courts",
  },
  {
    id: "mosque",
    img: "/assets/amenties/mosque.png",
    title: "Mosques",
  },
  {
    id: "school",
    img: "/assets/amenties/school.png",
    title: "Schools/Nurseries",
  },
  {
    id: "nursery",
    img: "/assets/amenties/school.png",
    title: "Nursery",
  },
  {
    id: "meeting",
    img: "/assets/amenties/meeting.svg",
    title: "Meeting Rooms",
  },
];
