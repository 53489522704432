import "./bannerSearch.scss";
import Select from "react-select";
import {
  locationOptions,
  statusOptions,
  typeOptions,
} from "../../../../data/Selectdata";
import makeAnimated from "react-select/animated";
import { Range, getTrackBackground } from "react-range";
import { useContext, useState } from "react";

// import { areas } from "../../../data/data";
import { SearchContext } from "../../../contexts/searchContext/searchContext";
import { useNavigate } from "react-router-dom";

const BannerSearch = () => {
  const { INITIAL_SEARCH_STATE, setSearchValue, setShowChips, setOpenModal } =
    useContext(SearchContext);
  const navigate = useNavigate();
  const [search, setSearch] = useState(INITIAL_SEARCH_STATE);
  const animatedComponents = makeAnimated();
  const STEP = 1000;
  const MIN = INITIAL_SEARCH_STATE.price[0];
  const MAX = INITIAL_SEARCH_STATE.price[1];
  const STEPROOM = 1;
  const MINROOM = 0;
  const MAXROOM = 20;

  // (''+number).length

  // const locationHandler = (choice) => {
  //   const value = choice.map((c) => c.value);
  //   setSearch({ ...search, area: value });

  // };
  // useEffect(() => {
  //   setSearchValue({ ...search });
  // }, [search, setSearchValue]);

  const priceHandler = (arr) => {
    let p = new Array(2);
    p = arr.map((a) =>
      a > 999
        ? a > 999999
          ? Math.ceil(a / Math.pow(10, 6)) * 1000000
          : (a / Math.ceil(Math.pow(10, 3))) * 1000
        : a
    );
    setSearch({ ...search, price: p });
  };
  const priceLabel = (a) => {
    return a > 999
      ? a > 999999
        ? Math.ceil(a / Math.pow(10, 6)) + "m"
        : a / Math.ceil(Math.pow(10, 3)) + "k"
      : a;
  };
  const submitHandler = (e) => {
    e.preventDefault();
    setSearchValue((prev) => ({ ...prev, ...search }));
    navigate("/properties");
    // setSubmit(true);
    setShowChips(true);
    setOpenModal(false);
    setSearch({
      ...search,
      INITIAL_SEARCH_STATE,
    });
  };

  return (
    <form className="form" onSubmit={submitHandler}>
      {/* property location select */}
      <div className="form--group">
        <label htmlFor="location">PROPERTY LOCATION</label>
        <Select
          // id="location"
          components={animatedComponents}
          // isMulti
          name="location"
          options={locationOptions}
          className="banner--select__container"
          classNamePrefix="banner--select"
          placeholder="Any"
          // closeMenuOnSelect={false}
          // onChange={locationHandler}
          // onChange={(choice) =>
          //   setSearch({ ...search, area: choice.value.toLowerCase() })
          // }
          onChange={(choice) =>
            setSearch(
              choice
                ? { ...search, area: choice.value.toLowerCase() }
                : { ...search, area: "" } //to clear the entry
            )
          }
          isClearable={search.area}
        />
      </div>
      {/* property status select */}
      <div className="form--group">
        <label htmlFor="status">PROPERTY STATUS</label>
        <Select
          name="status"
          options={statusOptions}
          className="banner--select__container"
          classNamePrefix="banner--select"
          // value={search.status}
          // onChange={(choice) =>
          //   setSearch({ ...search, status: choice.value.toLowerCase() })
          // }
          onChange={(choice) =>
            setSearch(
              choice
                ? { ...search, status: choice.value.toLowerCase() }
                : { ...search, status: "" } //to clear the entry
            )
          }
          isClearable={search.status}
          placeholder="Any"
        />
      </div>
      {/* property type select */}
      <div className="form--group">
        <label htmlFor="type">PROPERTY TYPE</label>
        <Select
          name="type"
          options={typeOptions}
          className="banner--select__container"
          classNamePrefix="banner--select"
          // onChange={(choice) =>
          //   setSearch({ ...search, type: choice.value.toLowerCase()})
          // }
          onChange={(choice) =>
            setSearch(
              choice
                ? { ...search, type: choice.value.toLowerCase() }
                : { ...search, type: "" } //to clear the entry
            )
          }
          isClearable={search.type}
          placeholder="Any"
        />
      </div>
      {/* price range  */}
      <div className="form--group">
        <label htmlFor="type">PRICE(AED)</label>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Range
            values={search.price}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={priceHandler}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "7rem",
                  display: "flex",
                  width: "90%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "5px",
                    width: "100%",
                    borderRadius: "4px",
                    background: getTrackBackground({
                      values: search.price,
                      colors: ["#fff", "#ffffff72", "#fff"],
                      min: MIN,
                      max: MAX,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ index, props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "20px",
                  width: "20px",
                  borderRadius: "4px",
                  backgroundColor: "#FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 6px #AAA",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-28px",
                    color: "#444",
                    fontWeight: "bold",
                    fontSize: "14px",

                    padding: "4px",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                  }}
                >
                  {priceLabel(search.price[index])}
                </div>
                <div
                  style={{
                    height: "16px",
                    width: "5px",
                    backgroundColor: isDragged ? "#548BF4" : "#CCC",
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
      {/*  range for bedrooms */}
      <div className="form--group">
        <label htmlFor="type">BEDROOMS</label>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Range
            values={search.bedrooms}
            step={STEPROOM}
            min={MINROOM}
            max={MAXROOM}
            onChange={(choice) => setSearch({ ...search, bedrooms: choice })}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "7rem",
                  display: "flex",
                  width: "90%",
                  padding: "0rem 0",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "5px",
                    width: "100%",
                    borderRadius: "4px",
                    background: getTrackBackground({
                      values: search.bedrooms,
                      colors: ["#fff", "#ffffff72", "#fff"],
                      min: MINROOM,
                      max: MAXROOM,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ index, props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "20px",
                  width: "20px",
                  borderRadius: "4px",
                  backgroundColor: "#FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 6px #AAA",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-28px",
                    color: "#444",
                    fontWeight: "bold",
                    fontSize: "14px",
                    padding: "4px",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                  }}
                >
                  {search.bedrooms[index] >= 20
                    ? search.bedrooms[index].toFixed(0)
                    : search.bedrooms[index].toFixed(0)}
                </div>
                <div
                  style={{
                    height: "16px",
                    width: "5px",
                    backgroundColor: isDragged ? "#548BF4" : "#CCC",
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
      <button className="form--btn" type="submit">
        see results
      </button>
    </form>
  );
};

export default BannerSearch;
