import useScrollAnimate from "../../hooks/useScrollAnimate";
import "./ListSection.scss";

const ListSection = () => {
  const { ref, inView } = useScrollAnimate();
  return (
    <div className="listsec">
      <div
        className={inView ? "listsec--container animate" : "listsec--container"}
        ref={ref}
      >
        <div className="col">
          <h1>List Your Properties With Us</h1>

          <a href="/listingform" role="button" class="listbtn">
            List Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default ListSection;
