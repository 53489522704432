import { createContext, useState } from "react";
import { data } from "../../../data/data";

export const PropertyContext = createContext(data);

export const PropertyContextProvider = ({ children }) => {
  const [properties, setProperties] = useState(data);
  return (
    <PropertyContext.Provider value={{ properties, setProperties }}>
      {children}
    </PropertyContext.Provider>
  );
};
