import "./guide.scss";

const Guide = ({ data }) => {
  return (
    <div className="guide">
      <h3>
        Area Guide: <span>{data.name}</span>
      </h3>
      <p>{data.guide}</p>
    </div>
  );
};

export default Guide;
