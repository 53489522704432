import "./footer.scss";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaTiktok,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer--container">
        <div className="col">
          <h3>about</h3>
          <p>
            Your premier destination for exceptional real estate solutions in
            Dubai. With a passion for excellence, we offer personalized
            solutions, unrivaled expertise, and a diverse portfolio of
            properties in the most coveted locations. Trust us to make your real
            estate journey seamless and rewarding.
          </p>
        </div>
        <div className="col">
          <h3>connect on social media</h3>
          <div className="social">
            <a href="https://www.facebook.com/aspectglobalrealestate?mibextid=ZbWKwL">
              <FaFacebook className="icon" />
            </a>
            <a href="https://instagram.com/aspectglobal?igshid=OGQ5ZDc2ODk2ZA==">
              <FaInstagram className="icon" />
            </a>
            <a href="https://x.com/AspectGlobal_RE?t=T2w-lRIeCr25xVLOp0k2CA&s=09">
              <FaTwitter className="icon" />
            </a>
            <a href="https://www.linkedin.com/company/99911226/admin/feed/posts/">
              <FaLinkedin className="icon" />
            </a>

            <a href="https://www.tiktok.com/@aspectglobal?_t=8fvQRREUfKx&_r=1">
              <FaTiktok className="icon" />
            </a>
          </div>
        </div>
        <div className="col">
          <h3>contact us</h3>

          <div className="sub-col">
            <h4>email</h4>
            <a href="mailto:sales@aspect-global.com">sales@aspect-global.com</a>
          </div>
          <div className="sub-col">
            <h4>phone</h4>
            <a href="tel:+97145708788">+971 4 570 8788</a>
          </div>
          <div className="sub-col">
            <h4>address</h4>
            <p>Tower 5B, Al Safa Park Complex, Dubai - United Arab Emirates</p>
          </div>
        </div>
      </div>
      <span>© 2023 Aspect-global Properties. All rights reserved.</span>
    </div>
  );
};

export default Footer;
