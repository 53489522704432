import "./propertyDetails.scss";

import Form from "../contact/form/Form";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import { data } from "../../../data/data";
import { useContext } from "react";
import { CurrencyContext } from "../../contexts/currencyContext/currencyContext";
import parse from "html-react-parser";
import { amentiesData } from "../../../data/amenties";
import { GiPlayerTime, GiVillage } from "react-icons/gi";
import { MdBedroomParent, MdLocationPin } from "react-icons/md";
import { BsBuilding } from "react-icons/bs";
import { BiArea } from "react-icons/bi";
import { FaBath, FaCalendarAlt, FaStar } from "react-icons/fa";
import PropertySlider from "../../components/propertySlider/PropertySlider";
import ActionButtons from "../../components/actionButtons/ActionButtons";
import SimilarProperties from "../../components/similarProperties/SimilarProperties";

import React from "react";
import { SizeContext } from "../../contexts/sizeContext/SizeContext";
import { useEffect } from "react";

const LoadingSpinner = () => {
  return <div className="loading-spinner"></div>;
};

const Propertydetails = () => {
  const path = useParams().name;
  const { currency, loading } = useContext(CurrencyContext);
  const { checked } = useContext(SizeContext);

  const property = data.find((el) => path === el.link);

  const navigate = useNavigate();
  useEffect(() => {
    if (!property) {
      return navigate("/");
    }
  }, [property, navigate]);

  const amenties = () => {
    const amentyList = property.amenties;
    const a = amentiesData.filter((el) =>
      amentyList.some((amenty) => amenty === el.id)
    );
    return a;
  };

  const priceString = (p) => {
    return `${currency.name} ${(p * currency.unit).toLocaleString("en-US", {
      // minimumFractionDigits: 2,
      maximumFractionDigits: 0,
    })}`;
  };

  const areaSize = (size) => {
    const arr = size
      .map((el) => {
        return checked ? el * (0.092903).toFixed(2) : el;
      })
      .toString()
      .replace(",", " - ");
    return checked ? (
      <span>
        {arr} M<sup>2</sup>
      </span>
    ) : (
      <span>{arr} Sq Ft</span>
    );
  };

  // {property.type.split("/")[0]} type {/* to print first word of type */}
  const rentType = property?.rent ? "rent" : "sale";

  const similarProperties = function () {
    const array = ["area", "bedrooms", "type", "status"];
    let similar = [];
    array.forEach((searchkey) => {
      if (similar.length > 5) return;
      const filterForCurrentSearchKey = data.filter(
        (el) => el[searchkey] === property[searchkey] && el.id !== property.id
      );
      similar = [...new Set([...similar, ...filterForCurrentSearchKey])].slice(
        0,
        5
      );
    });
    return similar;
  };

  return (
    property && (
      <div className="propertyDetails">
        <div className="propertyDetails__header">
          <div className="breadCrumbs">
            <NavLink to={"/home"}>HOME</NavLink>
            <NavLink to={"/properties"}>PROPERTIES</NavLink>
            <NavLink to={`/properties-for-${rentType}`}>
              {`properties for ${rentType}`}
            </NavLink>
            <NavLink to={`/areas/${property.area.split(" ").join("_")}`}>
              {property.area.toUpperCase()}
            </NavLink>
            <span>{property.title.toUpperCase()}</span>
          </div>
        </div>
        <div className="propertyDetails__container">
          <div className="details topDetails">
            <div className="details--left imageSlider">
              <PropertySlider
                images={property.images}
                type={rentType}
                title={property.title}
              />
            </div>

            <div className="details--right topDetails--right">
              {property.featured && (
                <div className="featured--tag">
                  <FaStar /> featured
                </div>
              )}
              <h3>{property.title}</h3>
              <h3 className="price">
                {loading ? <LoadingSpinner /> : priceString(property.price)}
              </h3>

              <div className="groups">
                <div className="group">
                  <MdLocationPin className="icon" />
                  <span className="group-right">
                    <span>Location</span>
                    <span>{property.area}, Dubai</span>
                  </span>
                </div>
                <div className="group">
                  <GiVillage className="icon" />
                  <span className="group-right">
                    <span>Type</span>
                    <span>{property.type}</span>
                  </span>
                </div>

                <div className="group">
                  <BiArea className="icon" />
                  <span className="group-right">
                    <span>Area</span>
                    <span>
                      {property.size ? (
                        areaSize(property.size)
                      ) : (
                        <a href="tel:+97145708788">Call Us To Know More</a>
                      )}
                    </span>
                  </span>
                </div>
                <div className="group">
                  <BsBuilding className="icon" />
                  <span className="group-right">
                    <span>Developer</span>
                    <span>
                      {property.developer ? (
                        property.developer
                      ) : (
                        <a href="tel:+97145708788">Call Us To Know more</a>
                      )}
                    </span>
                  </span>
                </div>

                <div className="group">
                  <MdBedroomParent className="icon" />
                  {property.roomType === "studio" ? (
                    <span style={{ fontSize: "1.6rem" }}>Studio</span>
                  ) : property.roomType === "office" ? (
                    <span style={{ fontSize: "1.6rem" }}>Office</span>
                  ) : (
                    <span className="group-right">
                      <span>Bedrooms</span>
                      <span>{property.bedrooms}</span>
                    </span>
                  )}
                </div>
                <div className="group">
                  <FaBath className="icon" />
                  <span className="group-right">
                    <span>Bathrooms</span>
                    <span>
                      {" "}
                      {property.bathrooms ? (
                        property.bathrooms
                      ) : (
                        <a href="tel:+97145708788">Call Us To Know More</a>
                      )}
                    </span>
                  </span>
                </div>
                <div className="group">
                  <GiPlayerTime className="icon" />
                  <span className="group-right">
                    <span>Status</span>
                    <span>{property.status.replace("p", "-P")}</span>
                  </span>
                </div>
                <div className="group">
                  <FaCalendarAlt className="icon" />
                  <span className="group-right">
                    <span>Possession Time</span>
                    <span>
                      {property.possession ? (
                        <span>{property.possession}</span>
                      ) : (
                        <a href="tel:+97145708788">Call us to know more</a>
                      )}
                    </span>
                  </span>
                </div>
              </div>
              <ActionButtons
                property={property}
                amenties={amenties()}
                price={priceString}
              />
            </div>
          </div>
          <div className="details bottomDetails">
            <div className="details--left bottomDetails--left">
              <div className="description">
                <h3>{property.title}: Overview</h3>
                <p>{property.detail}</p>
              </div>
              <div className="amenties">
                <h3>Amenties:</h3>
                <div className="boxes">
                  {amenties().map((el) => (
                    <div className="box" key={el.id}>
                      <div className="img">
                        <img src={el.img} alt={el.title} />
                      </div>
                      <p>{el.title}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="features">
                <h3>Features:</h3>
                <ul>
                  {property.features.map((el, i) => (
                    <li key={i}>
                      <span>{el}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {property.description && (
                <div className="description">{parse(property.description)}</div>
              )}
              {property.src && (
                <div className="propertyMap">
                  <h3>Find the Property in map:</h3>

                  <iframe
                    title={property.title}
                    src={property.src}
                    width="100%"
                    height="450"
                    loading="lazy"
                    className="map"
                  ></iframe>
                </div>
              )}
            </div>
            <div className="details--right bottomDetails--right">
              <div className="detailForm">
                <h3>For More Info:</h3>
                <Form />
              </div>
              <SimilarProperties properties={similarProperties()} />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Propertydetails;
