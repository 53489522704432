import "./topbar.scss";
import {
  FaCoins,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaTwitter,
  FaTiktok,
} from "react-icons/fa";
import Switch from "react-switch";
import { useContext } from "react";
import { CurrencyContext } from "../../contexts/currencyContext/currencyContext";
import { SizeContext } from "../../contexts/sizeContext/SizeContext";

const Topbar = () => {
  const { dispatch, setChangeCurrency, currency } = useContext(CurrencyContext);

  const { checked, setChecked } = useContext(SizeContext);

  // console.log(currency);
  // console.log(changeCurrency);
  const handleChange = (event) => {
    setChecked(!checked);
  };
  const currencyHandler = (e) => {
    const type = e.target.value.toUpperCase();
    setChangeCurrency(true);
    dispatch({ type: type });
  };

  return (
    <div className="topbar">
      <div className="topbar--container">
        <div className="topbar--container__left">
          <div className="select">
            <FaCoins className="icon" />
            <select
              defaultValue={currency.type.toLowerCase()}
              onChange={currencyHandler}
            >
              <option value={"aed"}>AED</option>
              <option value={"usd"}>USD</option>
              <option value={"gbp"}>GBP</option>
              <option value={"eur"}>EUR</option>
              <option value={"rub"}>RUB</option>
            </select>
          </div>
          <div className="switch">
            <span>sq ft</span>
            <Switch
              onChange={handleChange}
              checked={checked}
              onColor="#f9c356"
              onHandleColor="#fff"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="material-switch"
            />
            <span>
              m<sup>2</sup>
            </span>
          </div>
        </div>
        <div className="topbar--container__right">
          <a className="tel" href="tel:+97145708788">
            <FaPhoneAlt />

            <span>+971 4 570 8788</span>
          </a>
          <div className="social">
            <a href="https://www.facebook.com/aspectglobalrealestate?mibextid=ZbWKwL">
              <FaFacebook className="icon" />
            </a>
            <a href="https://www.instagram.com/aspectglobal/">
              <FaInstagram className="icon" />
            </a>
            <a href="https://twitter.com/AspectGlobal_RE">
              <FaTwitter className="icon" />
            </a>

            <a href="https://www.linkedin.com/company/apect-global/?viewAsMember=true ">
              <FaLinkedin className="icon" />
            </a>

            <a href="https://www.tiktok.com/@aspectglobal?_t=8fvQRREUfKx&_r=1">
              <FaTiktok className="icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
