import { createContext, useState } from "react";

export const SizeContext = createContext();

export const SizeContextProvider = ({ children }) => {
  const [checked, setChecked] = useState(false);
  return (
    <SizeContext.Provider value={{ checked, setChecked }}>
      {children}
    </SizeContext.Provider>
  );
};
