// if you are adding villa please add type:'villa/mansion' , for townhouse -> type: "townhouses", for apartment -> type: "apartments/flats"

export const data = [
  {
    id: "emaar1",
    title: "Park Heights (2 Bed)",
    area: "dubai hills estate",
    src: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14451.22246319279!2d55.2469174!3d25.1084406!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f694a8dfc9023%3A0xef2a650cecca6631!2sMulberry%201%20-%20Dubai%20Hills%20Estate!5e0!3m2!1sen!2sae!4v1680757997801!5m2!1sen!2sae",
    type: "apartments/flats",
    price: 2999999,
    size: [1565],
    status: "ready to move",
    possession: "Ready to move",
    link: "mulberry-1",
    featured: true,
    developer: "emaar",
    detail:
      "Situated in Mulberry 1 (B2), Dubai Hills Estate - This is a beautiful 2 Bedroom 'Corner Unit' Apartment with a massive L shaped terrace with pool and park views.",
    description:
      "<h4>Sale Price - AED 3,000,000 </h4><p>For a viewing call: <a href='0502675696'>050 267 5696</a></p><p>Mulberry 1 is a well-designed apartment complex and it consists of high quality apartments with each home featuring high-level finishes and amenities and it's strategically located to overlook the lush green landscapes. Mulberry 1 homes include common terrace areas where residents can experience uninterrupted views of the green park and Dubai skyline. Residents have access to an array of outdoor lifestyle amenities including a tennis academy, an outdoor pool for adults and children, multi-purpose trails, and more.</p><p>One of the best areas for families in Dubai with high level security in the whole of Dubai Hills Estate community. This property is vacant, ready to move in and it's just a couple of minutes drive away from the prestigious Dubai Hills Mall.</p>",
    features: [
      "Size: 1565 Sq Ft",
      "Terrace Area: 168 Sq. Ft",
      "Beautiful Living & Dining room area",
      "Beautiful Terrace Views",
      "Fully equipped kitchen",
      "Separate Laundry Area",
      "Fully Covered Parking",
      "24 Hrs Security",
    ],
    bedrooms: 2,
    bathrooms: 3,
    amenties: [
      "gym",
      "pool",
      "security",
      "nursery",
      "meeting",
      "shops",
      "reception",
      "garden",
    ],
    img: "/assets/contents/emaar/park-heights/17.jpg",
    images: [
      "/assets/contents/emaar/park-heights/1.jpg",
      "/assets/contents/emaar/park-heights/3.jpg",
      "/assets/contents/emaar/park-heights/4.jpg",
      "/assets/contents/emaar/park-heights/6.jpg",
      "/assets/contents/emaar/park-heights/7.jpg",
      "/assets/contents/emaar/park-heights/9.jpg",
      "/assets/contents/emaar/park-heights/12.jpg",
      "/assets/contents/emaar/park-heights/8.jpg",
      "/assets/contents/emaar/park-heights/5.jpg",
      "/assets/contents/emaar/park-heights/13.jpg",
      "/assets/contents/emaar/park-heights/14.jpg",
      "/assets/contents/emaar/park-heights/11.jpg",
      "/assets/contents/emaar/park-heights/15.jpg",
      "/assets/contents/emaar/park-heights/16.jpg",
      "/assets/contents/emaar/park-heights/17.jpg",
      "/assets/contents/emaar/park-heights/18.jpg",
    ],
  },

  {
    id: "emaar2",
    title: "Park Horizon",
    area: "dubai hills estate",
    type: "apartments/flats",
    price: 2028888,
    size: [1043],
    status: "offplan",
    possession: "Ready to move",
    link: "park-horizon",
    developer: "emaar",
    detail:
      "Park Horizon is located at the heart of a vibrant community, offering residents everything they need to embrace a modern lifestyle tailored towards contemporary convenience and natural beauty. From an eclectic array of dining and retail experiences, and an abundance of recreational activities, to world-class health and educational facilities, your dream home in Park Horizon is perfect for modern, community-centred living.",
    description:
      "<p>Park Horizon offers residents world-class amenities and state-of-the-art facilities at every turn, all conveniently located at the centre of a meticulously master-planned community. The ideal home for families, couples and everyone in between, this modern two-tower development has it all.</p>",
    features: [
      "MULTI-PURPOSE ROOM",
      "SWIMMING POOL & KIDS’ POOL",
      "LANDSCAPED PODIUM & OUTDOOR AREAS",
      "KIDS’ ROOM",
      "FULLY EQUIPPED GYM",
      "DIRECT ACCESS TO DUBAI HILLS PARK",
    ],
    bedrooms: 2,
    // bathrooms: 0,
    amenties: ["gym", "pool", "security", "play", "shops", "garden"],
    img: "/assets/contents/emaar/park-horizon/cardImage.webp",
    images: [
      "/assets/contents/emaar/park-horizon/img1.webp",
      "/assets/contents/emaar/park-horizon/img2.webp",
      "/assets/contents/emaar/park-horizon/img3.webp",
      "/assets/contents/emaar/park-horizon/img4.webp",
    ],
  },
  {
    id: "emaar3",
    title: "Lime Gardens",
    src: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14451.49888434656!2d55.2452713!3d25.1061018!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69f043724527%3A0xa973d6f8ec435765!2sLime%20Gardens%20Emaar!5e0!3m2!1sen!2sae!4v1689851582808!5m2!1sen!2sae",
    area: "dubai hills estate",
    type: "apartments/flats",
    price: 2927888,
    size: [1616],
    status: "offplan",
    possession: "Ready to move",
    link: "lime-gardens",
    developer: "emaar",
    bedrooms: 1,
    // bathrooms: 0,
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "basketball",
      "pharmacy",
      "tennis",
      "school",
    ],
    img: "/assets/contents/emaar/lime-gardens/cardImage.webp",
    images: [
      "/assets/contents/emaar/lime-gardens/img1.webp",
      "/assets/contents/emaar/lime-gardens/img2.webp",
      "/assets/contents/emaar/lime-gardens/img3.webp",
      "/assets/contents/emaar/lime-gardens/img4.webp",
    ],
    detail:
      "Lime Gardens is the latest residential tower development by Emaar Properties that features 1, 2 & 3 bedroom apartments and duplex townhouses at Dubai Hills Estate. The beautiful and astounding views of the place make it more relevant and unique from other residential developments. You are getting an excellent opportunity to experience the fresh luscious morning with an iconic view of Burj Khalifa and the Dubai skyline.",
    description:
      "<p>It is situated in a well-connected region of Dubai. For home buyers, it can be the perfect address because they can find all the desired destinations within a short distance. The greenery of the place surrounds you with its gloriousness and offers natural serenity to enjoy your life by staying connected with nature too. Greenery should be a part of every living being, and everyone needs that amazing kind of freshness and coolness around them</p><h3>Locations</h3><p></p><ul><li>Burj Khalifa – 12 minutes</li><li>The Dubai Mall – 12 minutes</li><li>Dubai Marina – 15 minutes</li><li>Dubai International Airport – 20 minutes</li><li>Al Maktoum International Airport – 25 minutes</li></ul>",
    features: [
      "New development promoting a new concept of living",
      "A choice of 1, 2 & 3 bedroom apartments and townhouses",
      "Near to an 18- hole championship golf course",
      "Prime location with easy connectivity options",
      "Surrounded by landscaped gardens and parks",
      "Access to some of the finest amenities of Dubai Hills Estate",
      "Residences offering mesmerizing views of the adjoining areas",
    ],
  },
  {
    id: "emaar4",
    title: "Elvira",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.849667999469!2d55.23764478493298!3d25.106949720448497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69912240b363%3A0xec4653f612f34954!2sElvira%20Dubai%20Hills%20Estate!5e0!3m2!1sen!2sae!4v1689853861902!5m2!1sen!2sae",
    area: "dubai hills estate",
    type: "apartments/flats",
    price: 2215888,
    size: [1064],
    status: "offplan",
    link: "elvira",
    developer: "emaar",
    bedrooms: 2,
    // bathrooms: 0,
    amenties: ["gym", "pool", "security", "play", "shops", "garden", "bbq"],
    img: "/assets/contents/emaar/elvira/cardImage.webp",
    images: [
      "/assets/contents/emaar/elvira/img1.webp",
      "/assets/contents/emaar/elvira/img2.webp",
      "/assets/contents/emaar/elvira/img3.webp",
      "/assets/contents/emaar/elvira/img4.webp",
    ],
    detail:
      "Elvira is a refuge of luxury living within Dubai Hills Estate, and it’s only a few minutes away from the hustle and bustle of Downtown Dubai, where you’ll find the city’s business, financial, and retail emporia. This community has everything you might want, including spacious homes with balconies and terraces, open green areas surrounded with trees, and convenient proximity to a variety of educational, retail, medical, recreational, and social amenities.",
    description:
      "<p>Take back the precious time that is so frequently lost in transit. With the new metro lines connecting directly to Al Maktoum International Airport and Downtown Dubai just 12 minutes away by car, your professional and personal lives become increasingly intertwined while remaining serenely distinct. Elvira's exceptional position in the heart of our city takes you closer to the things that matter most.</p><p>With every amenity you could every need just a stone’s throw away, Elvira is perfectly positioned at the centre of a comprehensive community, boasting world-class health and education facilities, exceptional leisure and wellness experiences, and a premium retail and lifestyle destination.</p>",
    features: [
      "1 to 3 bedroom apartments, townhouses and duplexes",
      "Extraordinary lifestyle in a natural green setting",
      "Prime location at Dubai Hills Estate",
      "The finest amenities and facilities at the service",
      "Awe-inspiring views of Dubai Marina and Skylines",
      "Experience the Parkland Paradise immersed in nature",
    ],
  },
  {
    id: "emaar5",
    title: "Golf Grand",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115659.8864463826!2d55.148692055227485!3d25.05539634745304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69eab24b0f63%3A0x9761af9ad7d07b9!2sGolf%20Grand!5e0!3m2!1sen!2sae!4v1689855958347!5m2!1sen!2sae",
    area: "dubai hills estate",
    type: "apartments/flats",
    price: 2030888,
    size: [1147],
    status: "ready to move",
    link: "golf-grand",
    developer: "emaar",
    bedrooms: 2,
    // bathrooms: 0,
    amenties: ["gym", "pool", "security", "play", "shops", "garden", "park"],
    img: "/assets/contents/emaar/golf-grand/cardImage.webp",
    images: [
      "/assets/contents/emaar/golf-grand/img1.webp",
      "/assets/contents/emaar/golf-grand/img2.webp",
      "/assets/contents/emaar/golf-grand/img3.webp",
      "/assets/contents/emaar/golf-grand/img4.webp",
    ],
    detail:
      "Emaar Golf Grand at Dubai Hills Estate is the latest residential epitome offering luxury 1, 2 & 3 bedroom apartments, adding a sense of suburban bliss to your modern living. Feel the excitement in these top-rated environment offering the most luxurious and sophisticated lifestyle possible with the views of 18-hole championship golf course. Serving the residents with top class convenience and comfort which is inevitable in the dynamic Dubai Hills Estate.",
    description:
      "<p>Come home to Golf Grand and bask in the tranquillity of Dubai Hills Estate’s greenery and open spaces. Enjoy the sweeping vistas of the Dubai Hills Golf Course as well as the beauty of the low-rise communities, adding a sense of suburban bliss to your modern urban luxury experience.</p><p>This distinctly green and family-friendly neighbourhood has charmed residents and visitors alike with its quiet, unpretentious character and easy living amongst the many stores and conveniences nearby. This is your quiet urban piece of Dubai. Yet all the attractions and benefits of living in the city centre are just around the corner. Golf Grand enjoys proximity to world-class healthcare and educational facilities, a central park, a championship golf course, and Dubai Hills Mall, Dubai’s newest comprehensive lifestyle and shopping destination.</p>",
    features: [
      "New development by Emaar Properties",
      "A choice of premium design 1, 2 & 3 bedroom apartments",
      "Prime and exclusive location at Dubai Hills Estate",
      "World class development laced with exclusive services",
      "Wonderful views and sights at the comfort of your home",
      "Amenities in sports, health, play, fun and leisure at your service",
      "World class access to the mainland and city’s key famous destinations",
    ],
  },
  {
    id: "emaar6",
    title: "Bayview by address resorts",
    area: "emaar beachfront",
    type: "apartments/flats",
    price: 6636888,
    size: [1380],
    status: "ready to move",
    link: "bayview-by-address-resorts",
    developer: "emaar",
    bedrooms: 2,
    // bathrooms: 0,
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "park",
      "bbq",
      "sauna",
    ],
    img: "/assets/contents/emaar/bayview-by-address-resorts/cardImage.webp",
    images: [
      "/assets/contents/emaar/bayview-by-address-resorts/img1.webp",
      "/assets/contents/emaar/bayview-by-address-resorts/img2.webp",
      "/assets/contents/emaar/bayview-by-address-resorts/img3.webp",
      "/assets/contents/emaar/bayview-by-address-resorts/img4.webp",
    ],
    detail:
      "Bayview by Address Resort at Emaar Beachfront carries the future in its details and promises a life that will fulfil your dreams of luxurious living by the sea and unparalleled modern opulence; a one-of-a-kind opportunity to celebrate the Dubai Life in the city’s most exclusive coastal development.",
    description:
      "<p>With its prime location at Emaar Beachfront and stunning views of the Arabian Gulf, it is going to become a symbol of elegance and sophistication. The development offers easy access through the major highways, so residents are going to experience hassle-free access to the rest of the city without any problem.</p><h3>Locations:</h3><ul><li>Al Maktoum International Airport - 25 minutes</li><li>Downtown Dubai - 15 minutes</li><li>Dubai International Airport - 25 minutes</li><li>Dubai Marina - 5 minutes</li><li>Sheikh Zayed Road - 5 minutes</li></ul>",
    features: [
      "1 to 4 bedroom apartments and 4 & 6 bedroom penthouses",
      "1.5 KM of Pristine Beach Destination in Dubai",
      "Prime and exclusive location at Emaar Beachfront",
      "Steps away from the Marina and Yacht Club",
      "Attractive and feasible payment plan options",
      "Well-connected paradise with waterfront amenities",
      "World-class leisure and entertainment options are available",
      "Direct access to the promenade, with a wide array of retails and dining",
    ],
  },
  {
    id: "emaar7",
    title: "Seapoint Emaar Beachfront",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.103443063262!2d55.1380052758745!3d25.098359477774668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6bd06e726d93%3A0x75181bb48d874f7!2sSeapoint%20Beachfront!5e0!3m2!1sen!2sae!4v1690005105061!5m2!1sen!2sa",
    area: "emaar beachfront",
    type: "apartments/flats",
    price: 7246888,
    size: [1463],
    status: "ready to move",
    link: "seapoint-emaar",
    developer: "emaar",
    bedrooms: 2,
    // bathrooms: 0,
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "park",
      "bbq",
      "sauna",
    ],
    img: "/assets/contents/emaar/seapoint-emaar/cardImage.webp",
    images: [
      "/assets/contents/emaar/seapoint-emaar/img1.webp",
      "/assets/contents/emaar/seapoint-emaar/img2.webp",
      "/assets/contents/emaar/seapoint-emaar/img3.webp",
    ],
    detail:
      "Seapoint combines the aspirational dream of high-end beachfront living with the sophistication of urban cool, incredible design flair and unrivalled levels of finish.Experience Dubai’s opulent way of life indoors and out, through texture, natural materials, abundant natural light and uninterrupted views.",
    description:
      "<p>Seapoint is the exclusive waterfront development at Emaar Beachfront, Dubai offering luxury class 1, 2, 3 & 4 bedroom apartments and 4, 5 & 6 bedroom penthouses developed by Emaar Properties. With access to the flanked paradise of 450 meters of white sandy beaches, lined with its leisure promenade available at service. The two exclusive designed tower comes with boasted views of the Sea and Dubai Marina as well as podium level facilities and a largest swimming pool at service</p><p>Being located at the most prestigious locations at the beachfront, the development offers smooth access from Sheikh Zayed Road, whenever you want. Wake up every day with the sunrise over the bay filled with attractions and leisure areas.</p><h3>Locations:</h3><ul><li>Al Maktoum International Airport - 25 minutes</li><li>Downtown Dubai - 15 minutes</li><li>Dubai International Airport - 25 minutes</li><li>Dubai Marina - 5 minutes</li><li>Sheikh Zayed Road - 5 minutes</li></ul>",
    features: [
      "1, 2, 3 & 4 bedroom apartments and 4, 5 & 6 bedroom penthouses ",
      "1.5 KM of Pristine Beach Destination in Dubai",
      "Prime and exclusive location at Emaar Beachfront",
      "Steps away from the Marina and Yacht Club",
      "Attractive and feasible payment plan options",
      "Well-connected paradise with waterfront amenities",
      "World-class leisure and entertainment options are available",
      "Direct access to the promenade, with a wide array of retails and dining",
    ],
  },
  {
    id: "emaar8",
    title: "South Ridge Towers",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1805.2345424221587!2d55.275561448672455!3d25.187398657257795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f682e2996271d%3A0xf031b9452deaaee3!2sDowntown%20Dubai%20-%20South%20Ridge%20-%20Dubai!5e0!3m2!1sen!2sae!4v1690009184975!5m2!1sen!2sae",
    area: "downtown dubai",
    type: "apartments/flats",
    price: 8013888,
    size: [3509],
    status: "ready to move",
    link: "southridge-emaar",
    developer: "emaar",
    bedrooms: 3,
    // bathrooms: 0,
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "park",
      "pharmacy",
    ],
    img: "/assets/contents/emaar/southridge-emaar/cardImage.jpg",
    images: [
      "/assets/contents/emaar/southridge-emaar/img1.jpg",
      "/assets/contents/emaar/southridge-emaar/img2.jpg",
    ],
    detail:
      "South Ridge Towers is a completed residential project in the center of Dubai. Emaar Properties began construction in 2005. Since 2008, apartments with 1, 2, 3 and 4 bedrooms, penthouses and villas have been ready for occupancy. The complex consists of six towers of different heights, of between 30 to 44 floors.",
    description:
      "<p>The building is designed according to the concept of having all necessary facilities near the house. The complex has created the perfect conditions for sports and recreation in the park area. Apartments overlook the Burj Khalifa tower and the sights in the center of the capital. Downtown Dubai combines residential buildings, shopping malls, entertainment, and hotels and restaurants.</p><p>There is a Spinney supermarket in Tower 1. Souk Al Bahar, a modern Arabian market, is a 9-minute drive away. There are 33 stores, 25 restaurants and cafes in the vicinity. At the same distance from South Ridge Towers is Dubai Mall. The distance from the old and new (Al Maktoum) airports is 17 and 44 minutes respectively.</p>",
    features: [
      "Close to the malls and shopping centers",
      "Games room for children",
      "Close to the kindergarten",
      "Well-developed facilities",
      "Transport accessibility",
    ],
  },
  {
    id: "emaar9",
    title: "Blvd Heights",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.3161505141397!2d55.26696467587706!3d25.192558677713986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f682abc466033%3A0x58e56c86d3de7cfd!2sBLVD%20Heights%20-%20Downtown%20-%20Emaar!5e0!3m2!1sen!2sae!4v1690011135666!5m2!1sen!2sae",
    area: "downtown dubai",
    type: "apartments/flats",
    price: 3499888,
    size: [1636],
    status: "offplan",
    link: "blvd-heights",
    developer: "emaar",
    bedrooms: 2,
    // bathrooms: 0,
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "park",
      "bbq",
    ],
    img: "/assets/contents/emaar/blvd-heights/cardImage.webp",
    images: [
      "/assets/contents/emaar/blvd-heights/img1.webp",
      "/assets/contents/emaar/blvd-heights/img2.webp",
      "/assets/contents/emaar/blvd-heights/img3.webp",
      "/assets/contents/emaar/blvd-heights/img4.webp",
      "/assets/contents/emaar/blvd-heights/img5.webp",
    ],
    detail:
      "BLVD Heights, a prominent development by Emaar Properties that offers 1, 2, 3 & 4-bedroom apartments, penthouses and duplex located in Downtown Dubai. The development comes with its 53 and 46-storey twin- towers with its luxury, elegance and contemporary chic design to impress its residents with its state at art facilities.",
    description:
      "<p>Though the development consists of 492 elegant designed apartments in the heart of Downtown Dubai alongside Sheikh Mohammed Bin Rashid Boulevard across Opera District. This boulevard also hosts a myriad of colourful events that brings the community together with its culture initiative, dynamic and brightly lit pavement adorn with an integrated art piece.</p><p>Decorated with striking interiors featuring a large entrance, large balconies, a sun terrace built with beautiful views of the Dubai Fountain, Burj Khalifa, and Dubai Mall, and other skyscrapers in Dubai. Plus, innumerable lanes and 5-star restaurants, luxury hotels and other key points in the city centre.</p><p>These houses are developed with smart ideas and innovative designs. The windows are large and transparent, giving you panoramic views of the Dubai skyline. The maximum relaxation and comfort features of this project include a 24-hour concierge service, retail plaza, wireless connectivity, air-conditioned bridges, bicycle paths, and endless facilities. These apartments are complete with luxurious furnishings and modern settings, rich bathrooms, and smart facilities for spaces.</p>",
    features: [
      "3 plus Bedroom Apartments & Townhouses ",
      "Panoramic views of Downtown Dubai",
      "53 and 46 storey Towers",
      "Floor to ceiling windows extenuate the feeling of space and light",
      "Main location in Boulevard Mohammed Bin Rashid",
      "Minutes away from Burj Khalifa and Dubai Opera",
      "Chic atheistic and contemporary design development ",
    ],
  },
  {
    id: "emaar10",
    title: "Blvd Crescent",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.3161505141397!2d55.26696467587706!3d25.192558677713986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f682abc466033%3A0x58e56c86d3de7cfd!2sBLVD%20Heights%20-%20Downtown%20-%20Emaar!5e0!3m2!1sen!2sae!4v1690011135666!5m2!1sen!2sae",
    area: "downtown dubai",
    type: "apartments/flats",
    price: 4662888,
    size: [2417],
    status: "ready to move",
    link: "blvd-crescent",
    developer: "emaar",
    bedrooms: 2,
    // bathrooms: 0,
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "park",
      "bbq",
    ],
    img: "/assets/contents/emaar/blvd-crescent/img1.webp",
    images: [
      "/assets/contents/emaar/blvd-crescent/img1.webp",
      "/assets/contents/emaar/blvd-crescent/img2.webp",
      "/assets/contents/emaar/blvd-crescent/img3.webp",
      "/assets/contents/emaar/blvd-crescent/img4.webp",
      "/assets/contents/emaar/blvd-crescent/img5.webp",
      "/assets/contents/emaar/blvd-crescent/img6.webp",
    ],
    detail:
      "Rising above Sheikh Mohammed bin Rashid Boulevard, BLVD Crescent comprises two residential towers of 39 and 21 storeys with 307 stylish apartments and a beautifully landscaped pool area. The stunning one, two and three-bedroom homes feature contemporary design, quality fixtures and large floor-to-ceiling windows that let in an abundance of light and frame breathtaking views of Downtown Dubai — the perfect choice for contemporary Dubai living.",
    description:
      "<p>BLVD Crescent is ideally positioned alongside the buzzing Sheikh Mohammed bin Rashid Boulevard with its dozens of restaurants, cafés and scenic spots to enjoy. The two-tower complex further offers easy access to Burj Khalifa, Dubai Opera and The Dubai Mall — for whenever you want to indulge in shopping, entertainment or an inspiring stroll. It is also minutes away from Business Bay and DIFC — perfect for when business calls.</p><p>Depending on its position, your new home could boast views of the awe-inspiring Burj Khalifa and Dubai Opera, as well as the colourful Sheikh Mohammed bin Rashid Boulevard. The 4-bedroom limited-edition penthouses — each with their own private terraces and balconies — offer the most stunning views of all, stretching all the way to the Arabian Sea. Take a seat and witness the sunset above the city's most iconic destination, every day.</p><p>And once you have had your fill of the view, step outside and explore Downtown Dubai's trendy restaurants, swanky fashion outlets and all of your favourite attractions. BLVD Crescent simply offers you that iconic Dubai lifestyle, in a dream location.</p>",
    features: [
      "1930’s Inspired Art Deco Architecture ",
      "39 & 21 storey Towers ",
      "Large private balconies ",
      "15 minutes’ walk to The Dubai Mall",
      "Direct access to the Boulevard and Public Promenade",
      "Beautiful designed landscaped pool area",
      "Double height entrance lobbies and drop-off zones",
      "Enjoy phenomenal vistas towards the Old Town & across Burj Khalifa",
    ],
  },

  {
    id: "emaar11",
    title: "Vida Residences",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14454.101353724038!2d55.128824820189244!3d25.084072302793324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f135494789ecb%3A0xae3654d5728515af!2sVida%20Dubai%20Marina%20%26%20Yacht%20Club!5e0!3m2!1sen!2sae!4v1687522874785!5m2!1sen!2sae",
    area: "dubai marina",
    type: "apartments/flats",
    size: [1577],
    price: 7142888,

    bedrooms: 2,
    img: "/assets/contents/emaar/vida-residences/img1.webp",
    status: "offplan",
    detail:
      "Surrounding you with stunning and unique wide views of the Dubai Marina, beaches and gorgeous nautical inspired architecture, Vida Residences Dubai Marina promises a relaxing, healthy lifestyle like no other. Choose between one, two, three and four-bedroom waterfront apartments.",
    link: "vida-residences",

    images: [
      "/assets/contents/emaar/vida-residences/img1.webp",
      "/assets/contents/emaar/vida-residences/img2.webp",

      "/assets/contents/emaar/vida-residences/img3.webp",

      "/assets/contents/emaar/vida-residences/img4.webp",
    ],
    developer: "emaar",
    roomtype: "bedrooms",
    bathrooms: 2,
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "school",
      "pharmacy",
    ],
    features: [
      "State of the art 50-storey residential development",
      "Serviced facilities by Vida Hotels and Resorts",
      "Round the clock security",
      "Easy accessibility and mobility",
      "Reception and Concierge services",
    ],
    description:
      "<p>Vida Residences Dubai Marina, a magnificent development by Emaar Properties that offers 1 to 4-bedroom apartments located at Dubai Marina, Dubai. This high-rise 50-storey waterfront development presents a range of exclusive opportunities to live a life characterized by unprecedented quality and flair managed by Vida Hotels and Resorts.</p><p>This project is strategically located in a luxurious yacht community and minutes from Dubai Marina Mall. The picture-perfect location of these residences affords stunning views over the bay and an array of yachts and palm-lined promenade set against the beautiful backdrop of the Marina skyline.</p><p>Life at the heart of Dubai Marina is the height of convenience with the tram, metro and water taxis only a short walk away, and excellent transport links connecting residents to the rest of this fascinating city. Enjoy access to the boat ride around the World Islands, Burj Al Arab Jumeirah, and other marina attractions that provides a refreshing perspective of the area.</p>",
  },

  {
    id: "emaar12",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.0419404508857!2d55.342513625974895!3d25.201808081512944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f671b0a1392c9%3A0x12e2f5ea26d58044!2sThe%20Cove%20-%20Dubai%20Creek%20Harbour!5e0!3m2!1sen!2sae!4v1687607669291!5m2!1sen!2sae",
    title: "The Cove",
    area: "dubai creek harbour",
    price: 3113888,
    type: "apartments/flats",
    bedrooms: 2,
    bathrooms: 0,
    size: [1204],
    status: "offplan",
    img: "/assets/contents/emaar/the-cove/img1.webp",

    detail:
      "Striking the perfect balance between modern convenience and natural wonder, The Cove delivers a refreshingly relaxed pace of life. The Cove’s broad range of apartment sizes and layouts ensures that you’ll find the right fit for your family. Gaze out of your floor-to-ceiling windows at the dazzling Dubai skyline, step out for a stroll along the winding waterside pathway, or set sail from the marina at your doorstep – the lifestyle of your dreams is closer than you ever imagined at The Cove.",
    link: "the-cove",

    images: [
      "/assets/contents/emaar/the-cove/img1.webp",
      "/assets/contents/emaar/the-cove/img2.webp",
      "/assets/contents/emaar/the-cove/img3.webp",
      "/assets/contents/emaar/the-cove/img4.webp",
      "/assets/contents/emaar/the-cove/img5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
    ],
    features: [
      "Lushly landscaped pathways, parks and promenades",
      "Quaint cafés",
      "Delightful dining concepts",
      "The Marina Yacht Club",
      "Lushly landscaped pathways",
    ],
    developer: "emaar",
    description:
      "<h3>Locations</h3><p>At Dubai Creek Harbour, you will experience more of what really matters. More laughs shared with friends. More memories made. A thriving community to live, work, play, and capture moments to behold and cherish. A home where every view is a view to live for.</p><ul><li>10 mins to Burj Khalifa</li><li>10 mins to Dubai Mall</li><li>15 mins to Dubai Intl Airport</li></ul>",
  },
  {
    id: "emaar13",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.8272213000855!2d55.3449938758776!3d25.20904857770331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f67624554aeb1%3A0x8c97ff836ba52ad8!2sCreek%20Waters!5e0!3m2!1sen!2sae!4v1690022059813!5m2!1sen!2sae",
    title: "Creek Waters",
    area: "dubai creek harbour",
    price: 6457888,
    type: "apartments/flats",
    bedrooms: 4,
    size: [2631],
    status: "offplan",
    img: "/assets/contents/emaar/creek-waters/img1.webp",

    detail:
      "Creek Waters at Dubai Creek Harbour is a new destination by Emaar Properties that is laced with premium choice of 1 to 4 bedroom waterfront apartments, 3 bedroom townhouses and 5 bedroom penthouse. This exclusive class project comes with exceptional designs and appealing aesthetics blended with a perfect touch of stunning architecture. This again comes in the most beautiful world of Dubai Creek Harbour, where the lifestyle gives you every premium access.",
    link: "creek-waters",

    images: [
      "/assets/contents/emaar/creek-waters/img1.webp",
      "/assets/contents/emaar/creek-waters/img2.webp",
      "/assets/contents/emaar/creek-waters/img3.webp",
      "/assets/contents/emaar/creek-waters/img4.webp",
      "/assets/contents/emaar/creek-waters/img5.webp",
    ],
    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
      "tennis",
    ],
    features: [
      "Luxurious apartments, townhouses and a state of the art penthouse",
      "An iconic tower with a modern architectural style",
      "A high-class sustainable development at Dubai Creek Harbour",
      "Access to beaches and creek promenade, retails at doorstep",
      "A stunning resort-style amenities podium with a wide range of activities",
      "Proximity to Creek Marina, Harbour Promenade and Central Park",
      "World-class advanced amenities of entertainment, health and pleasures",
    ],
    developer: "emaar",
    description:
      "<p>Creek Waters at Dubai Creek Harbour is a new destination by Emaar Properties that is laced with premium choice of 1 to 4 bedroom waterfront apartments, 3 bedroom townhouses and 5 bedroom penthouse. This exclusive class project comes with exceptional designs and appealing aesthetics blended with a perfect touch of stunning architecture. This again comes in the most beautiful world of Dubai Creek Harbour, where the lifestyle gives you every premium access.</p><h3>Locations</h3><ul><li>15 mins to Burj Khalifa</li><li>15 mins to Dubai Mall</li><li>10 mins to Dubai Intl Airport</li><li>5 minutes to creek marina</li><li>7 minutes to Ras Al Khor Wildlife Sanctuary</li></ul>",
  },
  {
    id: "emaar14",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.8286182514184!2d55.344864375877606!3d25.209001477703236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6700974dddef%3A0x2e1ebbcfa968f051!2sCreek%20Waters%202!5e0!3m2!1sen!2sae!4v1690022656627!5m2!1sen!2sae",
    title: "Creek Waters 2",
    area: "dubai creek harbour",
    price: 1717888,
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 2,
    size: [755],
    status: "ready to move",
    img: "/assets/contents/emaar/creek-waters-2/img1.webp",

    detail:
      "Creek Waters 2 at Dubai Creek Harbour is the latest addition by Emaar Properties offering luxury 1 to 4 bedroom apartments, townhouses and penthouses. Modern and stylish architectural design has made the development a natural haven for the residents and offers them a great experience of urban living with unparalleled vistas of the surrounding.",
    link: "creek-waters-2",

    images: [
      "/assets/contents/emaar/creek-waters-2/img1.webp",
      "/assets/contents/emaar/creek-waters-2/img2.webp",
      "/assets/contents/emaar/creek-waters-2/img3.webp",
      "/assets/contents/emaar/creek-waters-2/img4.webp",
      "/assets/contents/emaar/creek-waters-2/img5.webp",
    ],
    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
      "tennis",
    ],
    features: [
      "Luxurious apartments, townhouses and a state of the art penthouse",
      "An iconic tower with a modern architectural style",
      "A high-class sustainable development at Dubai Creek Harbour",
      "Access to beaches and creek promenade, retails at doorstep",
      "A stunning resort-style amenities podium with a wide range of activities",
      "Proximity to Creek Marina, Harbour Promenade and Central Park",
      "World-class advanced amenities of entertainment, health and pleasures",
    ],
    developer: "emaar",
    description:
      "<p>Dubai Creek Harbour, known for its architectural marvels and luxurious lifestyle, is about to witness the emergence of a new icon on its illustrious skyline. Enjoy a prime location along the shores of Dubai Creek, with breathtaking views of the waterfront and the iconic Dubai skyline. The locality not only offers a luxurious lifestyle but also ensures unmatched connectivity and convenience for its residents. Residents can revel in the seamless blend of tranquility and urban vibrancy, enjoying the best of both worlds within arm's reach.</p><p>The development boasts a myriad of world-class amenities, including private marinas, lushly landscaped gardens, state-of-the-art fitness centers, luxurious spa facilities, and swimming pools that seem to merge with the shimmering waters of the Creek. Additionally, residents can enjoy exclusive access to a range of fine dining restaurants, high-end retail outlets, and vibrant entertainment options, ensuring there is never a dull moment at Creek Island.</p><h3>Locations</h3><ul><li>15 mins to Burj Khalifa</li><li>15 mins to Dubai Mall</li><li>10 mins to Dubai Intl Airport</li><li>5 minutes to creek marina</li><li>7 minutes to Ras Al Khor Wildlife Sanctuary</li></ul>",
  },
  {
    id: "emaar15",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.8286182514184!2d55.344864375877606!3d25.209001477703236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6700974dddef%3A0x2e1ebbcfa968f051!2sCreek%20Waters%202!5e0!3m2!1sen!2sae!4v1690022656627!5m2!1sen!2sae",
    title: "Harbour Gate",
    area: "dubai creek harbour",
    price: 3746888,
    type: "apartments/flats",
    bedrooms: 3,
    size: [1639],
    status: "offplan",
    img: "/assets/contents/emaar/harbour-gate/img1.jpg",

    detail:
      "Harbour Gate Apartments by Emaar Properties, a luxurious designed twin-stepped tower development that offers 1, 2 & 3-bedroom apartments located at Dubai Creek Harbour. These stylishly designed units create a clear line of sight to the Dubai Creek Harbour overlooking the lush parkland below and the shimmering waters of the Creek. Enjoy living on the Creek Island that let you access the wide range of leisure and recreational facilities.",
    link: "harbour-gate",

    images: [
      "/assets/contents/emaar/harbour-gate/img0.jpg",
      "/assets/contents/emaar/harbour-gate/img1.jpg",
      "/assets/contents/emaar/harbour-gate/img2.jpg",
      "/assets/contents/emaar/harbour-gate/img3.jpg",
    ],
    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
      "tennis",
    ],
    features: [
      "State of the art two-stepped residential towers",
      "Selection of stylish 1, 2 & 3-bedroom apartments",
      "Private heaven on Dubai Creek Harbour",
      "Wide range of leisure and attraction facilities",
      "Easy and attractive payment plan options",
    ],
    developer: "emaar",
    description:
      "<p>The two stepped towers of Harbour Gate comprise stylish one, two and three-bedroom apartments with a clear line of sight to the futuristic Dubai Creek Harbour. Overlooking the leafy parkland below and the shimmering waters of the Dubai Creek, living here ensures you get access to the wide array of leisure and recreational facilities that bring Creek Island to life.</p><h3>Locations</h3><ul><li>18 mins to Burj Khalifa</li><li>18 mins to Dubai Mall</li><li>15 mins to Dubai Intl Airport</li><li>5 minutes to creek marina</li><li>13 minutes to Ras Al Khor Wildlife Sanctuary</li></ul>",
  },
  {
    id: "emaar16",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.8996740736584!2d55.34456282599715!3d25.20660563131841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f674a48bca10b%3A0x4a1c913e2b27fccc!2sCreek%20Gate%20-%20Emaar!5e0!3m2!1sen!2sae!4v1690176042108!5m2!1sen!2sae",
    title: "Creek Gate",
    area: "dubai creek harbour",
    price: 3653888,
    type: "apartments/flats",
    bedrooms: 3,
    size: [1496],
    status: "offplan",
    img: "/assets/contents/emaar/creek-gate/img1.webp",

    detail:
      "Creek Gate by Emaar, located at Dubai Creek Harbour comprises of 1, 2 and 3 luxury apartments with balcony get to. Inhabitants can appreciate the marina way of life while having close access to Ras Al Khor Wildlife Sanctuary that is home to 450 wildlife species.",
    link: "creek-gate",

    images: [
      "/assets/contents/emaar/creek-gate/img1.webp",
      "/assets/contents/emaar/creek-gate/img2.webp",
      "/assets/contents/emaar/creek-gate/img3.webp",
      "/assets/contents/emaar/creek-gate/img4.webp",
      "/assets/contents/emaar/creek-gate/img5.webp",
    ],
    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
      "tennis",
      "school",
    ],
    features: [
      "Located in a thriving marina community",
      "All-encompassing views of Dubai Creek Harbour",
      "staggering views of The Tower and The Island Central Park",
      "Wide range of leisure and attraction facilities",
      "Close access to Ras Al Khor Wildlife Sanctuary",
    ],
    developer: "emaar",
    description:
      "<p>Step out of Creek Gate’s high-rises and discover a flourishing waterfront hotspot, where Dubai’s past and future effortlessly intertwine in a fabulous feast for the senses. Comprising contemporary one, two and three-bedroom apartments, Creek Gate homes enjoy spectacular views and alluring leisure and recreational facilities.</p><p>Creek Harbour is a uber city planned in Dubai. It is a joint wander between Emaar and the government-owned real estate developer Dubai Holding. The venture keeps running with the slogan 'discover life at the source' and is arranged by the Ras Al Khor wildlife haven, famous for its flamingos.</p>",
  },
  {
    id: "emaar17",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.9634691436995!2d55.346214184951805!3d25.204454420223602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f67f82e7d0b31%3A0xa66154992844530d!2sCedar%20at%20Dubai%20Creek%20Harbour!5e0!3m2!1sen!2sae!4v1690177524329!5m2!1sen!2sae",
    title: "Cedar",
    area: "dubai creek harbour",
    price: 2004888,
    type: "apartments/flats",
    bedrooms: 2,
    size: [982],
    status: "offplan",
    img: "/assets/contents/emaar/cedar/img1.webp",

    detail:
      "Cedar at Creek Beach is a new residential development by Emaar Properties that features stylish design apartments located at Dubai Creek Harbour (DCH). The exclusive design project presents the state-of-the-art design infrastructure providing one of the exclusive addresses within Dubai with awe-inspiring views of the Skylines.",
    link: "cedar",

    images: [
      "/assets/contents/emaar/cedar/img1.webp",
      "/assets/contents/emaar/cedar/img2.webp",
      "/assets/contents/emaar/cedar/img3.webp",
      "/assets/contents/emaar/cedar/img4.webp",
      "/assets/contents/emaar/cedar/img5.webp",
    ],
    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
      "tennis",
      "school",
      "events",
    ],
    features: [
      "Close proximity to Vida Creek Harbour and Address Grand",
      "Direct access to the new park",
      "Large family pool and pool deck",
      "Kids’ pool and splash pad",
      "Toddlers-only play area",
      "Outdoor landscaped seating area",
      "Communal co-working space",
    ],
    developer: "emaar",
    description:
      "<p>Apartments at Cedar range in size from one to three bedrooms and are located in a stylish residential community comprised of three buildings that rise around a bustling central plaza. Each apartment has a private balcony or terrace that lets in plenty of natural light and contributes to the relaxing atmosphere reflected by the views of the lush park outside.</p><p>Cedar is conveniently close to all primary business, shopping and entertainment destinations in Dubai, so you’ll find peace at home without being too far from all the action. It is also surrounded by world-class amenities where you can strike an artful balance between work and life in the harmony.</p><h3>Nearby Places</h3><ul><li>Ras Al Khor Wildlife Sanctuary - 5 minutes</li><li>Dubai International Airport - 10 minutes</li><li>Dubai creek harbour - 5 minutes</li><li>Downtown Dubai - 15 minutes</li><li>Expo 2020 - 35 minutes</li></ul>",
  },
  {
    id: "emaar18",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.9622812686966!2d55.34822257587745!3d25.204494477706227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f67de781d3375%3A0x7216fd976388b2e0!2sSavanna%20%7C%20Creek%20Beach!5e0!3m2!1sen!2sae!4v1690177856432!5m2!1sen!2sae",
    title: "Savanna",
    area: "dubai creek harbour",
    price: 2110888,
    type: "apartments/flats",
    bedrooms: 2,
    size: [1046],
    status: "offplan",
    link: "savanna",
    img: "/assets/contents/emaar/savanna/img1.webp",
    images: [
      "/assets/contents/emaar/savanna/img1.webp",
      "/assets/contents/emaar/savanna/img2.webp",
      "/assets/contents/emaar/savanna/img3.webp",
      "/assets/contents/emaar/savanna/img4.webp",
      "/assets/contents/emaar/savanna/img5.webp",
    ],
    detail:
      "Savanna at Creek Beach is the latest beachfront development by Emaar Properties offering limited edition 1, 2 & 3 bedroom apartments at Dubai Creek Harbour (DCH). The development marks its pristine city address with some of the best-in-class leisure and entertainment options available at ease, so you can focus on the lifestyle.",

    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
      "tennis",
      "school",
      "events",
    ],
    features: [
      "Luxury design 1 to 3 bedroom beachfront apartments at service",
      "Exclusive design paradise with 700 metres of pristine white sands",
      "Prime location at Creek Beach, Dubai",
      "A gated community with leisure and attractions",
      "Top-notch facilities and amenities available",
      "Waterfront lifestyle with awe-inspiring outdoor views",
      "Minutes away from the Creek Island and Downtown Dubai",
    ],
    developer: "emaar",
    description:
      "<p>Savanna is a collection of 1-, 2- and 3-bedroom apartments adjacent to a lush park. The elegantly designed buildings occupy an enviable location amidst world-class amenities, with a comprehensive network of roads providing convenient links to enjoy the very best of Dubai and beyond.</p><p>Savanna is located minutes away from the pristine Creek Beach, where you can enjoy the serenity of the natural surroundings. It is also conveniently close to all primary business, shopping and entertainment destinations in Dubai, so you’ll find peace at home without being too far from all the action. The artful balance between work and life is struck in the harmony of Savanna's air of refined sophistication.</p><h3>Nearby Places</h3><ul><li>Ras Al Khor Wildlife Sanctuary - 5 minutes</li><li>Dubai International Airport - 10 minutes</li><li>Dubai creek harbour - 5 minutes</li><li>Downtown Dubai - 15 minutes</li><li>Expo 2020 - 35 minutes</li></ul>",
  },
  {
    id: "emaar19",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.9280143506357!2d55.33893839678956!3d25.20565000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f678e3eb1bedd%3A0xb289fcd40abe0207!2sDubai%20Creek%20Harbour%20Residences!5e0!3m2!1sen!2sae!4v1690180309902!5m2!1sen!2sae",
    title: "Dubai Creek Residences",
    area: "dubai creek harbour",
    price: 3482888,
    type: "apartments/flats",
    bedrooms: 2,
    size: [1567],
    status: "ready to move",
    link: "dubai-creek-residences",
    img: "/assets/contents/emaar/dubai-creek-residences/img1.webp",
    images: [
      "/assets/contents/emaar/dubai-creek-residences/img1.webp",
      "/assets/contents/emaar/dubai-creek-residences/img2.webp",
      "/assets/contents/emaar/dubai-creek-residences/img3.webp",
      "/assets/contents/emaar/dubai-creek-residences/img4.webp",
      "/assets/contents/emaar/dubai-creek-residences/img5.webp",
    ],
    detail:
      "Each apartment at Dubai Creek Residences is designed with high-quality materials and elegant finishing to match the expansive views of the Dubai Creek and the Emirate's iconic skyline.",

    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
      "tennis",
      "school",
      "events",
    ],
    features: [
      "Centric location within Dubai Creek Harbour",
      "Round the clock security",
      "Home to beautiful island park, retail and dining",
      "A gated community with leisure and attractions",
      "Top-notch facilities and amenities available",
      "Six-modern designed waterfront residential towers",
      "Minutes away from the Creek Island and Downtown Dubai",
    ],
    developer: "emaar",
    description:
      "<p>Wake up to views of gleaming white yachts in the marina, take a leisurely stroll down the promenade, explore the trails of the Island Park, or simply relax in the swimming pool or the range of fitness facilities at Dubai Creek Residences—designed to cater to your every need.</p><h3>Nearby Places</h3><ul><li>Dubai International Airport - 20 minutes</li><li>Downtown Dubai - 30 minutes</li></ul>",
  },
  {
    id: "emaar20",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.9685442285145!2d55.3422832258773!3d25.20428327770649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f678e14baf33f%3A0x8b1c3a0a97189738!2sCreekside%2018%20-%20Dubai%20Creek%20Harbour!5e0!3m2!1sen!2sae!4v1690181389505!5m2!1sen!2sae",
    title: "Creekside 18",
    area: "dubai creek harbour",
    price: 3280888,
    type: "apartments/flats",
    bedrooms: 3,
    size: [1609],
    status: "offplan",
    link: "creekside-18",
    img: "/assets/contents/emaar/creekside-18/img1.jpg",
    images: [
      "/assets/contents/emaar/creekside-18/img2.jpg",
      "/assets/contents/emaar/creekside-18/img3.jpg",
      "/assets/contents/emaar/creekside-18/img4.jpg",
      "/assets/contents/emaar/creekside-18/img5.jpg",
      "/assets/contents/emaar/creekside-18/img6.jpg",
    ],
    detail:
      "Perfectly positioned within the heart of Creek Island, at Creekside 18 you are just a short walk away from Dubai Square, the city’s new global retail destination. Soaring high above the Dubai Creek, the twin towers are accessible via Creek Island’s circular boulevard, with Downtown Dubai and Dubai International Airport a 10 to 15-minute drive away.",

    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
      "tennis",
      "school",
    ],
    features: [
      "Near retail centre & supermarket",
      "Landscaped podium",
      "Temperature-controlled swimming pools",
      "Start-of-the-art gym",
    ],
    developer: "emaar",
    description:
      "<p>Creekside 18’s dazzling glass-fronted apartments have spacious balconies from which you can savour unrivalled views of Dubai Creek Tower and Downtown Dubai. Anchored to a podium of green walkways and lush open spaces, this is your very own oasis of tranquillity. The neighbouring business and shopping districts that border Creek Island’s parkland, enrich your experience with a host of gourmet restaurants, vibrant cafés and entertainment venues to explore.</p><p>Creekside 18 apartments enjoy dedicated parking and a convenient drop-off zone behind the reception area that connects directly to the main lobby. Serene swimming pools and intimately positioned sun loungers invite you to make the most of the afternoon sun. A fully equipped gymnasium, barbecue stations and children’s play areas offer countless ways to spend treasured time with friends and loved ones without ever having to leave the comfort of your home</p>",
  },

  {
    id: "emaar21",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.370694543305!2d55.27471727587889!3d25.258112577671803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43783adc4af3%3A0x64c89506c4372723!2sSeascape!5e0!3m2!1sen!2sae!4v1690185661517!5m2!1sen!2sae",
    title: "Seascape building",
    area: "rashid yachts and marina",
    price: 4537888,
    type: "apartments/flats",
    bedrooms: 3,
    size: [2620],
    status: "offplan",
    link: "seascape-building",
    img: "/assets/contents/emaar/seascape/img1.webp",
    images: [
      "/assets/contents/emaar/seascape/img1.webp",
      "/assets/contents/emaar/seascape/img2.webp",
      "/assets/contents/emaar/seascape/img3.webp",
      "/assets/contents/emaar/seascape/img4.webp",
      "/assets/contents/emaar/seascape/img5.webp",
    ],
    detail:
      "Seascape building is a wonderful residential community which offers people with the most attractive lifestyle within Dubai. The neighbourhood provides an address with such a high level of luxury, where you are going to explore a lifestyle like never before. Buyers are offered this truly remarkable opportunity because it will make their life better and offers a yachting destination in Dubai.",
    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "sauna",
      "tennis",
      "school",
      "jog",
    ],
    features: [
      "Long Canal Pool with marina promenade",
      "Retail and dining on the water",
      "Pedestrian bridges for quick access",
      "Attractive payment plan options",
      "State of the art facilities like never before",
    ],
    developer: "emaar",
    description:
      "<p>The luxury, style and elegance at this development are offered with the breathtaking views of the marina and the blue horizon of the gulf offering endless water. You have access to the facilities and services that the general public does not have easy access to, so it is important that you make a decision that will be beneficial not only for yourself but also for your family.</p><h3>Nearby Locations</h3><ul><li>Burj Khalifa - 20 minutes</li><li>Dubai International Airport - 15 minutes</li><li>Sheikh Zayed Road - 10 minutes</li></ul>",
  },
  {
    id: "emaa22",
    title: "Golf views residences",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.1478255521242!2d55.1520297741036!3d24.85880027793032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f7528ceb0a7c9%3A0xc5481b1a29167095!2sExpo%20Golf%20Villas%20III!5e0!3m2!1sen!2sae!4v1687777558536!5m2!1sen!2sae",
    area: "emaar south",
    featured: true,
    price: 1996888,
    type: "apartments/flats",
    bedrooms: 3,

    img: "/assets/contents/emaar/golf-views/img1.jpg",
    status: "offplan",
    detail:
      "Emaar South Golf Views is best connected by road and metro. It is close to Al Maktoum International Airport, providing easy access and exit, while the rail and metro network will facilitate communication in the city. The road network along the project offers transportation capacity for the people of southern Emaar.",
    link: "golf-views",
    description:
      "<h3>Nearby Access:</h3><ul><li>10 mins drive to Al Maktoum Intl Airport</li><li>10 mins drive to Expo 2020 Dubai</li><li>25 mins drive to Dubai Marina</li><li>35 mins drive to Downtown Dubai</li><g/ul>",
    size: [1545],
    images: [
      "/assets/contents/emaar/golf-views/img1.jpg",
      "/assets/contents/emaar/golf-views/img2.jpg",
      "/assets/contents/emaar/golf-views/img3.jpg",
      "/assets/contents/emaar/golf-views/img4.jpg",
      "/assets/contents/emaar/golf-views/img5.jpg",
    ],
    roomtype: "bedrooms",
    bathrooms: 3,
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "deck",
      "bbq",
    ],
    features: [
      "A few minutes’ drives from Al Maktoum International Airport",
      "Diversified housing assistance with a variety of sizes",
      "Nearby 18-hole championship golf course, schools",
      "Hotels and retail community amenities and parks",
      "Fully incorporated transport system close by Dubai metro link",
    ],
    developer: "emaar",
  },
  {
    id: "emaar23",
    title: "Fairway villas 2",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.222364270159!2d55.142316775867634!3d24.85625387793203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f75e512990603%3A0x73fa81a607688f09!2sFairway%20Villas%20-%20Emaar%20South!5e0!3m2!1sen!2sae!4v1690270334601!5m2!1sen!2sae",
    area: "emaar south",
    price: 3209888,
    type: "villa/mansion",
    bedrooms: 3,
    img: "/assets/contents/emaar/fairway-villas-2/img1.webp",
    status: "offplan",
    detail:
      "Fairway Villas 2 is an exquisite collection of villas in the centre of a cutting-edge, family-friendly community that is nestled in nature yet connected to an inspired array of world-class amenities. At Fairway Villas 2, you can experience the luxury of a golf resort with its immaculate landscape and lush greens, while enjoying sweeping vistas of the large linear park and tree-lined sikkas from the comfort of your balcony.",
    link: "fairway-villas-2",
    description:
      "<p>Located on a stunning 18-hole championship golf course, Fairway Villas 2 is a stunning community of stylish homes with three and four bedrooms. These exquisite villas are situated in the centre of a dynamic neighbourhood and feature modern design, generous space, and a high level of comfort. Their modern, life-enhancing design features are all thoughtfully woven together with an affinity for the surrounding environment.</p><h3>Nearby Access:</h3><ul><li>10 mins drive to Al Maktoum Intl Airport</li><li>10 mins drive to Expo 2020 Dubai</li><li>25 mins drive to Dubai Marina</li><li>35 mins drive to Downtown Dubai</li></ul>",
    size: [2990],
    images: [
      "/assets/contents/emaar/fairway-villas-2/img1.webp",
      "/assets/contents/emaar/fairway-villas-2/img2.webp",
      "/assets/contents/emaar/fairway-villas-2/img3.webp",
      "/assets/contents/emaar/fairway-villas-2/img4.webp",
      "/assets/contents/emaar/fairway-villas-2/img5.webp",
      "/assets/contents/emaar/fairway-villas-2/img6.webp",
    ],
    roomtype: "bedrooms",
    bathrooms: 3,
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "deck",
      "bbq",
    ],
    features: [
      "18-Hole Championship Golf Course",
      "Adult & Kids’ Swimming Pools",
      "Golf Clubhouse",
      "Family-friendly lifestyle within the gated community",
      "Serene setting away from the hustle and bustle of the city",
    ],
    developer: "emaar",
  },
  {
    id: "emaar24",
    title: "The Hills",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.708585644291!2d55.1587239!3d25.077864400000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6eb5bcbe40cf%3A0x9691f41aa5633ac0!2sThe%20Hills%20-%20Emaar!5e0!3m2!1sen!2sae!4v1690272703939!5m2!1sen!2sae",
    area: "emirates hills",
    price: 3030888,
    type: "apartments/flats",
    bedrooms: 1,
    img: "/assets/contents/emaar/the-hills/img1.webp",
    status: "ready to move",
    detail:
      "The Hills Dubai is a development by Emaar, part of the Emirates Living communities that include The Greens, The Springs, The Meadows and The Lakes. It has a focus on green lifestyles with several energy and water efficiency initiatives included.",
    link: "the-hills",
    description:
      "<p>The Hills offers a variety of amenities, including access to beauty salons, spas, equipped gyms, barbecue areas, children’s playgrounds, restaurants, parks, swimming pools, and the Emirates Golf Club.</p>",
    size: [1012],
    images: [
      "/assets/contents/emaar/the-hills/img1.webp",
      "/assets/contents/emaar/the-hills/img2.jpg",
      "/assets/contents/emaar/the-hills/img3.jpg",
      "/assets/contents/emaar/the-hills/img4.jpg",
    ],
    roomtype: "bedrooms",
    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "deck",
      "bbq",
    ],
    features: [
      "18-Hole Championship Golf Course",
      "Adult & Kids’ Swimming Pools",
      "Golf Clubhouse",
      "Family-friendly lifestyle within the gated community",
      "Serene setting away from the hustle and bustle of the city",
    ],
    developer: "emaar",
  },

  // damac properties
  {
    id: "damac1",
    title: "Damac Towers By Paramount",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.497594412628!2d55.2894841744571!3d25.186436632138257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d5efc17f51%3A0x18ecd3040d18d16c!2sDAMAC%20Towers%20Paramount!5e0!3m2!1sen!2sae!4v1690004167960!5m2!1sen!2sae",
    area: "business-bay",
    price: 1752000,
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 2,
    size: [687],
    status: "ready to move",
    img: "/assets/contents/Damac/damac-tower/cardimage.jpg",

    detail:
      "DAMAC Towers by Paramount Hotels & Resorts is a hotel and residential complex located in the heart of the stylish Burj area,Buisness Bay Dubai. Damac Towers by Paramount is a mega project consisting of four buildings, one of which will house the Paramount-branded hotel while the other three buildings will have the Damac Maison Paramount serviced residences. Sophisticated yet inviting, suites feature contemporary design cues where elements of the California lifestyle are transported to Dubai in a distinctive ‘Hollywood studio’ feel.",
    link: "damac-tower-by-paramount",

    images: [
      "/assets/contents/Damac/damac-tower/cardimage.jpg",
      "/assets/contents/Damac/damac-tower/pic1.jpg",
      "/assets/contents/Damac/damac-tower/pic2.jpg",
      "/assets/contents/Damac/damac-tower/pic3.jpg",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "security", "play", "shops", "garden", "bbq"],
    features: [
      "green central park",
      "stunning marina",
      "yacht club",
      "five-star hotels",
      "Swimming Pool",
    ],
    developer: "damac",
    description:
      "<p>Whilst in residence, you’ll experience the height of fashionable living with the service standards of the finest luxury hotels; and when you’re away, you’ll earn impressive rental income from your exquisite property. Residents and guests can enjoy high-end shopping with the perfect combination of global luxury and California cool, implemented through a signature Paramount Hotels & Resorts merchandise store: The Vault.</p>",
  },

  {
    id: "damac2",
    title: "AVANTI TOWER",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.786003769099!2d55.26855647445681!3d25.176702632532276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f68353b836f8f%3A0xcc4092dae64f8117!2sAvanti%20Tower!5e0!3m2!1sen!2sae!4v1690003372812!5m2!1sen!2sae",
    area: "business-bay",
    price: 1543000,
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 2,
    status: "ready to move",
    img: "/assets/contents/Damac/avanti-tower/cardimage.jpg",

    detail:
      "With its exceptional location, Avanti is your vantage point to get the most out of vibrant city life. Within the contemporary 15-storey tower, a wealth of state-of-the-art facilities create an experience beyond the ordinary.",
    link: "avanti-tower",

    images: [
      "/assets/contents/Damac/avanti-tower/cardimage.jpg",
      "/assets/contents/Damac/avanti-tower/pic1.jpg",
      "/assets/contents/Damac/avanti-tower/pic2.jpg",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "security", "play", "shops", "garden", "bbq"],
    features: [
      "ample parking",
      "fitted with kitchen application and white goods",
      "gymnasium",
      "steam room",
      "children play area",
      "Swimming Pool",
    ],
    developer: "damac",
    description:
      "<p>Avanti stands close to the Burj area with the city’s main attractions close to hand, and access to the key highways mere moments away. This stylish tower isn’t just a perfect place to call home – complete with its tasteful neutral furnishings, equipped kitchens and large balconies; it’s also a shrewd investment opportunity for buy-to-let landlords. </p>",
  },

  {
    id: "damac3",
    title: "Bellavista",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.274095585674!2d55.241200774451436!3d25.024770588664833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6e1e656ab211%3A0xc696e87056d8aef4!2sDAMAC%20Hills%20-%20Bellavista!5e0!3m2!1sen!2sae!4v1690004473523!5m2!1sen!2sae",
    area: "business-bay",
    price: 1543000,
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 2,
    status: "ready to move",
    img: "/assets/contents/Damac/bellavista/cardimage.jpg",

    detail:
      "With its exceptional location, Avanti is your vantage point to get the most out of vibrant city life. Within the contemporary 15-storey tower, a wealth of state-of-the-art facilities create an experience beyond the ordinary.",
    link: "bellavista",

    images: [
      "/assets/contents/Damac/bellavista/cardimage.jpg",
      "/assets/contents/Damac/bellavista/pic1.jpg",
      "/assets/contents/Damac/bellavista/pic2.jpg",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "security", "play", "shops", "garden", "bbq"],
    features: [
      "Sauna",
      "fitted with kitchen application and white goods",
      "gymnasium",
      "steam room",
      "children play area",
      "Swimming Pool",
      "access of the world class amenity of Damac Hill",
      "fitness center",
    ],
    developer: "damac",
    description:
      "<p>Three residential towers offering studios, along with one and two-bedroom apartments with panoramic views of the DAMAC Hills development and its green outdoor spaces. Bellavista condominiums in Dubai for sale looks out over the Trump International Golf Club Dubai with a universe of facilities at street level. An elegant lobby welcomes you home, and your apartment is thoughtfully designed to maximise both living space and exceptional views. Bellavista is part of an established community, and benefits from life necessities such as shops and cafés, a convenient school, as well as an expanse of green parkland and outdoor fun.</p>",
  },

  {
    id: "damac4",
    title: "BelAir at The Trump Estates - Phase 2",

    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.44911252995!2d55.24453587274794!3d25.018828389917854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f710f2ecf2d39%3A0xe8ae26ade9f119!2sBelair%20-%20DAMAC%20Hills!5e0!3m2!1sen!2sae!4v1690005309356!5m2!1sen!2sae",
    area: "Damac-hills",
    price: 14856000,
    type: "villa/mansion",
    bedrooms: 1,
    bathrooms: 2,
    status: "offplan",
    img: "/assets/contents/Damac/belairvillastrumpestate/pic1.jpg",

    detail:
      "With its exceptional location, Avanti is your vantage point to get the most out of vibrant city life. Within the contemporary 15-storey tower, a wealth of state-of-the-art facilities create an experience beyond the ordinary.",
    link: "belAir-at-the-trump-estates-Phase-2",

    images: [
      "/assets/contents/Damac/belairvillastrumpestate/pic1.jpg",
      "/assets/contents/Damac/belairvillastrumpestate/pic2.jpg",
      "/assets/contents/Damac/belairvillastrumpestate/pic3.jpg",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "security", "play", "shops", "garden", "bbq"],
    features: [
      "Sauna",
      "fitted with kitchen application and white goods",
      "gymnasium",
      "steam room",
      "children play area",
      "Swimming Pool",
      "access of the world class amenity of Damac Hill",
      "fitness center",
    ],
    developer: "damac",
    description:
      "<p>Belair Villas The Trump Estate 2 is among the most sought-after projects of Damac Properties offering spectacular mansions and villas for sale in Dubai. These amazing properties simply give you the best of “California Living”. These architectural masterpieces are the perfect harmony of a prestigious location, world-class amenities, chic interiors and also eye-catching views. Do not miss full info on these sought-after villas at Damac Hills…</p>",
  },

  {
    id: "damac5",
    title: "DAMAC Hills 2 Hotel Edge by Rotana",
    featured: true,
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.486421943204!2d55.390261274051404!3d24.98358237784907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f7b180c9f0a7f%3A0xcbfb4613f8b16973!2sDamac%20Hills%202%20Hotel%2C%20an%20Edge%20by%20Rotana%20Hotel!5e0!3m2!1sen!2sae!4v1690009562785!5m2!1sen!2sae",
    area: "Damac-hills",
    price: 653000,
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 2,
    status: "ready to move",
    img: "/assets/contents/Damac/Hotel Edge by Rotana/cardimage.jpg",

    detail:
      "DAMAC Hills 2 Hotel – an Edge by Rotana managed property – is a modern and elegant three-star property located in Dubai's vibrant DAMAC Hills 2 community. Guests at DAMAC Hills 2 Hotel Edge by Rotana can expect convenience and comfort close to world-class attractions such as the Malibu Beach wave pool, sports fields, outdoor cinema, paintball arcade, butterfly garden and more.",
    link: "damac-hills-2-hotel-edge-by-rotana",

    images: [
      "/assets/contents/Damac/Hotel Edge by Rotana/cardimage.jpg",
      "/assets/contents/Damac/Hotel Edge by Rotana/pic2.jpg",
      "/assets/contents/Damac/Hotel Edge by Rotana/pic3.jpg",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "security", "play", "shops", "garden", "bbq"],
    features: [
      "295 Rooms and suites",
      "Four fully funished meeting room",
      "Restaurant and Bar",
      "fitness and wellness club",
      "Easy access to Damac 2 community",
      "latest  in-room technologies ",
      "Work desk",
      " Controlled Swimming Pool",
      "access of the world class amenity of Damac Hill",
    ],
    developer: "damac",
    description:
      "<p>Choose from 295 stylish and well-designed rooms and suites featuring the latest in-room technology, balconies and work desks. Enjoy the comforts of an all-day dining restaurant & bar, fully equipped gym and two outdoor temperature-controlled swimming pools. And for those on important business trips, DAMAC Hills 2 Hotel features four meeting rooms with flexible set-ups</p>",
  },
  {
    id: "damac6",
    title: "Tenora",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.5791370464503!2d55.209212674049844!3d24.94640337787317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f73eecd6023ed%3A0x6f2a9b6e0005e7c3!2sDAMAC%20-%20Tenora!5e0!3m2!1sen!2sae!4v1690011018295!5m2!1sen!2sae",
    price: 714000,
    area: "Dubai South",
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 2,
    status: "ready to move",
    img: "/assets/contents/Damac/Tenora/cardimage.jpg",

    detail:
      "Set in a master development created to enhance the happiness and wellbeing of its residents, Tenora offers a mix of furnished studios, as well as one and two bedroom apartments, with equipped kitchens, and luxurious furnishings. Homes are decorated in warm, neutral tones and enjoy access to a wealth of amenities within the development. ",
    link: "tenora",

    images: [
      "/assets/contents/Damac/Tenora/cardimage.jpg",
      "/assets/contents/Damac/Tenora/pic1.jpg",
      "/assets/contents/Damac/Tenora/pic2.jpg",
      "/assets/contents/Damac/Tenora/pic3.jpg",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "security", "play", "shops", "garden", "bbq"],
    features: [
      "Landscaped garden",
      " Childrens play area",
      " Space for shops and Restaurent",
      " Multipurpsoe activity rooms for residents",
      "Sauna",
      "State of art gymnasium ",
      " Temparature Controlled Swimming Pool",
    ],
    developer: "damac",
    description:
      "<p>Dubai South is home to Dubai World Central (or Al Maktoum International) airport and the site of World Expo 2020. Comprising residential, commercial and logistics elements, Dubai South is the central hub of facilities, amenities and lifestyle offerings in the area. With direct links to rail, road, sea and air, the community combines business and innovation with quality of life.</p>",
  },
  // Golf Green 1, 2 bed
  {
    id: "damac7",
    title: "Golf Green(1 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.142524792016!2d55.24816617005596!3d25.0292368287982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6f483724e897%3A0x6dba1efd8f16a67!2sDamac%20Golf%20Greens!5e0!3m2!1sen!2sae!4v1690527249791!5m2!1sen!2sae",
    price: 1076000.0,
    size: [698.58],
    area: "damac hills",
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/golf-green/cardimage.webp",

    detail:
      "Golf Greens at Damac Hills, Dubai is an exceptional residential Twin Tower by Damac Properties with luxurious and elegant 1 & 2 bedroom apartments and 2 & 3 bedroom townhouses. This high-rise 20-storey development of towers A and B comes with astonishing infrastructure ornamented with some greenery in it. This lavish creation is the hub of many required and leisure-based amenities and services along with the stunning vistas of the lush green and Trump International Golf Course",
    link: "golf-green-1-bed",

    images: [
      "/assets/contents/Damac/golf-green/cardimage.webp",
      "/assets/contents/Damac/golf-green/pic1.webp",
      "/assets/contents/Damac/golf-green/pic2.webp",
      "/assets/contents/Damac/golf-green/pic3.webp",
      "/assets/contents/Damac/golf-green/pic4.webp",
      "/assets/contents/Damac/golf-green/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Aqua Garden",
      "Palm Garden",
      "Aqua Golf and Island Golf",
      " Serene Pool",
      "Sky Golf ",
      "Indoor Golf Simulator and Night Glowy Golf",
      "Ergonomic Seating Area",
    ],
    developer: "damac",
    description:
      "<p>Golf Greens is a stylish curation of 1- and 2-bedroom luxury apartments, and 2- and 3-bedroom luxury townhouses, inspired by nature's symphony and the golfer's soul.</p>",
  },
  {
    id: "damac8",
    title: "Golf Green(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.142524792016!2d55.24816617005596!3d25.0292368287982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6f483724e897%3A0x6dba1efd8f16a67!2sDamac%20Golf%20Greens!5e0!3m2!1sen!2sae!4v1690527249791!5m2!1sen!2sae",
    price: 1697000.0,
    size: [1212.02],
    area: "damac hills",
    type: "apartments/flats",
    bedrooms: 2,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/golf-green/cardimage.webp",

    detail:
      "Golf Greens at Damac Hills, Dubai is an exceptional residential Twin Tower by Damac Properties with luxurious and elegant 1 & 2 bedroom apartments and 2 & 3 bedroom townhouses. This high-rise 20-storey development of towers A and B comes with astonishing infrastructure ornamented with some greenery in it. This lavish creation is the hub of many required and leisure-based amenities and services along with the stunning vistas of the lush green and Trump International Golf Course",
    link: "golf-green-2-bed",

    images: [
      "/assets/contents/Damac/golf-green/cardimage.webp",
      "/assets/contents/Damac/golf-green/pic1.webp",
      "/assets/contents/Damac/golf-green/pic2.webp",
      "/assets/contents/Damac/golf-green/pic3.webp",
      "/assets/contents/Damac/golf-green/pic4.webp",
      "/assets/contents/Damac/golf-green/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Aqua Garden",
      "Palm Garden",
      "Aqua Golf and Island Golf",
      " Serene Pool",
      "Sky Golf ",
      "Indoor Golf Simulator and Night Glowy Golf",
      "Ergonomic Seating Area",
    ],
    developer: "damac",
    description:
      "<p>Golf Greens is a stylish curation of 1- and 2-bedroom luxury apartments, and 2- and 3-bedroom luxury townhouses, inspired by nature's symphony and the golfer's soul.</p>",
  },
  //  Damac Lagoons Maltan1 4 and 5 beds
  {
    id: "damac9",
    title: "MALTA 1-DAMAC Lagoons(4 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.5020356262644!2d55.235487174052764!3d25.017031277827396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f711ebc19261b%3A0x99f06416331f173f!2sDAMAC%20Lagoons!5e0!3m2!1sen!2sae!4v1690529870785!5m2!1sen!2sae",
    price: 2929000.0,
    size: [2273.77],
    area: "damac hills",
    type: "villa/mansion",
    bedrooms: 4,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/malta1/cardimage.webp",

    detail:
      "Malta is a new residential cluster by Damac Properties that features of 4 & 5 bedrooms townhouses located at Damac Lagoons, Dubai. These exclusively designed townhouses present a Mediterranean lifestyle. The extra-vantage facilities from the lagoon to beach areas are on the front to offer you a warm welcome with its surprising leisure design areas of attraction",
    link: "malta-1-damac-lagoons-4-bed",

    images: [
      "/assets/contents/Damac/malta1/cardimage.webp",
      "/assets/contents/Damac/malta1/pic1.webp",
      "/assets/contents/Damac/malta1/pic2.webp",
      "/assets/contents/Damac/malta1/pic3.webp",
      "/assets/contents/Damac/malta1/pic4.webp",
      "/assets/contents/Damac/malta1/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Health Care Centre",
      "24x7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
    ],
    developer: "damac",
    description:
      "<p>Explore a host of attractions and facilities, while living at this viable address that offers you access to kayaking-like amenities at service. The retail areas, beach promenade spots, and relaxing areas further ensure occupants can access every amenity at their doorstep</p>",
  },

  {
    id: "damac10",
    title: "MALTA 1-DAMAC Lagoons(5 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.5020356262644!2d55.235487174052764!3d25.017031277827396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f711ebc19261b%3A0x99f06416331f173f!2sDAMAC%20Lagoons!5e0!3m2!1sen!2sae!4v1690529870785!5m2!1sen!2sae",
    price: 4080000.0,
    size: [3377.72],
    area: "damac hills",
    type: "villa/mansion",
    bedrooms: 5,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/malta1/cardimage.webp",

    detail:
      "Malta is a new residential cluster by Damac Properties that features of 4 & 5 bedrooms townhouses located at Damac Lagoons, Dubai. These exclusively designed townhouses present a Mediterranean lifestyle. The extra-vantage facilities from the lagoon to beach areas are on the front to offer you a warm welcome with its surprising leisure design areas of attraction",
    link: "malta-1-damac-lagoons-5-bed",

    images: [
      "/assets/contents/Damac/malta1/cardimage.webp",
      "/assets/contents/Damac/malta1/pic1.webp",
      "/assets/contents/Damac/malta1/pic2.webp",
      "/assets/contents/Damac/malta1/pic3.webp",
      "/assets/contents/Damac/malta1/pic4.webp",
      "/assets/contents/Damac/malta1/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Health Care Centre",
      "24x7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
    ],
    developer: "damac",
    description:
      "<p>Explore a host of attractions and facilities, while living at this viable address that offers you access to kayaking-like amenities at service. The retail areas, beach promenade spots, and relaxing areas further ensure occupants can access every amenity at their doorstep</p>",
  },
  // Damac Lagoons  MOROCCO 1 6 and 7 beds
  {
    id: "damac11",
    title: "Damac Lagoons  MOROCCO 1(6 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.5020356262644!2d55.235487174052764!3d25.017031277827396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f711ebc19261b%3A0x99f06416331f173f!2sDAMAC%20Lagoons!5e0!3m2!1sen!2sae!4v1690529870785!5m2!1sen!2sae",
    price: 4080000.0,
    size: [3377.72],
    area: "damac hills",
    type: "villa/mansion",
    bedrooms: 6,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/moracco/cardimage.webp",

    detail:
      "Morocco Villas comes in a new cluster at Damac Lagoons, where the lifestyle is awesome with exclusive waterfront services. The most exceptional and ideal form of lifestyle with a number of amenities ranging in various aspects of life. Sports, entertainment, social, and leisure elements are all at your ease",
    link: "damac-lagoons-morocco-1-6-bed",

    images: [
      "/assets/contents/Damac/moracco/cardimage.webp",
      "/assets/contents/Damac/moracco/pic1.webp",
      "/assets/contents/Damac/moracco/pic2.webp",
      "/assets/contents/Damac/moracco/pic3.webp",
      "/assets/contents/Damac/moracco/pic4.webp",
      "/assets/contents/Damac/moracco/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Health Care Centre",
      "24x7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "BBQ Areas",
      "Running and Jogging Track",
      "Pools and Water Activities",
    ],
    developer: "damac",
    description:
      "<p>The DAMAC Lagoons community is located amidst the picturesque surroundings of DAMAC Hills, Tilal Al Ghaf, and Remraam. Its strategic location allows residents to easily access any part of Dubai, with Downtown Dubai, Business Bay, and Dubai International Airport being just 35-minutes away from the community. Dubai Marina is a 30-minute drive away, while Deira can be reached within 40 minutes</p>",
  },
  {
    id: "damac12",
    title: "Damac Lagoons  MOROCCO 1(7 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.5020356262644!2d55.235487174052764!3d25.017031277827396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f711ebc19261b%3A0x99f06416331f173f!2sDAMAC%20Lagoons!5e0!3m2!1sen!2sae!4v1690529870785!5m2!1sen!2sae",
    price: 4080000.0,
    size: [3377.72],
    area: "damac hills",
    type: "villa/mansion",
    bedrooms: 7,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/moracco/cardimage.webp",

    detail:
      "Morocco Villas comes in a new cluster at Damac Lagoons, where the lifestyle is awesome with exclusive waterfront services. The most exceptional and ideal form of lifestyle with a number of amenities ranging in various aspects of life. Sports, entertainment, social, and leisure elements are all at your ease",
    link: "damac-lagoons-morocco-1-7-bed",

    images: [
      "/assets/contents/Damac/moracco/cardimage.webp",
      "/assets/contents/Damac/moracco/pic1.webp",
      "/assets/contents/Damac/moracco/pic2.webp",
      "/assets/contents/Damac/moracco/pic3.webp",
      "/assets/contents/Damac/moracco/pic4.webp",
      "/assets/contents/Damac/moracco/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Health Care Centre",
      "24x7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "BBQ Areas",
      "Running and Jogging Track",
      "Pools and Water Activities",
    ],
    developer: "damac",
    description:
      "<p>The DAMAC Lagoons community is located amidst the picturesque surroundings of DAMAC Hills, Tilal Al Ghaf, and Remraam. Its strategic location allows residents to easily access any part of Dubai, with Downtown Dubai, Business Bay, and Dubai International Airport being just 35-minutes away from the community. Dubai Marina is a 30-minute drive away, while Deira can be reached within 40 minutes</p>",
  },
  // CAMELIA-DAMAC Hills 3 and 4 bed
  {
    id: "damac13",
    title: "Camalia-Damac Hills(3 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57853.72091841797!2d55.28423576279801!3d25.00495567545931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f7bb4e9c9244b%3A0xb3a7418c72b28b88!2sCamelia%20villas!5e0!3m2!1sen!2sae!4v1690539394435!5m2!1sen!2sae",
    price: 1420000.0,
    size: [1721.47],
    area: "damac hills",
    type: "villa/mansion",
    bedrooms: 3,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/camalia/cardimage.webp",

    detail:
      "Find home in a community with its own one-of-a-kind charm at Camelia Villas - a collection of townhouses surrounded by sports amenities, water attractions and other extraordinary experiences. This is a life well lived.At Camelia, no two days are ever the same. Steps from your doorstep are the cheerful vibes at Malibu Beach and other experiences. Ride the waves, catch a high-spirited game of football, or cuddle a rabbit every day expresses itself in its own unique way.",
    link: "camalia-damac-hills-3-Bed",

    images: [
      "/assets/contents/Damac/camalia/cardimage.webp",
      "/assets/contents/Damac/camalia/pic1.webp",
      "/assets/contents/Damac/camalia/pic2.webp",
      "/assets/contents/Damac/camalia/pic3.webp",
      "/assets/contents/Damac/camalia/pic4.webp",
      "/assets/contents/Damac/camalia/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Health Care Centre",
      "24x7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "BBQ Areas",
      "Running and Jogging Track",
      "Pools and Water Activities",
    ],
    developer: "damac",
    description:
      "<p>The latest launch of Camelia Villas at Damac Hills 2 brings a new joy brought by Damac Properties offering 3-bedroom luxury townhouses with epic amenities. Timeless in designs and creative in decor, these astonishing townhouses are a new world of superior comfort combined with refined excellence to make your life what you have always wanted.</p>",
  },
  {
    id: "damac14",
    title: "Camalia-Damac Hills(4 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57853.72091841797!2d55.28423576279801!3d25.00495567545931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f7bb4e9c9244b%3A0xb3a7418c72b28b88!2sCamelia%20villas!5e0!3m2!1sen!2sae!4v1690539394435!5m2!1sen!2sae",
    price: 1763000.0,
    size: [2261.3],
    area: "damac hills",
    type: "villa/mansion",
    bedrooms: 3,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/camalia/cardimage.webp",

    detail:
      "Find home in a community with its own one-of-a-kind charm at Camelia Villas - a collection of townhouses surrounded by sports amenities, water attractions and other extraordinary experiences. This is a life well lived.At Camelia, no two days are ever the same. Steps from your doorstep are the cheerful vibes at Malibu Beach and other experiences. Ride the waves, catch a high-spirited game of football, or cuddle a rabbit every day expresses itself in its own unique way.",
    link: "camalia-damac-hills-4-Bed",

    images: [
      "/assets/contents/Damac/camalia/cardimage.webp",
      "/assets/contents/Damac/camalia/pic1.webp",
      "/assets/contents/Damac/camalia/pic2.webp",
      "/assets/contents/Damac/camalia/pic3.webp",
      "/assets/contents/Damac/camalia/pic4.webp",
      "/assets/contents/Damac/camalia/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Health Care Centre",
      "24x7 Security",
      "Dining Outlets",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "BBQ Areas",
      "Running and Jogging Track",
      "Pools and Water Activities",
    ],
    developer: "damac",
    description:
      "<p>The latest launch of Camelia Villas at Damac Hills 2 brings a new joy brought by Damac Properties offering 3-bedroom luxury townhouses with epic amenities. Timeless in designs and creative in decor, these astonishing townhouses are a new world of superior comfort combined with refined excellence to make your life what you have always wanted.</p>",
  },
  //  Damac Bay 2Tower
  {
    id: "damac15",
    title: "Damac Bay Phase(1 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.2676605629204!2d55.1408850758491!3d25.092799277778365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b62d5f41581%3A0x839e48152f61481c!2sDAMAC%20Bay%20by%20Cavalli!5e0!3m2!1sen!2sae!4v1690804967230!5m2!1sen!2sae",
    price: 3688000.0,
    size: [824.95],
    area: "dubai creek harbour",
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/damac-bay-2//cardimage.webp",

    detail:
      "A marvellous development, Damac Bay Phase 2 at Dubai Harbour now comes with 1, 2 & 3 bedroom apartments and 3 to 5 bedroom super luxury duplexes by Cavalli with private pools and down-the-line facilities. An extensive stretch of luxury to its core with a striking 49-storey tower that spirits harmony and exhilaration, lined with rare amenities from floating relaxation pods to the infinity pool",
    link: "damac-bay-phase-1-bed",

    images: [
      "/assets/contents/Damac/damac-bay-2/cardimage.webp",
      "/assets/contents/Damac/damac-bay-2/pic1.webp",
      "/assets/contents/Damac/damac-bay-2/pic2.webp",
      "/assets/contents/Damac/damac-bay-2/pic3.webp",
      "/assets/contents/Damac/damac-bay-2/pic4.webp",
      "/assets/contents/Damac/damac-bay-2/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Beach Access",
      "Fitness Centre",
      "Gardens and Parks",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "Yoga & Meditation",
      "Retail & Dining Options",
      "Multi Purpose Lounge",
      "Marina & Yacht Club",
      "Parks and Leisure Areas",
    ],
    developer: "damac",
    description:
      "<p>A wave of fresh air blended with the fragrance of waters with the refreshing dynamism of Dubai. The living can be really wonderful here. Immerse in the pleasant atmosphere with a climate-controlled world surrounded by nature's finest greenery and services. Elegant development with the finest of the services and amenities of sports, entertainment, well-being and health makes you go for it</p>",
  },

  {
    id: "damac16",
    title: "Damac Bay Phase(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.2676605629204!2d55.1408850758491!3d25.092799277778365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b62d5f41581%3A0x839e48152f61481c!2sDAMAC%20Bay%20by%20Cavalli!5e0!3m2!1sen!2sae!4v1690804967230!5m2!1sen!2sae",
    price: 5181000.0,
    size: [1146.14],
    area: "dubai creek harbour",
    type: "apartments/flats",
    bedrooms: 2,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/damac-bay-2//cardimage.webp",

    detail:
      "A marvellous development, Damac Bay Phase 2 at Dubai Harbour now comes with 1, 2 & 3 bedroom apartments and 3 to 5 bedroom super luxury duplexes by Cavalli with private pools and down-the-line facilities. An extensive stretch of luxury to its core with a striking 49-storey tower that spirits harmony and exhilaration, lined with rare amenities from floating relaxation pods to the infinity pool",
    link: "damac-bay-phase-2-bed",

    images: [
      "/assets/contents/Damac/damac-bay-2/cardimage.webp",
      "/assets/contents/Damac/damac-bay-2/pic1.webp",
      "/assets/contents/Damac/damac-bay-2/pic2.webp",
      "/assets/contents/Damac/damac-bay-2/pic3.webp",
      "/assets/contents/Damac/damac-bay-2/pic4.webp",
      "/assets/contents/Damac/damac-bay-2/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Beach Access",
      "Fitness Centre",
      "Gardens and Parks",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "Yoga & Meditation",
      "Retail & Dining Options",
      "Multi Purpose Lounge",
      "Marina & Yacht Club",
      "Parks and Leisure Areas",
    ],
    developer: "damac",
    description:
      "<p>A wave of fresh air blended with the fragrance of waters with the refreshing dynamism of Dubai. The living can be really wonderful here. Immerse in the pleasant atmosphere with a climate-controlled world surrounded by nature's finest greenery and services. Elegant development with the finest of the services and amenities of sports, entertainment, well-being and health makes you go for it</p>",
  },

  {
    id: "damac17",
    title: "Damac Bay Phase(3 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.2676605629204!2d55.1408850758491!3d25.092799277778365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b62d5f41581%3A0x839e48152f61481c!2sDAMAC%20Bay%20by%20Cavalli!5e0!3m2!1sen!2sae!4v1690804967230!5m2!1sen!2sae",
    price: 7199000.0,
    size: [1628.58],
    area: "dubai creek harbour",
    type: "apartments/flats",
    bedrooms: 3,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/damac-bay-2//cardimage.webp",

    detail:
      "A marvellous development, Damac Bay Phase 2 at Dubai Harbour now comes with 1, 2 & 3 bedroom apartments and 3 to 5 bedroom super luxury duplexes by Cavalli with private pools and down-the-line facilities. An extensive stretch of luxury to its core with a striking 49-storey tower that spirits harmony and exhilaration, lined with rare amenities from floating relaxation pods to the infinity pool",
    link: "damac-bay-phase-3-bed",

    images: [
      "/assets/contents/Damac/damac-bay-2/cardimage.webp",
      "/assets/contents/Damac/damac-bay-2/pic1.webp",
      "/assets/contents/Damac/damac-bay-2/pic2.webp",
      "/assets/contents/Damac/damac-bay-2/pic3.webp",
      "/assets/contents/Damac/damac-bay-2/pic4.webp",
      "/assets/contents/Damac/damac-bay-2/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Beach Access",
      "Fitness Centre",
      "Gardens and Parks",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "Yoga & Meditation",
      "Retail & Dining Options",
      "Multi Purpose Lounge",
      "Marina & Yacht Club",
      "Parks and Leisure Areas",
    ],
    developer: "damac",
    description:
      "<p>A wave of fresh air blended with the fragrance of waters with the refreshing dynamism of Dubai. The living can be really wonderful here. Immerse in the pleasant atmosphere with a climate-controlled world surrounded by nature's finest greenery and services. Elegant development with the finest of the services and amenities of sports, entertainment, well-being and health makes you go for it</p>",
  },
  //Damac bay
  {
    id: "damac18",
    title: "Damac Bay(1 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115624.56511701363!2d54.991024697265644!3d25.092799300000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b62d5f41581%3A0x839e48152f61481c!2sDAMAC%20Bay%20by%20Cavalli!5e0!3m2!1sen!2sae!4v1690868617730!5m2!1sen!2sae",
    price: 3920000.0,
    size: [845.72],
    area: "dubai creek harbour",
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/damac-bay/cardimage.webp",

    detail:
      "Damac BAY at Dubai Harbour is the latest residential development by Damac Properties that features 1, 2 & 3 bedroom seafront apartments, interiors by Cavalli at Dubai. The adventurous journey of the Dubai Harbour comes to your home, where you start a new lifestyle lined with an exclusive dose of facilities. The exclusive project presents the state-of-the-art design infrastructure providing one of the exclusive addresses at Dubai with awe-inspiring views of the Skylines",
    link: "damac-bay-1-bed",

    images: [
      "/assets/contents/Damac/damac-bay/cardimage.webp",
      "/assets/contents/Damac/damac-bay/pic1.webp",
      "/assets/contents/Damac/damac-bay/pic2.webp",
      "/assets/contents/Damac/damac-bay/pic3.webp",
      "/assets/contents/Damac/damac-bay/pic4.webp",
      "/assets/contents/Damac/damac-bay/pic5.webp",
      "/assets/contents/Damac/damac-bay/pic6.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Beach Access",
      "Fitness Centre",
      "Gardens and Parks",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "Yoga & Meditation",
      "Retail & Dining Options",
      "Multi Purpose Lounge",
      "Marina & Yacht Club",
      "Parks and Leisure Areas",
    ],
    developer: "damac",
    description:
      "<p>This is a new home for premium individuals who want luxury lined with premium facilities. The locality is a nexus of business centers, entertainment zones, sports facilities, and waterfront dining venues. You have access to a wealth of possibilities that can elevate your abilities to a higher level while you work to improve your life. Discover the authentic flavor of delights that can be found within these magnificent waterfront residences here.</p>",
  },

  {
    id: "damac19",
    title: "Damac Bay(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115624.56511701363!2d54.991024697265644!3d25.092799300000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b62d5f41581%3A0x839e48152f61481c!2sDAMAC%20Bay%20by%20Cavalli!5e0!3m2!1sen!2sae!4v1690868617730!5m2!1sen!2sae",
    price: 7848000.0,
    size: [1735.36],
    area: "dubai creek harbour",
    type: "apartments/flats",
    bedrooms: 2,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/damac-bay/cardimage.webp",

    detail:
      "Damac BAY at Dubai Harbour is the latest residential development by Damac Properties that features 1, 2 & 3 bedroom seafront apartments, interiors by Cavalli at Dubai. The adventurous journey of the Dubai Harbour comes to your home, where you start a new lifestyle lined with an exclusive dose of facilities. The exclusive project presents the state-of-the-art design infrastructure providing one of the exclusive addresses at Dubai with awe-inspiring views of the Skylines",
    link: "damac-bay-2-bed",

    images: [
      "/assets/contents/Damac/damac-bay/cardimage.webp",
      "/assets/contents/Damac/damac-bay/pic1.webp",
      "/assets/contents/Damac/damac-bay/pic2.webp",
      "/assets/contents/Damac/damac-bay/pic3.webp",
      "/assets/contents/Damac/damac-bay/pic4.webp",
      "/assets/contents/Damac/damac-bay/pic5.webp",
      "/assets/contents/Damac/damac-bay/pic6.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Beach Access",
      "Fitness Centre",
      "Gardens and Parks",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "Yoga & Meditation",
      "Retail & Dining Options",
      "Multi Purpose Lounge",
      "Marina & Yacht Club",
      "Parks and Leisure Areas",
    ],
    developer: "damac",
    description:
      "<p>This is a new home for premium individuals who want luxury lined with premium facilities. The locality is a nexus of business centers, entertainment zones, sports facilities, and waterfront dining venues. You have access to a wealth of possibilities that can elevate your abilities to a higher level while you work to improve your life. Discover the authentic flavor of delights that can be found within these magnificent waterfront residences here.</p>",
  },

  {
    id: "damac20",
    title: "Damac Bay(3 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115624.56511701363!2d54.991024697265644!3d25.092799300000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b62d5f41581%3A0x839e48152f61481c!2sDAMAC%20Bay%20by%20Cavalli!5e0!3m2!1sen!2sae!4v1690868617730!5m2!1sen!2sae",
    price: 8927000.0,
    size: [2012.64],
    area: "dubai creek harbour",
    type: "apartments/flats",
    bedrooms: 3,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/damac-bay/cardimage.webp",

    detail:
      "Damac BAY at Dubai Harbour is the latest residential development by Damac Properties that features 1, 2 & 3 bedroom seafront apartments, interiors by Cavalli at Dubai. The adventurous journey of the Dubai Harbour comes to your home, where you start a new lifestyle lined with an exclusive dose of facilities. The exclusive project presents the state-of-the-art design infrastructure providing one of the exclusive addresses at Dubai with awe-inspiring views of the Skylines",
    link: "damac-bay-3-bed",

    images: [
      "/assets/contents/Damac/damac-bay/cardimage.webp",
      "/assets/contents/Damac/damac-bay/pic1.webp",
      "/assets/contents/Damac/damac-bay/pic2.webp",
      "/assets/contents/Damac/damac-bay/pic3.webp",
      "/assets/contents/Damac/damac-bay/pic4.webp",
      "/assets/contents/Damac/damac-bay/pic5.webp",
      "/assets/contents/Damac/damac-bay/pic6.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Beach Access",
      "Fitness Centre",
      "Gardens and Parks",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Swimming Pool",
      "Yoga & Meditation",
      "Retail & Dining Options",
      "Multi Purpose Lounge",
      "Marina & Yacht Club",
      "Parks and Leisure Areas",
    ],
    developer: "damac",
    description:
      "<p>This is a new home for premium individuals who want luxury lined with premium facilities. The locality is a nexus of business centers, entertainment zones, sports facilities, and waterfront dining venues. You have access to a wealth of possibilities that can elevate your abilities to a higher level while you work to improve your life. Discover the authentic flavor of delights that can be found within these magnificent waterfront residences here.</p>",
  },

  {
    id: "damac21",
    title: "Verona(4 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.4867965683243!2d55.38386947608432!3d24.983569640319022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f7b5968634711%3A0x7781032ab63a6f14!2sVERONA!5e0!3m2!1sen!2sae!4v1691214097912!5m2!1sen!2sae",
    price: 1829000.0,
    size: [2352.26],
    area: "damac hills",
    type: "villa/mansion",
    bedrooms: 4,
    bathrooms: 0,
    status: "off-plan",
    img: "/assets/contents/Damac/verona/cardimage.webp",

    detail:
      "Nestled within the esteemed DAMAC Hills 2, Verona beckons you to a life of abundance, where every moment is enriched with luxury and comfort. Life here is beyond the ordinary, offering a world of its own, brimming with top-notch amenities and captivating attractions",
    link: "verona-4-bed",

    images: ["/assets/contents/Damac/verona/cardimage.webp"],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "play", "shops", "garden"],
    features: [
      "Cycling Trails",
      "Fitness Centre",
      "Gardens and Parks",
      "Fitness Centre",
      "Green Surrounding",
      "Gymnasium",
      "Parking Facility",
      "Schools and Institutes",
      "Yoga & Meditation",
      "Retail & Dining Options",
      "Multi Purpose Lounge",
      "Tennis Courts",
      "Spa and Sauna",
      "Parks and Leisure Areas",
      "Outdoor Dining Area",
      "Shopping Mall",
    ],
    developer: "damac",
    description:
      "<p>The allure of the development is heightened by the abundance of outdoor recreational facilities. Sports enthusiasts can indulge in various activities, including tennis, basketball, and football. The beautifully designed golf course provides golf aficionados with the perfect opportunity to refine their skills amidst stunning surroundings. The community's water attractions are nothing short of enchanting. Residents can enjoy leisurely strolls along the scenic waterfront, take refreshing dips in the swimming pools, or partake in water sports to experience the thrill of adventure.</p>",
  },

  // danube properties
  {
    id: "danube1",
    title: "Fashionz by DANUBE",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.7624177204307!2d55.18616447405396!3d25.042135577811077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d328f8d8135%3A0xd15bdf8fe1c9f4da!2sFashionz%20by%20Danube!5e0!3m2!1sen!2sae!4v1690014812467!5m2!1sen!2sae",
    price: 1791000,
    area: "Dubai South",
    type: "apartments/flats",
    bedrooms: 2,
    status: "ready to move",
    img: "/assets/contents/Danube/Fashonz/cardImage.webp",

    detail:
      "Dubai’s first FashionTV Branded Residences FASHIONZ - A Haute Couture lifestyle, where individuality is celebrated and fashion comes alive every day. Fall head over heels as you experience luxury living at FASHIONZ, one of the tallest towers in Jumeirah Village Triangle.Inspired by style icons, FASHIONZ is fashionably modern with a perfect blend of indoor and outdoor amenities to bring you a world of comfort, luxury, and style",
    link: "fashionz-by-danube",

    images: [
      "/assets/contents/Danube/Fashonz/cardImage.webp",
      "/assets/contents/Danube/Fashonz/pic1.webp",
      "/assets/contents/Danube/Fashonz/pic2.webp",
      "/assets/contents/Danube/Fashonz/pic3.webp",
      "/assets/contents/Danube/Fashonz/pic4.webp",
      "/assets/contents/Danube/Fashonz/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "security", "play", "shops", "garden", "bbq"],
    features: [
      "Kids Pool",
      "Swimming Pool",
      " Tennis Court",
      "Jacuzzi",
      "Sauna",
      "Fashion Plazza ",
      "Japanese Garden",
      "Fashion Ramp",
    ],
    developer: "danube-properties",
    description:
      "<p>Dubai South is home to Dubai World Central (or Al Maktoum International) airport and the site of World Expo 2020. Comprising residential, commercial and logistics elements, Dubai South is the central hub of facilities, amenities and lifestyle offerings in the area. With direct links to rail, road, sea and air, the community combines business and innovation with quality of life.</p>",
  },
  {
    id: "danube2",
    title: "Viewz by DANUBE",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.0684305284694!2d55.138346774054945!3d25.065669677795853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d6eefc498cd%3A0x3f01ceb8583f9458!2sViewz%20by%20Danube%201!5e0!3m2!1sen!2sae!4v1690023827034!5m2!1sen!2sae",
    price: 9668000,
    area: "Jumeirah Lake Towers",
    type: "apartments/flats",
    bedrooms: 4,
    bathrooms: 6,
    status: "ready to move",
    img: "/assets/contents/Danube/Viewz/cardimage.webp",
    detail:
      "Viewz will house a bespoke selection of beautifully crafted studios, 1-bedroom presidential suites and 1-3 bedroom apartments. Those who wish to experience true opulence can choose from a limited collection of 4-bedroom sky villas located on the 50th floor. The Viewz by Danube will be interconnected with 2 skybridges, which adds an air of sophistication to the upcoming masterpiece. Select layouts will come with private studies, whilst all presidential suites and 2-3 bedroom units will feature a private swimming pool.",
    link: "viewz-by-danube",
    featured: true,
    images: [
      "/assets/contents/Danube/Viewz/cardimage.webp",
      "/assets/contents/Danube/Viewz/pic1.webp",
      "/assets/contents/Danube/Viewz/pic2.webp",
      "/assets/contents/Danube/Viewz/pic4.webp",
      "/assets/contents/Danube/Viewz/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "jog",
      "car-parking",
    ],
    features: [
      "Kids Pool",
      "Infinity Pool",
      "Fitness center",
      "furnished Residency",
      "Green Areas",
    ],
    developer: "danube-properties",
    description:
      "<p>Danube Viewz will be situated next to the Cluster K in Jumeirah Lake Towers, with easy access to First Al Khail St. The convenient location of the premium complex will allow hassle-free commutes around the rest of Dubai. For instance, some of the most sought-after destinations, including Palm Jumeirah and Dubai Marina can be reached within a 10-minute drive</p>",
  },

  {
    id: "danube3",
    title: "Elitz by DANUBE",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.1046753251126!2d55.21522838471256!3d25.064441076399135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6dfdfc27575d%3A0x55d76e8bbad3fb6b!2sElitz%20By%20Danube!5e0!3m2!1sen!2sae!4v1690175986603!5m2!1sen!2sae",
    price: 1525000,
    area: "jumeirah village circle",
    type: "apartments/flats",
    bedrooms: 2,

    status: "ready to move",
    img: "/assets/contents/Danube/Elitz/cardImage.webp",

    detail:
      "Elitz by Danube is located at a prime district in the well-developed community of JVC and is set to be an architectural landmark in the area. The building design is one of its kind with an exclusive glass facade and easy access to Al Khail Road, Sheikh Mohammed Bin Zayed Road and Sheikh Zayed Road.",
    link: "elitz-by-danube",

    images: [
      "/assets/contents/Danube/Elitz/pic1.webp",
      "/assets/contents/Danube/Elitz/pic2.webp",
      "/assets/contents/Danube/Elitz/pic3.webp",
      "/assets/contents/Danube/Elitz/pic4.webp",
    ],
    roomtype: "bedrooms",

    amenties: ["gym", "pool", "security", "play", "shops", "garden", "bbq"],
    features: [
      "Outdoor cabanas with BBQ area",
      "Infinity Pool",
      "Mini golf",
      "Cricket pitch",
      "Green Areas",
      "Outdoor cinema",
      "Jogging track",
      "Ablution room",
    ],
    developer: "danube-properties",
    description:
      "<p>Elitz Apartments are the newest launch of Danube Properties comprising of two twin towers. One of the towers is a 36-storey building and the other is a 39-storey tower. These towers include commercial and retail units as well. You can see other features of the project below</p>",
  },

  {
    id: "danube4",
    title: "PETALZ By Danube(2 Bed) ",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.0671619302993!2d55.39354397405933!3d25.16720997773021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f67103a0248a1%3A0x188041fe6c356103!2sPetalz%20By%20Danube!5e0!3m2!1sen!2sae!4v1690179460715!5m2!1sen!2sae",
    price: "1077000",
    size: [1102],
    area: "jumeirah village circle",
    type: "apartments/flats",
    bedrooms: 2,
    bathrooms: 4,
    status: "ready to move",
    img: "/assets/contents/Danube/Petalz/cardimage.jpg",

    detail:
      "Greenery and open spaces, world-class amenities, best infrastructure, and a thriving community. Petalz by Danube is an exclusive gated community that offers a tranquil living experience within the bustling city of Dubai. Here you can pick from Studio, 1, and 2 BR variants that offers space for your dreams and loved ones.",
    link: "petalz-by-danube-2-bed",

    images: [
      "/assets/contents/Danube/Petalz/cardimage.jpg",
      "/assets/contents/Danube/Petalz/pic1.jpg",
      "/assets/contents/Danube/Petalz/pic2.jpg",
      "/assets/contents/Danube/Petalz/pic3.jpg",
      "/assets/contents/Danube/Petalz/pic4.jpg",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "lobby",
      "play-area",
    ],
    features: [
      "Outdoor cabanas with BBQ area",
      "Toddler Splash Pad",
      "Open Air Cinema",
      "Swimming Pool In Balcony",
      "Party Hall",
      "Beauty Salon",
      "Infinity Pool",
      "Mini golf",
      "Cricket pitch",
      "Green Areas",
      "Outdoor cinema",
      "Jogging track",
      "Ablution room",
    ],
    developer: "danube-properties",
    description:
      "<p>Greenery, open spaces, world-class amenities, the best infrastructure, and a thriving community. Project Petalz by Danube properties is an exclusive gated community that offers a tranquil living experience within the bustling city of Dubai. Here you can pick from Studio, 1, and 2 BR variants that offers space for your dreams and loved ones.</p>",
  },
  {
    id: "danube5",
    title: "PETALZ By Danube ",
    featured: true,
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.0671619302993!2d55.39354397405933!3d25.16720997773021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f67103a0248a1%3A0x188041fe6c356103!2sPetalz%20By%20Danube!5e0!3m2!1sen!2sae!4v1690179460715!5m2!1sen!2sae",
    price: 767000,
    area: "jumeirah village circle",
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 2,
    status: "ready to move",
    img: "/assets/contents/Danube/Petalz/pic3.jpg",

    detail:
      "Greenery and open spaces, world-class amenities, best infrastructure, and a thriving community. Petalz by Danube is an exclusive gated community that offers a tranquil living experience within the bustling city of Dubai. Here you can pick from Studio, 1, and 2 BR variants that offers space for your dreams and loved ones.",
    link: "petalz-by-danube",

    images: [
      "/assets/contents/Danube/Petalz/cardimage.jpg",
      "/assets/contents/Danube/Petalz/pic1.jpg",
      "/assets/contents/Danube/Petalz/pic2.jpg",
      "/assets/contents/Danube/Petalz/pic3.jpg",
      "/assets/contents/Danube/Petalz/pic4.jpg",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "lobby",
      "play-area",
    ],
    features: [
      "Outdoor cabanas with BBQ area",
      "Toddler Splash Pad",
      "Open Air Cinema",
      "Swimming Pool In Balcony",
      "Party Hall",
      "Beauty Salon",
      "Infinity Pool",
      "Mini golf",
      "Cricket pitch",
      "Green Areas",
      "Outdoor cinema",
      "Jogging track",
      "Ablution room",
    ],
    developer: "danube-properties",
    description:
      "<p>Greenery, open spaces, world-class amenities, the best infrastructure, and a thriving community. Project Petalz by Danube properties is an exclusive gated community that offers a tranquil living experience within the bustling city of Dubai. Here you can pick from Studio, 1, and 2 BR variants that offers space for your dreams and loved ones.</p>",
  },

  {
    id: "danube6",
    title: "OPALZ by Danube(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.9519734920173!2d55.244249674055084!3d25.069616877793354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6f1ac588b9d3%3A0xf97449202427865!2sOpalz%20by%20Danube!5e0!3m2!1sen!2sae!4v1690188722371!5m2!1sen!2sae",
    price: 2623000,
    size: [2674],
    area: "Dubai Science Park",
    type: "apartments/flats",
    bedrooms: 2,

    status: "ready to move",
    img: "/assets/contents/Danube/Opalz/cardimage.jpg",

    detail:
      "Various amenities, facilities, and resources provide premium customers with numerous joys and opportunities to explore the many premium locations, such as a games room, jacuzzi, clubhouse, parks, and gardens. However, gorgeous finishes and fixture availability make life easier for everyone. The tranquillity of the environment, combined with finely shaped infrastructure and modern designs and places.",
    link: "opalz-by-danube-2-bed",

    images: [
      "/assets/contents/Danube/Opalz/cardimage.jpg",
      "/assets/contents/Danube/Opalz/pic1.jpg",
      "/assets/contents/Danube/Opalz/pic2.jpg",
      "/assets/contents/Danube/Opalz/pic3.jpg",
      "/assets/contents/Danube/Opalz/pic4.jpg",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "lobby",
      "play-area",
    ],
    features: [
      "Outdoor cabanas with BBQ area",
      "Toddler Splash Pad",
      "Open Air Cinema",
      "Swimming Pool In Balcony",
      "Party Hall",
      "Beauty Salon",
      "Infinity Pool",
      "Mini golf",
      "Cricket pitch",
      "Green Areas",
      "Outdoor cinema",
      "Jogging track",
      "Ablution room",
    ],
    developer: "danube-properties",
    description:
      "<p>Opalz by Danube Properties  - A serene oasis amidst the bustling city where luxury meets the future. This unique architectural masterpiece is future-ready and offers the highest standard of opulent living. Located in the plushest precinct of Dubai, the beautiful two towers stand tall in the prime location of Dubai Science Park with easy access to Sheikh Zayed Road while offering energy-efficient amenities, innovative technology, green architecture, and exclusive access to modern amenities that’ll change your lifestyle forever.</p>",
  },

  {
    id: "danube7",
    title: "GEMZ by Danube(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.4660876586017!2d55.13835157405298!3d25.01825197782664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d891b1bddbb%3A0x94270e27b10b506!2sGemz%20by%20Danube!5e0!3m2!1sen!2sae!4v1690198157609!5m2!1sen!2sae",
    price: 1721000,
    size: [1434],
    area: "al furjan",
    type: "apartments/flats",
    bedrooms: 2,
    status: "ready to move",
    img: "/assets/contents/Danube/Gemz2bed/cardimage.jpg",

    detail:
      "GEMZ - a new project by Danube is redefining luxury living in the heart of Al Furjan. This unique project with a stunning pyramid shaped architecture is the upcoming landmark. Built on the prime location, the project offers spacious apartments, top-of-the line amenities, easy access to public transport and malls, and a lot more.",
    link: "gemz-by-danube-2-bed",

    images: [
      "/assets/contents/Danube/Gemz2bed/cardimage.jpg",
      "/assets/contents/Danube/Gemz2bed/pic1.jpg",
      "/assets/contents/Danube/Gemz2bed/pic2.jpg",
      "/assets/contents/Danube/Gemz2bed/pic3.jpg",
      "/assets/contents/Danube/Gemz2bed/pic4.jpg",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "lobby",
      "play-area",
    ],
    features: [
      "Kids Playing Area",
      "Party Hall",
      "Open Air Cinema",
      "Doctor On Call",
      "Party Hall",
      "Beauty Salon",
      "Infinity Pool",
      "Cricket Pitch",
      "Smart Office",
      "Swimming Pool In Balcony",
      "Outdoor cinema",
      "Jogging track",
      "Open Air Cinema",
    ],
    developer: "danube-properties",
    description:
      "<p>GEMZ by Danube properties is a new property redefining luxury living in the heart of Al Furjan. This unique project with stunning pyramid-shaped architecture is the upcoming landmark. Built-in a prime location, the project offers spacious apartments, top-of-the-line amenities, easy access to public transport and malls, and much more.</p>",
  },

  {
    id: "danube8",
    title: "GEMZ by Danube(3 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.4660876586017!2d55.13835157405298!3d25.01825197782664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d891b1bddbb%3A0x94270e27b10b506!2sGemz%20by%20Danube!5e0!3m2!1sen!2sae!4v1690198157609!5m2!1sen!2sae",
    price: 2150000,
    size: [1873],
    area: "al-furjan",
    type: "apartments/flats",
    bedrooms: 3,
    bathrooms: 5,

    status: "ready to move",
    img: "/assets/contents/Danube/gemz3bed/cardimage.jpg",

    detail:
      "GEMZ - a new project by Danube is redefining luxury living in the heart of Al Furjan. This unique project with a stunning pyramid shaped architecture is the upcoming landmark. Built on the prime location, the project offers spacious apartments, top-of-the line amenities, easy access to public transport and malls, and a lot more.",
    link: "gemz-by-danube-3-bed",

    images: [
      "/assets/contents/Danube/gemz3bed/cardimage.jpg",
      "/assets/contents/Danube/gemz3bed/pic1.jpg",
      "/assets/contents/Danube/gemz3bed/pic2.jpg",
      "/assets/contents/Danube/gemz3bed/pic3.jpg",
      "/assets/contents/Danube/gemz3bed/pic4.jpg",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "security",
      "play",
      "shops",
      "garden",
      "bbq",
      "lobby",
      "play-area",
    ],
    features: [
      "Kids Playing Area",
      "Party Hall",
      "Open Air Cinema",
      "Doctor On Call",
      "Party Hall",
      "Beauty Salon",
      "Infinity Pool",
      "Cricket Pitch",
      "Smart Office",
      "Swimming Pool In Balcony",
      "Outdoor cinema",
      "Jogging track",
      "Open Air Cinema",
    ],
    developer: "danube-properties",
    description:
      "<p>GEMZ by Danube properties is a new property redefining luxury living in the heart of Al Furjan. This unique project with stunning pyramid-shaped architecture is the upcoming landmark. Built-in a prime location, the project offers spacious apartments, top-of-the-line amenities, easy access to public transport and malls, and much more.</p>",
  },
  // AYS developer

  {
    id: "ays1",
    title: "Q GARDENS LOFTS(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.424371284567!2d55.2084207!3d25.0536018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6db03e0f0cc7%3A0x49ebd92554cdc11!2sQ%20GARDENS%20LOFTS!5e0!3m2!1sen!2sae!4v1690266098830!5m2!1sen!2sae",
    price: 2452934.81,
    size: [2458.07],
    area: "jumeirah village circle",
    type: "apartments/flats",
    bedrooms: 2,
    bathrooms: 0,

    status: "offplan ",
    img: "/assets/contents/Ays-developer/2bed/cardimage.webp",

    detail:
      "A residential tower offering spacious high quality apartments, and luxurious modern amenities, in a prime location in Dubai. The tower is currently under construction and will be ready by December 2024.",
    link: "q-gardens-loft-2-bed",

    images: [
      "/assets/contents/Ays-developer/2bed/cardimage.webp",
      "/assets/contents/Ays-developer/2bed/pic1.webp",
      "/assets/contents/Ays-developer/2bed/pic2.webp",
      "/assets/contents/Ays-developer/2bed/pic3.webp",
      "/assets/contents/Ays-developer/2bed/pic4.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "lobby",
      "play-area",
      "car-parking",
      "gymnasium",
      "reception",
    ],
    features: [
      "Kids Play Areas",
      "Indoors Cinema",
      "Rooftop Swimming Pool",
      "Residents Sitting Area",
      "Elegant Reception",
      "Podium Parking",
      "Modern GYM",
      "Management Office",
    ],
    developer: "ays-developers",
    description:
      "<p>Q Gardens Lofts is located in the heart of JVC, directly opposite Five Hotel – Jumeirah Village Circle. The location is just a few minutes drive to all important spots in Dubai:</p>",
  },
  {
    id: "ays2",
    title: "Q GARDENS LOFTS(1 Bed)",
    featured: true,
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.424371284567!2d55.2084207!3d25.0536018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6db03e0f0cc7%3A0x49ebd92554cdc11!2sQ%20GARDENS%20LOFTS!5e0!3m2!1sen!2sae!4v1690266098830!5m2!1sen!2sae",
    price: 938494.26,
    size: [881.68],
    area: "jumeirah village circle",
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 0,
    status: "offplan ",
    img: "/assets/contents/Ays-developer/2bed/cardimage.webp",
    detail:
      "A residential tower offering spacious high quality apartments, and luxurious modern amenities, in a prime location in Dubai. The tower is currently under construction and will be ready by December 2024.",
    link: "q-gardens-loft-1-bed",
    images: [
      "/assets/contents/Ays-developer/2bed/cardimage.webp",
      "/assets/contents/Ays-developer/2bed/pic1.webp",
      "/assets/contents/Ays-developer/2bed/pic2.webp",
      "/assets/contents/Ays-developer/2bed/pic3.webp",
      "/assets/contents/Ays-developer/2bed/pic4.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "lobby",
      "play-area",
      "car-parking",
      "gymnasium",
      "reception",
    ],
    features: [
      "Kids Play Areas",
      "Indoors Cinema",
      "Rooftop Swimming Pool",
      "Residents Sitting Area",
      "Elegant Reception",
      "Podium Parking",
      "Modern GYM",
      "Management Office",
    ],
    developer: "ays-developers",
    description:
      "<p>Q Gardens Lofts is located in the heart of JVC, directly opposite Five Hotel – Jumeirah Village Circle. The location is just a few minutes drive to all important spots in Dubai:</p>",
  },

  {
    id: "ays3",
    title: "Q GARDENS LOFTS(3 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.424371284567!2d55.2084207!3d25.0536018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6db03e0f0cc7%3A0x49ebd92554cdc11!2sQ%20GARDENS%20LOFTS!5e0!3m2!1sen!2sae!4v1690266098830!5m2!1sen!2sae",
    price: 2370630.86,
    size: [2323.95],
    area: "jumeirah village circle",
    type: "apartments/flats",
    bedrooms: 3,
    bathrooms: 0,

    status: "offplan ",
    img: "/assets/contents/Ays-developer/2bed/cardimage.webp",

    detail:
      "A residential tower offering spacious high quality apartments, and luxurious modern amenities, in a prime location in Dubai. The tower is currently under construction and will be ready by December 2024.",
    link: "q-gardens-loft-3-bed",

    images: [
      "/assets/contents/Ays-developer/2bed/cardimage.webp",
      "/assets/contents/Ays-developer/2bed/pic1.webp",
      "/assets/contents/Ays-developer/2bed/pic2.webp",
      "/assets/contents/Ays-developer/2bed/pic3.webp",
      "/assets/contents/Ays-developer/2bed/pic4.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "lobby",
      "play-area",
      "car-parking",
      "gymnasium",
      "reception",
    ],
    features: [
      "Kids Play Areas",
      "Indoors Cinema",
      "Rooftop Swimming Pool",
      "Residents Sitting Area",
      "Elegant Reception",
      "Podium Parking",
      "Modern GYM",
      "Management Office",
    ],
    developer: "ays-developers",
    description:
      "<p>Q Gardens Lofts is located in the heart of JVC, directly opposite Five Hotel – Jumeirah Village Circle. The location is just a few minutes drive to all important spots in Dubai:</p>",
  },

  {
    id: "ays4",
    title: "Q GARDENS LOFTS(Studio)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.424371284567!2d55.2084207!3d25.0536018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6db03e0f0cc7%3A0x49ebd92554cdc11!2sQ%20GARDENS%20LOFTS!5e0!3m2!1sen!2sae!4v1690266098830!5m2!1sen!2sae",
    price: 435999.45,
    size: [417.64],
    area: "jumeirah village circle",
    type: "apartments/flats",
    bedrooms: 0,
    bathrooms: 0,

    status: "offplan ",
    img: "/assets/contents/Ays-developer/2bed/cardimage.webp",

    detail:
      "A residential tower offering spacious high quality apartments, and luxurious modern amenities, in a prime location in Dubai. The tower is currently under construction and will be ready by December 2024.",
    link: "q-gardens-loft-studio",

    images: [
      "/assets/contents/Ays-developer/2bed/cardimage.webp",
      "/assets/contents/Ays-developer/2bed/pic1.webp",
      "/assets/contents/Ays-developer/2bed/pic2.webp",
      "/assets/contents/Ays-developer/2bed/pic3.webp",
      "/assets/contents/Ays-developer/2bed/pic4.webp",
    ],
    roomType: "studio",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "lobby",
      "play-area",
      "car-parking",
      "gymnasium",
      "reception",
    ],
    features: [
      "Kids Play Areas",
      "Indoors Cinema",
      "Rooftop Swimming Pool",
      "Residents Sitting Area",
      "Elegant Reception",
      "Podium Parking",
      "Modern GYM",
      "Management Office",
    ],
    developer: "ays-developers",
    description:
      "<p>Take a 3D tour: <a href='https://realeyez360.com/v/qgardens_loft/3dtour_studio_jvc/458/' target='_blank' >click here</a></p><p>Q Gardens Lofts is located in the heart of JVC, directly opposite Five Hotel – Jumeirah Village Circle. The location is just a few minutes drive to all important spots in Dubai:</p>",
  },
  //ellington-properties
  {
    id: "ellington1",
    title: "Arbor View(3 bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.424371284567!2d55.2084207!3d25.0536018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6db03e0f0cc7%3A0x49ebd92554cdc11!2sQ%20GARDENS%20LOFTS!5e0!3m2!1sen!2sae!4v1690266098830!5m2!1sen!2sae",
    price: 2566828.0,
    size: [1720.29],
    area: "arjan",
    type: "apartments/flats",
    bedrooms: 3,
    bathrooms: 0,
    possesion: "Q1 2026",

    status: "offplan ",
    img: "/assets/contents/Ellington/Arbor View/cardimage.webp",

    detail:
      "Arbor View at Arjan is a new development propelled by Ellington Properties offering luxury design studios, 1, 2 & 3 bedroom apartments, lined with exclusive amenities in Dubai. Discover the Nature's Silhouette, an oasis of tranquility amidst the vibrant cityscape, where luxury meets nature, where breathtaking landscapes intertwine seamlessly with architectural marvels, and where an exquisite lifestyle awaits you.",
    link: "arbor-view-3-bed",

    images: [
      "/assets/contents/Ellington/Arbor View/pic1.webp",
      "/assets/contents/Ellington/Arbor View/pic2.webp",
      "/assets/contents/Ellington/Arbor View/pic3.webp",
      "/assets/contents/Ellington/Arbor View/pic4.webp",
      "/assets/contents/Ellington/Arbor View/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "lobby",
      "play-area",
      "car-parking",
      "gymnasium",
      "reception",
    ],
    features: [
      "Dining Outlets",
      "Gymnasium",
      "Kids Play Area",
      "Parking Facility",
      "Parks and Leisure Areas",
      "Restaurants",
      "Swimming Pool",
    ],
    developer: "ellington-properties",
    description:
      "<h1>Locations</h1><p>Arjan is an emerging community on the outskirts of Dubai that offers its residents a serene and peaceful environment. Arjan provides an ideal setting for families and individuals looking for a harmonious blend of modern living and natural beauty with its attractive parks, proximity to essential services, educational institutions, healthcare facilities, and convenient access to major transportation routes. One of the main attractions of Arjan is the presence of two renowned parks: the Dubai Miracle Garden and the Dubai Butterfly Garden. These vibrant and enchanting green spaces draw visitors worldwide and provide a serene and picturesque setting for residents to relax and enjoy nature.</p>",
  },
  {
    id: "ellington2",
    title: "Arbor View(1 bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.424371284567!2d55.2084207!3d25.0536018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6db03e0f0cc7%3A0x49ebd92554cdc11!2sQ%20GARDENS%20LOFTS!5e0!3m2!1sen!2sae!4v1690266098830!5m2!1sen!2sae",
    price: 1503828.0,
    size: [944.64],
    area: "arjan",
    type: "apartments/flats",
    bedrooms: 3,
    bathrooms: 0,
    possesion: "Q1 2026",

    status: "offplan ",
    img: "/assets/contents/Ellington/Arbor View/cardimage.webp",

    detail:
      "Arbor View at Arjan is a new development propelled by Ellington Properties offering luxury design studios, 1, 2 & 3 bedroom apartments, lined with exclusive amenities in Dubai. Discover the Nature's Silhouette, an oasis of tranquility amidst the vibrant cityscape, where luxury meets nature, where breathtaking landscapes intertwine seamlessly with architectural marvels, and where an exquisite lifestyle awaits you.",
    link: "arbor-view-1-bed",

    images: [
      "/assets/contents/Ellington/Arbor View/pic1.webp",
      "/assets/contents/Ellington/Arbor View/pic2.webp",
      "/assets/contents/Ellington/Arbor View/pic3.webp",
      "/assets/contents/Ellington/Arbor View/pic4.webp",
      "/assets/contents/Ellington/Arbor View/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "lobby",
      "play-area",
      "car-parking",
      "gymnasium",
      "reception",
    ],
    features: [
      "Dining Outlets",
      "Gymnasium",
      "Kids Play Area",
      "Parking Facility",
      "Parks and Leisure Areas",
      "Restaurants",
      "Swimming Pool",
    ],
    developer: "ellington-properties",
    description:
      "<h1>Locations</h1><p>Arjan is an emerging community on the outskirts of Dubai that offers its residents a serene and peaceful environment. Arjan provides an ideal setting for families and individuals looking for a harmonious blend of modern living and natural beauty with its attractive parks, proximity to essential services, educational institutions, healthcare facilities, and convenient access to major transportation routes. One of the main attractions of Arjan is the presence of two renowned parks: the Dubai Miracle Garden and the Dubai Butterfly Garden. These vibrant and enchanting green spaces draw visitors worldwide and provide a serene and picturesque setting for residents to relax and enjoy nature.</p>",
  },
  {
    id: "ellington3",
    title: "Arbor View(2 bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.424371284567!2d55.2084207!3d25.0536018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6db03e0f0cc7%3A0x49ebd92554cdc11!2sQ%20GARDENS%20LOFTS!5e0!3m2!1sen!2sae!4v1690266098830!5m2!1sen!2sae",
    price: 1965828.0,
    size: [1363.36],
    area: "arjan",
    type: "apartments/flats",
    bedrooms: 2,
    bathrooms: 0,
    possesion: "Q1 2026",

    status: "offplan ",
    img: "/assets/contents/Ellington/Arbor View/cardimage.webp",

    detail:
      "Arbor View at Arjan is a new development propelled by Ellington Properties offering luxury design studios, 1, 2 & 3 bedroom apartments, lined with exclusive amenities in Dubai. Discover the Nature's Silhouette, an oasis of tranquility amidst the vibrant cityscape, where luxury meets nature, where breathtaking landscapes intertwine seamlessly with architectural marvels, and where an exquisite lifestyle awaits you.",
    link: "arbor-view-2-bed",

    images: [
      "/assets/contents/Ellington/Arbor View/pic1.webp",
      "/assets/contents/Ellington/Arbor View/pic2.webp",
      "/assets/contents/Ellington/Arbor View/pic3.webp",
      "/assets/contents/Ellington/Arbor View/pic4.webp",
      "/assets/contents/Ellington/Arbor View/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "lobby",
      "play-area",
      "car-parking",
      "gymnasium",
      "reception",
    ],
    features: [
      "Dining Outlets",
      "Gymnasium",
      "Kids Play Area",
      "Parking Facility",
      "Parks and Leisure Areas",
      "Restaurants",
      "Swimming Pool",
    ],
    developer: "ellington-properties",
    description:
      "<h1>Locations</h1><p>Arjan is an emerging community on the outskirts of Dubai that offers its residents a serene and peaceful environment. Arjan provides an ideal setting for families and individuals looking for a harmonious blend of modern living and natural beauty with its attractive parks, proximity to essential services, educational institutions, healthcare facilities, and convenient access to major transportation routes. One of the main attractions of Arjan is the presence of two renowned parks: the Dubai Miracle Garden and the Dubai Butterfly Garden. These vibrant and enchanting green spaces draw visitors worldwide and provide a serene and picturesque setting for residents to relax and enjoy nature.</p>",
  },
  //The Crestmark 1,2  Beds
  {
    id: "ellington4",
    title: "The Crestmark(1 bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4767983164616!2d55.2812328740602!3d25.187138377717442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69b68c36df77%3A0xffd99d8dee4542cb!2sThe%20Crestmark%20by%20Ellington!5e0!3m2!1sen!2sae!4v1690352176068!5m2!1sen!2sae",
    price: 2708828.0,
    size: [1026.66],
    area: "business bay",
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 0,
    possesion: "Q1 2026",

    status: "offplan",
    img: "/assets/contents/Ellington/Crestmark/cardimage.webp",

    detail:
      "The Crestmark is a luxury residential development by Ellington Properties, which will be nestled in the vibrant area of Business Bay and offer stunning views of Dubai Canal and surrounding areas. A range of residences in various layouts will be present in The Crestmark, including studios, 1–3 bedroom apartments. A limited collection of three spacious 3-4 bedroom premium penthouses will be provided with an infinity plunge pool or outdoor jet whirlpool, a maid’s room and outdoor lounges.",
    link: "the-crestmark-1-bed",

    images: [
      "/assets/contents/Ellington/Crestmark/pic1.webp",
      "/assets/contents/Ellington/Crestmark/pic2.webp",
      "/assets/contents/Ellington/Crestmark/pic3.webp",
      "/assets/contents/Ellington/Crestmark/pic4.webp",
      "/assets/contents/Ellington/Crestmark/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
    ],
    features: [
      "Supermarket",
      "Retail Outlets",
      "Dining Outlets",
      "Health Care Centre",
      "Green Surrounding",
      "Waterside restaurants, cafes",
      "Gymnasium",
      "Kids Play Area",
      "Parking Facility",
      "Parks and Leisure Areas",
      "Restaurants",
      "Swimming Pool",
    ],
    developer: "ellington-properties",
    description:
      "<p>The development is iconic with hundreds of amenities and exceptional services for the welfare and well-being of residents. Close to Dubai's most famous attractions and tourist spots, business centres and world-famous developments like Burj Khalifa, and Downtown Dubai. Experience the joy of living in the wonderful location of the city</p>",
  },

  {
    id: "ellington5",
    title: "The Crestmark(2 bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4767983164616!2d55.2812328740602!3d25.187138377717442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69b68c36df77%3A0xffd99d8dee4542cb!2sThe%20Crestmark%20by%20Ellington!5e0!3m2!1sen!2sae!4v1690352176068!5m2!1sen!2sae",
    price: 2708828.0,
    size: [1026.66],
    area: "business bay",
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 0,
    possesion: "Q1 2026",

    status: "offplan",
    img: "/assets/contents/Ellington/Crestmark/cardimage.webp",

    detail:
      "The Crestmark is a luxury residential development by Ellington Properties, which will be nestled in the vibrant area of Business Bay and offer stunning views of Dubai Canal and surrounding areas. A range of residences in various layouts will be present in The Crestmark, including studios, 1–3 bedroom apartments. A limited collection of three spacious 3-4 bedroom premium penthouses will be provided with an infinity plunge pool or outdoor jet whirlpool, a maid’s room and outdoor lounges.",
    link: "the-crestmark-2-bed",

    images: [
      "/assets/contents/Ellington/Crestmark/pic1.webp",
      "/assets/contents/Ellington/Crestmark/pic2.webp",
      "/assets/contents/Ellington/Crestmark/pic3.webp",
      "/assets/contents/Ellington/Crestmark/pic4.webp",
      "/assets/contents/Ellington/Crestmark/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
    ],
    features: [
      "Supermarket",
      "Retail Outlets",
      "Dining Outlets",
      "Health Care Centre",
      "Green Surrounding",
      "Waterside restaurants, cafes",
      "Gymnasium",
      "Kids Play Area",
      "Parking Facility",
      "Parks and Leisure Areas",
      "Restaurants",
      "Swimming Pool",
    ],
    developer: "ellington-properties",
    description:
      "<p>The development is iconic with hundreds of amenities and exceptional services for the welfare and well-being of residents. Close to Dubai's most famous attractions and tourist spots, business centres and world-famous developments like Burj Khalifa, and Downtown Dubai. Experience the joy of living in the wonderful location of the city</p>",
  },
  //The Santuary 4 & 5 Bed add possesition time and
  {
    id: "ellington6",
    title: "The Santuary(4 bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.0889667299275!2d55.34373367405787!3d25.132682877752497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6532722c3e45%3A0x1bb37bfddd9ff4a4!2sThe%20Sanctuary%20by%20Ellington!5e0!3m2!1sen!2sae!4v1690354350508!5m2!1sen!2sae",
    price: 16498828.0,
    size: [7951.99],
    area: "Mohammed Bin Rashid City",
    type: "villa/mansion",
    bedrooms: 4,
    bathrooms: 0,
    possesion: " Q4 2025",

    status: "offplan",
    img: "/assets/contents/Ellington/Santuary/cardimage.webp",

    detail:
      "The Sanctuary at District 11 is the newest development by Ellington Properties that features waterfront villas located at Mohammed Bin Rashid City, Dubai. This master community is a new addition to its boutique styled residential development making a more elegant lifestyle for users",
    link: "the-santuary-4-bed",

    images: [
      "/assets/contents/Ellington/Santuary/cardimage.webp",
      "/assets/contents/Ellington/Santuary/pic1.webp",
      "/assets/contents/Ellington/Santuary/pic2.webp",
      "/assets/contents/Ellington/Santuary/pic3.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
    ],
    features: [
      "Kids Play Area",
      "Spa AND Sauna",
      "Swimming Pool",
      "Covered Parking",
      "Fitness Center",
      "Landscaped Areas",
    ],
    developer: "ellington-properties",
    description:
      "<h1>Location</h1><ul><li>15 mins drive to the Academic City</li><li>20 mins drive to the Dubai Int.Airport</li><li>25 mins drive to the Downtown Dubai</li><li>28 mins drive to the Jumeirah Beach</li></ul>",
  },
  {
    id: "ellington7",
    title: "The Santuary(5 bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.0889667299275!2d55.34373367405787!3d25.132682877752497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6532722c3e45%3A0x1bb37bfddd9ff4a4!2sThe%20Sanctuary%20by%20Ellington!5e0!3m2!1sen!2sae!4v1690354350508!5m2!1sen!2sae",
    price: 20680828.0,
    size: [9984.97],
    area: "Mohammed Bin Rashid City",
    type: "villa/mansion",
    possesion: " Q4 2025",
    bedrooms: 5,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Santuary/cardimage.webp",

    detail:
      "The Sanctuary at District 11 is the newest development by Ellington Properties that features waterfront villas located at Mohammed Bin Rashid City, Dubai. This master community is a new addition to its boutique styled residential development making a more elegant lifestyle for users",
    link: "the-santuary-5-bed",

    images: [
      "/assets/contents/Ellington/Santuary/cardimage.webp",
      "/assets/contents/Ellington/Santuary/pic1.webp",
      "/assets/contents/Ellington/Santuary/pic2.webp",
      "/assets/contents/Ellington/Santuary/pic3.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
    ],
    features: [
      "Kids Play Area",
      "Spa AND Sauna",
      "Swimming Pool",
      "Covered Parking",
      "Fitness Center",
      "Landscaped Areas",
    ],
    developer: "ellington-properties",
    description:
      "<h1>Location</h1><ul><li>15 mins drive to the Academic City</li><li>20 mins drive to the Dubai Int.Airport</li><li>25 mins drive to the Downtown Dubai</li><li>28 mins drive to the Jumeirah Beach</li></ul>",
  },

  // Ellington House III
  {
    id: "ellington8",
    title: "Ellington House III(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57799.768257962096!2d55.18584502167969!3d25.1192714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69a87c512d7f%3A0xcb87a3c05f1112fe!2sEllington%20House!5e0!3m2!1sen!2sae!4v1690361628257!5m2!1sen!2sae",
    price: 3259828.0,
    size: [1383.7],
    area: "dubai hills estate",
    type: "apartments/flats",
    possesion: " Q4 2025",
    bedrooms: 2,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Ellington House III/cardimage.webp",

    detail:
      " Ellington House is a stylish 12-storey building featuring high-end 1-, 2-, and 3-bedroom apartments. With 69 1-bedroom, 64 2-bedroom, and 17 3-bedroom apartments, the development offers a total of 150 exquisite units, all among the finest apartments for sale in Dubai. Ellington House also features a ground floor with a lavish lobby and an alluring podium floor. Each unit also comes with a gorgeous balcony on which you can enjoy spectacular panoramic views of the surrounding natural scenery.",
    link: "ellington-house-iii-2-bed",

    images: [
      "/assets/contents/Ellington/Ellington House III/cardimage.webp",
      "/assets/contents/Ellington/Ellington House III/pic1.webp",
      "/assets/contents/Ellington/Ellington House III/pic2.webp",
      "/assets/contents/Ellington/Ellington House III/pic4.webp",
      "/assets/contents/Ellington/Ellington House III/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
    ],
    features: [
      "Leisure & lap pool",
      "Outdoor and indoor fitness centre",
      "Activity garden",
      "Kids’ clubhouse",
      "Mini-putt",
      "Barbeque area",
    ],
    developer: "ellington-properties",
    description:
      "<p>When it comes to the fitness of its residents, the development beats all with its fitness centre offering indoor and outdoor zones. The indoor fitness studio features high-performance eco-conscious equipment and zoned areas for full body workouts. Moreover, it comes with yoga rooms designed to achieve the perfect connection with mind, body and soul, where steam and sauna services are ready to freshen your mood every time.</p>",
  },
  {
    id: "ellington9",
    title: "Ellington House III(1 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57799.768257962096!2d55.18584502167969!3d25.1192714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69a87c512d7f%3A0xcb87a3c05f1112fe!2sEllington%20House!5e0!3m2!1sen!2sae!4v1690361628257!5m2!1sen!2sae",
    price: 2314828.0,
    size: [903.31],
    area: "dubai hills estate",
    type: "apartments/flats",
    possesion: " Q4 2025",
    bedrooms: 2,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Ellington House III/cardimage.webp",

    detail:
      " Ellington House is a stylish 12-storey building featuring high-end 1-, 2-, and 3-bedroom apartments. With 69 1-bedroom, 64 2-bedroom, and 17 3-bedroom apartments, the development offers a total of 150 exquisite units, all among the finest apartments for sale in Dubai. Ellington House also features a ground floor with a lavish lobby and an alluring podium floor. Each unit also comes with a gorgeous balcony on which you can enjoy spectacular panoramic views of the surrounding natural scenery.",
    link: "ellington-house-iii-1-bed",

    images: [
      "/assets/contents/Ellington/Ellington House III/cardimage.webp",
      "/assets/contents/Ellington/Ellington House III/pic1.webp",
      "/assets/contents/Ellington/Ellington House III/pic2.webp",
      "/assets/contents/Ellington/Ellington House III/pic4.webp",
      "/assets/contents/Ellington/Ellington House III/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
    ],
    features: [
      "Leisure & lap pool",
      "Outdoor and indoor fitness centre",
      "Activity garden",
      "Kids’ clubhouse",
      "Mini-putt",
      "Barbeque area",
    ],
    developer: "ellington-properties",
    description:
      "<p>When it comes to the fitness of its residents, the development beats all with its fitness centre offering indoor and outdoor zones. The indoor fitness studio features high-performance eco-conscious equipment and zoned areas for full body workouts. Moreover, it comes with yoga rooms designed to achieve the perfect connection with mind, body and soul, where steam and sauna services are ready to freshen your mood every time.</p>",
  },
  // Ellington House IV 1,2, 3bed
  {
    id: "ellington10",
    title: "Ellington House 4(1 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.5768417504933!2d55.25538567405711!3d25.116181777763252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f693045cda18f%3A0xcc67a6d764fbaba1!2sEllington%20House%204!5e0!3m2!1sen!2sae!4v1690366167127!5m2!1sen!2sae",
    price: 2206828.0,
    size: [913.64],
    area: "dubai hills estate",
    type: "apartments/flats",

    bedrooms: 1,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Ellington House IV/cardimage.webp",

    detail:
      " Ellington House is a stylish 12-storey building featuring high-end 1-, 2-, and 3-bedroom apartments. With 69 1-bedroom, 64 2-bedroom, and 17 3-bedroom apartments, the development offers a total of 150 exquisite units, all among the finest apartments for sale in Dubai. Ellington House also features a ground floor with a lavish lobby and an alluring podium floor. Each unit also comes with a gorgeous balcony on which you can enjoy spectacular panoramic views of the surrounding natural scenery.",
    link: "ellington-house-4-1-bed",

    images: [
      "/assets/contents/Ellington/Ellington House IV/cardimage.webp",
      "/assets/contents/Ellington/Ellington House IV/pic2.webp",
      "/assets/contents/Ellington/Ellington House IV/pic3.webp",
      "/assets/contents/Ellington/Ellington House IV/pic4.webp",
      "/assets/contents/Ellington/Ellington House IV/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Leisure & lap pool",
      "Outdoor and indoor fitness centre",
      "Activity garden",
      "Kids’ clubhouse",
      "Mini-putt",
      "Barbeque area",
      "Tennis Courts",
      "Infinity Pool",
      "Basketball Court",
      "Health Care Centre",
    ],
    developer: "ellington-properties",
    description:
      "<p>When it comes to the fitness of its residents, the development beats all with its fitness centre offering indoor and outdoor zones. The indoor fitness studio features high-performance eco-conscious equipment and zoned areas for full body workouts. Moreover, it comes with yoga rooms designed to achieve the perfect connection with mind, body and soul, where steam and sauna services are ready to freshen your mood every time.</p>",
  },
  {
    id: "ellington11",
    title: "Ellington House 4(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.5768417504933!2d55.25538567405711!3d25.116181777763252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f693045cda18f%3A0xcc67a6d764fbaba1!2sEllington%20House%204!5e0!3m2!1sen!2sae!4v1690366167127!5m2!1sen!2sae",
    price: 3171828.0,
    size: [1370.14],
    area: "dubai hills estate",
    type: "apartments/flats",

    bedrooms: 2,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Ellington House IV/pic2.webp",

    detail:
      "Ellington House is a stylish 12-storey building featuring high-end 1-, 2-, and 3-bedroom apartments. With 69 1-bedroom, 64 2-bedroom, and 17 3-bedroom apartments, the development offers a total of 150 exquisite units, all among the finest apartments for sale in Dubai. Ellington House also features a ground floor with a lavish lobby and an alluring podium floor. Each unit also comes with a gorgeous balcony on which you can enjoy spectacular panoramic views of the surrounding natural scenery.",
    link: "ellington-house-4-2-bed",

    images: [
      "/assets/contents/Ellington/Ellington House IV/cardimage.webp",
      "/assets/contents/Ellington/Ellington House IV/pic2.webp",
      "/assets/contents/Ellington/Ellington House IV/pic3.webp",
      "/assets/contents/Ellington/Ellington House IV/pic4.webp",
      "/assets/contents/Ellington/Ellington House IV/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Leisure & lap pool",
      "Outdoor and indoor fitness centre",
      "Activity garden",
      "Kids’ clubhouse",
      "Mini-putt",
      "Barbeque area",
      "Tennis Courts",
      "Infinity Pool",
      "Basketball Court",
      "Health Care Centre",
    ],
    developer: "ellington-properties",
    description:
      "<p>When it comes to the fitness of its residents, the development beats all with its fitness centre offering indoor and outdoor zones. The indoor fitness studio features high-performance eco-conscious equipment and zoned areas for full body workouts. Moreover, it comes with yoga rooms designed to achieve the perfect connection with mind, body and soul, where steam and sauna services are ready to freshen your mood every time.</p>",
  },
  {
    id: "ellington12",
    title: "Ellington House 4(3 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.5768417504933!2d55.25538567405711!3d25.116181777763252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f693045cda18f%3A0xcc67a6d764fbaba1!2sEllington%20House%204!5e0!3m2!1sen!2sae!4v1690366167127!5m2!1sen!2sae",
    price: 4677828.0,
    size: [1867.43],
    area: "dubai hills estate",
    type: "apartments/flats",

    bedrooms: 2,
    bathrooms: 0,
    status: "offplan",
    img: "/assets/contents/Ellington/Ellington House IV/cardimage.webp",

    detail:
      "Ellington House is a stylish 12-storey building featuring high-end 1-, 2-, and 3-bedroom apartments. With 69 1-bedroom, 64 2-bedroom, and 17 3-bedroom apartments, the development offers a total of 150 exquisite units, all among the finest apartments for sale in Dubai. Ellington House also features a ground floor with a lavish lobby and an alluring podium floor. Each unit also comes with a gorgeous balcony on which you can enjoy spectacular panoramic views of the surrounding natural scenery.",
    link: "ellington-house-4-3-bed",

    images: [
      "/assets/contents/Ellington/Ellington House IV/cardimage.webp",
      "/assets/contents/Ellington/Ellington House IV/pic2.webp",
      "/assets/contents/Ellington/Ellington House IV/pic3.webp",
      "/assets/contents/Ellington/Ellington House IV/pic4.webp",
      "/assets/contents/Ellington/Ellington House IV/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Leisure & lap pool",
      "Outdoor and indoor fitness centre",
      "Activity garden",
      "Kids’ clubhouse",
      "Mini-putt",
      "Barbeque area",
      "Tennis Courts",
      "Infinity Pool",
      "Basketball Court",
      "Health Care Centre",
    ],
    developer: "ellington-properties",
    description:
      "<p>When it comes to the fitness of its residents, the development beats all with its fitness centre offering indoor and outdoor zones. The indoor fitness studio features high-performance eco-conscious equipment and zoned areas for full body workouts. Moreover, it comes with yoga rooms designed to achieve the perfect connection with mind, body and soul, where steam and sauna services are ready to freshen your mood every time.</p>",
  },
  // Upper House East Tower bed 1 , 2, 3
  {
    id: "ellington13",
    title: "Upper House East Tower(1 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.5768417504933!2d55.25538567405711!3d25.116181777763252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f693045cda18f%3A0xcc67a6d764fbaba1!2sEllington%20House%204!5e0!3m2!1sen!2sae!4v1690366167127!5m2!1sen!2sae",
    price: 2003828.0,
    size: [1089.63],
    possession: "Q1 2026",
    area: "Jumeirah Lake Towers",
    type: "apartments/flats",
    bedrooms: 1,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Upper House East Tower/cardimage.webp",

    detail:
      "The latest launch by Ellington Properties, Upper House at Jumeirah Lake Towers (JLT), Dubai offering luxury design studios, 1, 2 & 3 bedroom apartments. Dive into a most coveted lifestyle in the perfect position of the development that brings ease in connectivity throughout the city parts and some major landmarks nearby.",
    link: "upper-house-east-tower-1-bed",

    images: [
      "/assets/contents/Ellington/Upper House East Tower/cardimage.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic1.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic2.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic3.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic4.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Jogging Trails",
      "Outdoor Sitting Area",
      "Running Track",
      "Shopping Mall",
      "Water Activity",
      "Yoga & Meditation",
      "Outdoor Gymnasium",
      "Fitness Centre",
    ],
    developer: "ellington-properties",
    description:
      "<p>Ellington House IV is a modern residential building designed with a minimalist approach, drawing inspiration from classic design principles. The design of Ellington House IV is marked by a cohesive quality reflected in its distinct character. The building’s bright color palette further accentuates this feature, giving it a unique and unmistakable identity. The use of robust materials adds to the building’s solidity, reinforcing its structural integrity and ensuring its longevity</p>",
  },

  {
    id: "ellington14",
    title: "Upper House East Tower(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.5768417504933!2d55.25538567405711!3d25.116181777763252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f693045cda18f%3A0xcc67a6d764fbaba1!2sEllington%20House%204!5e0!3m2!1sen!2sae!4v1690366167127!5m2!1sen!2sae",
    price: 2749828.0,
    size: [1224.72],
    area: "Jumeirah Lake Towers",
    type: "apartments/flats",
    possession: "Q1 2026",
    bedrooms: 2,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Upper House East Tower/pic1.webp",

    detail:
      "The latest launch by Ellington Properties, Upper House at Jumeirah Lake Towers (JLT), Dubai offering luxury design studios, 1, 2 & 3 bedroom apartments. Dive into a most coveted lifestyle in the perfect position of the development that brings ease in connectivity throughout the city parts and some major landmarks nearby.",
    link: "upper-house-east-tower-2-bed",

    images: [
      "/assets/contents/Ellington/Upper House East Tower/cardimage.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic1.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic2.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic3.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic4.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Jogging Trails",
      "Outdoor Sitting Area",
      "Running Track",
      "Shopping Mall",
      "Water Activity",
      "Yoga & Meditation",
      "Outdoor Gymnasium",
      "Fitness Centre",
    ],
    developer: "ellington-properties",
    description:
      "<p>Ellington House IV is a modern residential building designed with a minimalist approach, drawing inspiration from classic design principles. The design of Ellington House IV is marked by a cohesive quality reflected in its distinct character. The building’s bright color palette further accentuates this feature, giving it a unique and unmistakable identity. The use of robust materials adds to the building’s solidity, reinforcing its structural integrity and ensuring its longevity</p>",
  },

  {
    id: "ellington15",
    title: "Upper House East Tower(3 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.5768417504933!2d55.25538567405711!3d25.116181777763252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f693045cda18f%3A0xcc67a6d764fbaba1!2sEllington%20House%204!5e0!3m2!1sen!2sae!4v1690366167127!5m2!1sen!2sae",
    price: 4245828.0,
    size: [2194.33],
    area: "Jumeirah Lake Towers",
    type: "apartments/flats",
    possession: "Q1 2026",
    bedrooms: 3,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Upper House East Tower/cardimage.webp",

    detail:
      "The latest launch by Ellington Properties, Upper House at Jumeirah Lake Towers (JLT), Dubai offering luxury design studios, 1, 2 & 3 bedroom apartments. Dive into a most coveted lifestyle in the perfect position of the development that brings ease in connectivity throughout the city parts and some major landmarks nearby.",
    link: "upper-house-east-tower-3-bed",

    images: [
      "/assets/contents/Ellington/Upper House East Tower/cardimage.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic1.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic2.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic3.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic4.webp",
      "/assets/contents/Ellington/Upper House East Tower/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Jogging Trails",
      "Outdoor Sitting Area",
      "Running Track",
      "Shopping Mall",
      "Water Activity",
      "Yoga & Meditation",
      "Outdoor Gymnasium",
      "Fitness Centre",
    ],
    developer: "ellington-properties",
    description:
      "<p>Ellington House IV is a modern residential building designed with a minimalist approach, drawing inspiration from classic design principles. The design of Ellington House IV is marked by a cohesive quality reflected in its distinct character. The building’s bright color palette further accentuates this feature, giving it a unique and unmistakable identity. The use of robust materials adds to the building’s solidity, reinforcing its structural integrity and ensuring its longevity</p>",
  },
  //Belgravia1 3 bed room only
  {
    id: "ellington16",
    title: "Belgravia 1(3 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.5768417504933!2d55.25538567405711!3d25.116181777763252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f693045cda18f%3A0xcc67a6d764fbaba1!2sEllington%20House%204!5e0!3m2!1sen!2sae!4v1690366167127!5m2!1sen!2sae",
    price: 2938828.0,
    size: [3194.08],
    area: "Jumeirah Village Circle",
    type: "apartments/flats",
    bedrooms: 3,
    bathrooms: 0,

    status: "ready to move",
    img: "/assets/contents/Ellington/Belgravia1/cardimage.webp",

    detail:
      "Ellington Belgravia Heights at Jumeirah Village Circle (JVC) presenting residents spaces to connect with family, society to grow. Homes are the thermally padding exterior facade and are a mix of luxury Studio, 1, 2 bedroom apartments units in G+4P+15 residential building and layouts exist with various facilities",
    link: "belgravia-1-3-Bed",

    images: [
      "/assets/contents/Ellington/Belgravia1/cardimage.webp",
      "/assets/contents/Ellington/Belgravia1/pic1.webp",
      "/assets/contents/Ellington/Belgravia1/pic2.webp",
      "/assets/contents/Ellington/Belgravia1/pic3.webp",
      "/assets/contents/Ellington/Belgravia1/pic4.webp",
      "/assets/contents/Ellington/Belgravia1/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Jogging Trails",
      "Outdoor Sitting Area",
      "Running Track",
      "Shopping Mall",
      "Water Activity",
      "Yoga & Meditation",
      "Outdoor Gymnasium",
      "Fitness Centre",
    ],
    developer: "ellington-properties",
    description:
      "<p>The Fourth in a series of projects under the Belgravia Heights trademark in the Dubai's Jumeirah is expected to be a great success, just like the first three Mega demanded Belgravia projects. The Belgravia project will offer 225 off-plan units for sale, comprising studios and 1 to 2 Bedroom apartments. The Belgravia Square is a resort like landscape Platform with a Swimming pool available for the Residents which adds a great pleasure. A truly customer-focused Real Estate Developer in Dubai, Ellington build stronger, more complete classically beautiful houses, meeting the incredible art with a perfect design with the ongoing modern trends.</p>",
  },
  //The Highbury bed 1, 2 and 3 bed

  {
    id: "ellington17",
    title: "The Highbury 1(1 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.6960966514434!2d55.3037749744569!3d25.17973743240943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6981ab9f200b%3A0xa3491e7d3fa57332!2sThe%20Highbury!5e0!3m2!1sen!2sae!4v1690436261564!5m2!1sen!2sae",
    price: 1563828.0,
    size: [844.97],
    area: "Meydan",
    type: "apartments/flats",
    possession: "Q1 2026",
    bedrooms: 1,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Highbury1/cardimage.jpg",

    detail:
      "The Highbury is a premium 29-storey complex consisting of 2 buildings by Ellington Properties in the sought-after area of Mohammed Bin Rashid City. In terms of properties, the development offers a collection of exquisitely designed studios and 1-3 bedroom apartments, in addition to luxury 3-4 bedroom duplexes and 4-bedroom penthouses, with living areas ranging from 425 sq. ft to 5,254 sq. ft. The upper floor apartments, which house the premium duplex units, offer a more extravagant and lavish design",
    link: " the-highbury-1-1-bed",

    images: [
      "/assets/contents/Ellington/Highbury1/cardimage.jpg",
      "/assets/contents/Ellington/Highbury1/pic1.webp",
      "/assets/contents/Ellington/Highbury1/pic2.webp",
      "/assets/contents/Ellington/Highbury1/pic3.webp",
      "/assets/contents/Ellington/Highbury1/pic4.webp",
      "/assets/contents/Ellington/Highbury1/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Basketball Court",
      "Dining Outlets",
      "Gymnasium",
      "Health Care Centre",
      "Infinity Pool",
      "Kids Play Area",
      "Parks and Leisure Areas",
      "Restaurants",
      "Tennis Courts",
      "Supermarket",
    ],
    developer: "ellington-properties",
    description:
      "<p>These homes are perfect and picturesque from every angle offering iconic and mesmerising views of water features, skylines, beaches and golf courses. Tailored fit living gives you comfort and convenience which is exceptional in many ways. Round the clock, you will have access to a variety of activities and services to make the most of them</p>",
  },
  {
    id: "ellington18",
    title: "The Highbury 1(2 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.6960966514434!2d55.3037749744569!3d25.17973743240943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6981ab9f200b%3A0xa3491e7d3fa57332!2sThe%20Highbury!5e0!3m2!1sen!2sae!4v1690436261564!5m2!1sen!2sae",
    price: 2965828.0,
    size: [1216.75],
    area: "Meydan",
    type: "apartments/flats",
    possession: "Q1 2026",
    bedrooms: 2,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Highbury1/cardimage.jpg",

    detail:
      "The Highbury is a premium 29-storey complex consisting of 2 buildings by Ellington Properties in the sought-after area of Mohammed Bin Rashid City. In terms of properties, the development offers a collection of exquisitely designed studios and 1-3 bedroom apartments, in addition to luxury 3-4 bedroom duplexes and 4-bedroom penthouses, with living areas ranging from 425 sq. ft to 5,254 sq. ft. The upper floor apartments, which house the premium duplex units, offer a more extravagant and lavish design",
    link: " the-highbury-1-2-bed",

    images: [
      "/assets/contents/Ellington/Highbury1/cardimage.jpg",
      "/assets/contents/Ellington/Highbury1/pic1.webp",
      "/assets/contents/Ellington/Highbury1/pic2.webp",
      "/assets/contents/Ellington/Highbury1/pic3.webp",
      "/assets/contents/Ellington/Highbury1/pic4.webp",
      "/assets/contents/Ellington/Highbury1/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Basketball Court",
      "Dining Outlets",
      "Gymnasium",
      "Health Care Centre",
      "Infinity Pool",
      "Kids Play Area",
      "Parks and Leisure Areas",
      "Restaurants",
      "Tennis Courts",
      "Supermarket",
    ],
    developer: "ellington-properties",
    description:
      "<p>These homes are perfect and picturesque from every angle offering iconic and mesmerising views of water features, skylines, beaches and golf courses. Tailored fit living gives you comfort and convenience which is exceptional in many ways. Round the clock, you will have access to a variety of activities and services to make the most of them</p>",
  },
  {
    id: "ellington19",
    title: "The Highbury 1(3 Bed)",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.6960966514434!2d55.3037749744569!3d25.17973743240943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6981ab9f200b%3A0xa3491e7d3fa57332!2sThe%20Highbury!5e0!3m2!1sen!2sae!4v1690436261564!5m2!1sen!2sae",
    price: 6850828.0,
    size: [2810.03],
    area: "Meydan",
    type: "apartments/flats",
    possession: "Q1 2026",
    bedrooms: 2,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Highbury1/cardimage.jpg",

    detail:
      "The Highbury is a premium 29-storey complex consisting of 2 buildings by Ellington Properties in the sought-after area of Mohammed Bin Rashid City. In terms of properties, the development offers a collection of exquisitely designed studios and 1-3 bedroom apartments, in addition to luxury 3-4 bedroom duplexes and 4-bedroom penthouses, with living areas ranging from 425 sq. ft to 5,254 sq. ft. The upper floor apartments, which house the premium duplex units, offer a more extravagant and lavish design",
    link: " the-highbury-1-3-bed",

    images: [
      "/assets/contents/Ellington/Highbury1/cardimage.jpg",
      "/assets/contents/Ellington/Highbury1/pic1.webp",
      "/assets/contents/Ellington/Highbury1/pic2.webp",
      "/assets/contents/Ellington/Highbury1/pic3.webp",
      "/assets/contents/Ellington/Highbury1/pic4.webp",
      "/assets/contents/Ellington/Highbury1/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Fitness Centre",
      "Outdoor Gymnasium",
      "Shopping Mall",
      "Sports Court",
      "Running Track",
      "Restaurant and Cafe",
    ],
    developer: "ellington-properties",
    description:
      "<p>These homes are perfect and picturesque from every angle offering iconic and mesmerising views of water features, skylines, beaches and golf courses. Tailored fit living gives you comfort and convenience which is exceptional in many ways. Round the clock, you will have access to a variety of activities and services to make the most of them</p>",
  },
  //wilton park residence 0  bedroom

  {
    id: "ellington20",
    title: "Wilton Park Residences",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.734185904452!2d55.308993774059765!3d25.178451777723016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f691d4a8f0ae7%3A0x3d4734fca396a414!2sWilton%20Park%20Residences%20%E2%80%93%20Ellington%20Properties!5e0!3m2!1sen!2sae!4v1690440266938!5m2!1sen!2sae",
    price: 1160828.0,
    size: [519.36],
    area: "Meydan",
    type: "apartments/flats",
    possession: "Q3 2023",
    bedrooms: 0,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/wilton park Residences/cardimage.jpg",

    detail:
      "Wilton Park Residences is an icon of understated and restrained elegance comprising of perfectly crafted studios, 1 & 2 bedrooms. The perfect investment for home buyers who desire a harmonious relationship between aesthetic design, privacy and community living.",
    link: "wilton-park-residences",

    images: [
      "/assets/contents/Ellington/wilton park Residences/cardimage.jpg",
      "/assets/contents/Ellington/wilton park Residences/pic1.webp",
      "/assets/contents/Ellington/wilton park Residences/pic2.webp",
      "/assets/contents/Ellington/wilton park Residences/pic3.webp",
    ],
    roomType: "studio",

    amenties: [
      "gym",
      "basketball",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
    ],
    features: [
      "Basketball Court",
      "Dining Outlets",
      "Gymnasium",
      "Health Care Centre",
      "Infinity Pool",
      "Kids Play Area",
      "Parks and Leisure Areas",
      "Restaurants",
      "Tennis Courts",
      "Supermarket",
    ],
    developer: "ellington-properties",
    description:
      "<p><h1>Location</h1><ul><li>06 minutes DUBAI INTERNATIONALFINANCE CENTRE</li><li>12 minutes DUBAI INTERNATIONAL AIRPORT</li><li>15 minutes MALL OF THE EMIRATES </li><ul></p>",
  },
  //Ellington Beach House 1 ,2
  {
    id: "ellington21",
    title: "Ellington Beach House(1 bed) ",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.734185904452!2d55.308993774059765!3d25.178451777723016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f691d4a8f0ae7%3A0x3d4734fca396a414!2sWilton%20Park%20Residences%20%E2%80%93%20Ellington%20Properties!5e0!3m2!1sen!2sae!4v1690440482814!5m2!1sen!2sae",
    price: 6958828.0,
    size: [1256.36],
    area: "palm Jumeirah",
    type: "apartments/flats",
    possession: "Q3 2024",
    bedrooms: 1,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/ellington Beach House/cardimage.webp",

    detail:
      "Ellington Beach House is another standard advancement by Ellington Properties that features stylish design residences at Palm Jumeirah, Dubai. The development is designed for those with a passion for living remarkably well, where they can be surrounded by the attractive spot for shopping, dining and entertainment.",
    link: "ellington-beach-house-1-bed",

    images: [
      "/assets/contents/Ellington/ellington Beach House/cardimage.webp",
      "/assets/contents/Ellington/ellington Beach House/pic1.webp",
      "/assets/contents/Ellington/ellington Beach House/pic2.webp",
      "/assets/contents/Ellington/ellington Beach House/pic3.webp",
      "/assets/contents/Ellington/ellington Beach House/pic4.webp",
      "/assets/contents/Ellington/ellington Beach House/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "bbq",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
      "play-area",
      "jog",
    ],
    features: [
      "Basketball Court",
      "Beach Access",
      "Gymnasium",
      "Schools and Institutes",
      "BBQ Areas",
      "Kids Play Area",
      "Parks and Leisure Areas",
      "Restaurants",
      "Tennis Courts",
      "Supermarket",
    ],
    developer: "ellington-properties",
    description:
      "Residents additionally benefit from the pristine beachfront neighbourhood and the lifestyle thanks to the building's exceptional location at the entrance to the Palm Jumeirah in Dubai. You can easily and without fuss travel across the rest of Dubai thanks to the development's additional connections to the mainland, where you can find world-class accommodations, restaurants, and retail centres",
  },
  {
    id: "ellington22",
    title: "Ellington Beach House(2 bed) ",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.734185904452!2d55.308993774059765!3d25.178451777723016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f691d4a8f0ae7%3A0x3d4734fca396a414!2sWilton%20Park%20Residences%20%E2%80%93%20Ellington%20Properties!5e0!3m2!1sen!2sae!4v1690440482814!5m2!1sen!2sae",
    price: 7349828.0,
    size: [1417.71],
    area: "palm Jumeirah",
    type: "apartments/flats",
    possession: "Q3 2024",
    bedrooms: 1,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/ellington Beach House/cardimage.webp",

    detail:
      "Ellington Beach House is another standard advancement by Ellington Properties that features stylish design residences at Palm Jumeirah, Dubai. The development is designed for those with a passion for living remarkably well, where they can be surrounded by the attractive spot for shopping, dining and entertainment.",
    link: "ellington-beach-house-2-bed",

    images: [
      "/assets/contents/Ellington/ellington Beach House/cardimage.jpg",
      "/assets/contents/Ellington/ellington Beach House/pic1.webp",
      "/assets/contents/Ellington/ellington Beach House/pic2.webp",
      "/assets/contents/Ellington/ellington Beach House/pic3.webp",
      "/assets/contents/Ellington/ellington Beach House/pic4.webp",
      "/assets/contents/Ellington/ellington Beach House/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "bbq",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
      "play-area",
      "jog",
    ],
    features: [
      "Basketball Court",
      "Beach Access",
      "Gymnasium",
      "Schools and Institutes",
      "BBQ Areas",
      "Kids Play Area",
      "Parks and Leisure Areas",
      "Restaurants",
      "Tennis Courts",
      "Supermarket",
    ],
    developer: "ellington-properties",
    description:
      "Residents additionally benefit from the pristine beachfront neighbourhood and the lifestyle thanks to the building's exceptional location at the entrance to the Palm Jumeirah in Dubai. You can easily and without fuss travel across the rest of Dubai thanks to the development's additional connections to the mainland, where you can find world-class accommodations, restaurants, and retail centres",
  },

  //Ocean house3,2 bed
  {
    id: "ellington23",
    title: "Ocean House(2 bed) ",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.073016615735!2d55.14931597405784!3d25.13322217775215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ba4a1c40b31%3A0x165e4d286673ae93!2sOcean%20House%20By%20Ellington!5e0!3m2!1sen!2sae!4v1690447909605!5m2!1sen!2sae",
    price: 9549828.0,
    size: [1872.49],
    area: "palm Jumeirah",
    type: "apartments/flats",
    possession: " Q4 2025",
    bedrooms: 2,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Ocean house/cardimage.webp",

    detail:
      "Ellington Ocean House at Palm Jumeirah is the newest residential development by Ellington Properties that presents 2 to 6 bedroom apartments and penthouses in Dubai. In the incredible world of Palm Jumeirah, the most beautiful and amazing examples of luxury living have been commended to the design and creation of the world's most renowned architects.",
    link: "ocean-house-2-bed",

    images: [
      "/assets/contents/Ellington/Ocean house/cardimage.webp",
      "/assets/contents/Ellington/Ocean house/pic1.webp",
      "/assets/contents/Ellington/Ocean house/pic2.webp",
      "/assets/contents/Ellington/Ocean house/pic3.webp",
      "/assets/contents/Ellington/Ocean house/pic4.webp",
      "/assets/contents/Ellington/Ocean house/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "bbq",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
      "play-area",
      "jog",
    ],
    features: [
      "Basketball Court",
      "Beach Access",
      "Gymnasium",
      "Schools and Institutes",
      "BBQ Areas",
      "Kids Play Area",
      "Parks and Leisure Areas",
      "Restaurants",
      "Tennis Courts",
      "Supermarket",
    ],
    developer: "ellington-properties",
    description:
      "Plunge into the unrivaled luxury, which extends from the facilities and amenities to the elegant designs, the forward-thinking ideas for a lifestyle, the luxurious facilities, the security and safety, etc. This is a slice of heaven for those who prefer to be a part of the finest that the development has to offer. Get your units booked at this development by availing the attractive payment plan options.",
  },
  {
    id: "ellington24",
    title: "Ocean House(3 bed) ",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.073016615735!2d55.14931597405784!3d25.13322217775215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ba4a1c40b31%3A0x165e4d286673ae93!2sOcean%20House%20By%20Ellington!5e0!3m2!1sen!2sae!4v1690447909605!5m2!1sen!2sae",
    price: 19072828.0,
    size: [3531.96],
    area: "palm Jumeirah",
    type: "apartments/flats",
    possession: " Q4 2025",
    bedrooms: 2,
    bathrooms: 0,

    status: "offplan",
    img: "/assets/contents/Ellington/Ocean house/cardimage.webp",

    detail:
      "Ellington Ocean House at Palm Jumeirah is the newest residential development by Ellington Properties that presents 2 to 6 bedroom apartments and penthouses in Dubai. In the incredible world of Palm Jumeirah, the most beautiful and amazing examples of luxury living have been commended to the design and creation of the world's most renowned architects.",
    link: "ocean-house-3-bed",

    images: [
      "/assets/contents/Ellington/Ocean house/cardimage.webp",
      "/assets/contents/Ellington/Ocean house/pic1.webp",
      "/assets/contents/Ellington/Ocean house/pic2.webp",
      "/assets/contents/Ellington/Ocean house/pic3.webp",
      "/assets/contents/Ellington/Ocean house/pic4.webp",
      "/assets/contents/Ellington/Ocean house/pic5.webp",
    ],
    roomtype: "bedrooms",

    amenties: [
      "gym",
      "bbq",
      "pool",
      "play",
      "shops",
      "car-parking",
      "gymnasium",
      "reception",
      "hotel",
      "garden",
      "play-area",
      "jog",
    ],
    features: [
      "Basketball Court",
      "Beach Access",
      "Gymnasium",
      "Schools and Institutes",
      "BBQ Areas",
      "Kids Play Area",
      "Parks and Leisure Areas",
      "Restaurants",
      "Tennis Courts",
      "Supermarket",
    ],
    developer: "ellington-properties",
    description:
      "Plunge into the unrivaled luxury, which extends from the facilities and amenities to the elegant designs, the forward-thinking ideas for a lifestyle, the luxurious facilities, the security and safety, etc. This is a slice of heaven for those who prefer to be a part of the finest that the development has to offer. Get your units booked at this development by availing the attractive payment plan options.",
  },
];

export const areas = [
  {
    id: "a1",
    name: "dubai hills estate",
    img: "/assets/areas/dubaihillsestate.jpg",
    guide:
      "Dubai Hills Estate – the green heart of Dubai – is a first-of-its-kind destination. This masterfully planned, multi-purpose development forms an integral part of Mohammed Bin Rashid City. Strategically situated between Downtown Dubai and Dubai Marina, and flanked by Al Khail Road, the community offers easy access to the city’s most popular districts and attractions.",
    link: "dubai-hills-estate",
  },
  {
    id: "a2",
    name: "dubai marina",
    img: "/assets/areas/dubai-marina.jpg",
    guide:
      "Dubai Marina is an affluent residential neighborhood known for The Beach at JBR, a leisure complex with al fresco dining and sandy stretches to relax on. Smart cafes and pop-up craft markets line waterside promenade Dubai Marina Walk, while Dubai Marina Mall is packed with chain and luxury fashion brands. Upscale yachts cruise through the large man-made marina, where activities range from jet-skiing to skydiving.",
    link: "dubai-marina",
  },
  {
    id: "a3",
    name: "downtown dubai",
    img: "/assets/areas/downtown.jpeg",
    guide:
      "Downtown Dubai is the city’s busy tourism hub, home to the towering Burj Khalifa skyscraper, with its observation deck, and the dancing Dubai Fountain, where crowds gather to watch music and light shows. ",
    link: "downtown-dubai",
  },
  {
    id: "a4",
    name: "dubai creek harbour",
    img: "/assets/areas/dubai-creek-harbour.jpg",
    guide:
      "Dubai Creek Harbour offers a stunning view of the Dubai skyline over the water of Dubai Creek. It's a particular spectacular place from which to de a sunset over the Burj Khalifa. There are a few cafes and shops open at the Marina and some activities for kids.",
    link: "dubai-creek-harbour",
  },
  {
    id: "a5",
    name: "Rashid yachts and marina",
    img: "/assets/areas/rashid-yachts-and-marina.jpg",
    guide:
      "Dubai’s latest and unprecedented residential marina masterpiece on the Arabian Gulf coast is driven by a desire to change the meaning of sophistication. An architectural endeavor that offers a new interpretation of luxurious seafront living.",
    link: "rashid-yachts-and-marina",
  },
  {
    id: "a6",
    name: "arabian ranches",
    img: "/assets/areas/arabian-ranches.jpg",
    guide:
      "Arabian Ranches is an upscale gated villa community in Dubai, United Arab Emirates launched in 2004. Located in Wadi Al Safa 6, along Sheikh Mohammad Bin Zayed Road and in proximity to Dubai's Global Village. It includes the Arabian Ranches Golf Club, Dubai Equestrian & Polo Club.",
    link: "arabian-ranches",
  },
  {
    id: "a7",
    name: "emaar beachfront",
    img: "/assets/areas/emaar-beachfront.jpg",
    guide:
      "EMAAR Beachfront is one of the most spectacular places to reside in Dubai Harbour.It is a private, gated island community on Dubai Harbour. The development is located just off Dubai Marina, between JBR and Palm Jumeirah.",
    link: "emaar-beachfront",
  },
  {
    id: "a8",
    name: "mina rashid",
    img: "/assets/areas/minarashid.jpg",
    guide:
      "Mina Rashid, also referred to as Port Rashid, is a man-made cruise terminal in Dubai, United Arab Emirates. It was Dubai's first commercial port; in 2018 cargo operations moved to Jebel Ali Port. It currently serves as a seafront coastal destination, tourist cruise destination, and residential area",
    link: "mina-rashid",
  },
  {
    id: "a9",
    name: "emaar south",
    img: "/assets/areas/emaarsouth.jpg",
    guide:
      "Emaar South is a mixed-use development offering a new concept of tiered townhomes and standard townhouses. Standalone villas and apartments will have views of the stunning 18-hole championship golf course within the community completing the offer of a genuinely fulfilling lifestyle.",
    link: "emaar-south",
  },
  {
    id: "a10",
    name: "Business Bay",
    img: "/assets/areas/business-bay.jpg",
    guide:
      "Business Bay is a contemporary financial district packed with corporate high-rises, stylish apartments buildings, and swanky hotels. Dubai Water Canal has jogging and cycling tracks plus a colorful mechanical waterfall",
    link: "business-bay",
  },
  {
    id: "a11",
    name: "Damac-hills ",
    img: "/assets/areas/damac-hill.jpg",
    guide:
      "DAMAC Hills, the epitome of luxurious living in Dubai. Spread over 42 million square feet of pristine land, this premier community boasts a wide range of modern apartments, villas, townhouses, and luxury residences in association with world-class brands. The community is renowned for offering residents everything they need for a lifetime of discovery. From spectacular outdoor living to world-class retail and leisure spaces, DAMAC Hills has it all.",
    link: "damac-hills",
  },

  {
    id: "a12",
    name: "Dubai South",
    img: "/assets/areas/dubai-south.jpg",
    guide:
      "Dubai South is home to Dubai World Central (or Al Maktoum International) airport and the site of World Expo 2020. Comprising residential, commercial and logistics elements, Dubai South is the central hub of facilities, amenities and lifestyle offerings in the area. With direct links to rail, road, sea and air, the community combines business and innovation with quality of life",
    link: "dubai-south",
  },
  {
    id: "a13",
    name: "Jumeirah Lake Towers",
    img: "/assets/areas/jlt.jpg",
    guide:
      "The Jumeirah Lakes Towers is a large development in Dubai, United Arab Emirates which consists of 80 towers being constructed along the edges of three artificial lakes as well as the JLT Embankment of eight towers facing Jumeirah Islands",
    link: "jumeirah-lake-towers",
  },

  {
    id: "a15",
    name: "jumeirah village circle",
    img: "/assets/areas/jvc.jpg",
    guide:
      "Jumeirah Village Circle provides a beautiful, self-contained environment for residents, with all the modern facilities of a city in a tranquil village setting. Designed to cater for the whole family, Jumeirah Village Circle features sports fields, schools and mosques clustered throughout the abundant green parks of the development. The main entry/exit points connecting Jumeirah Village Circle are two on Hessa Street and two on Al Khail Road.",
    link: "jumeirah-village-circle",
  },
  {
    id: "a16",
    name: "al furjan",
    img: "/assets/areas/alfurjan.jpg",
    guide:
      "Al Furjan, or simply Furjan, is a thriving neighbourhood and popular residential sector led by master developer Nakheel. Al Furjan, located between Sheikh Zayed Road and Sheikh Mohammed Bin Zayed Road, strives to create a welcoming villa community for its inhabitants.",
    link: "al-furjan",
  },
  {
    id: "a17",
    name: "Dubai Science Park",
    img: "/assets/areas/Dubai-science-park.jpg",
    guide:
      "Dubai Science Park is a free zone community that serves the entire value chain of the science, health and pharma sectors, fostering an environment that supports research, creativity, innovation and passion, ensuring a supportive ecosystem for businesses to establish sustainable and positive change",
    link: "dubai-science-park",
  },

  {
    id: "a18",
    name: "Emirates Hills",
    img: "/assets/areas/emirates-hills.jpg",
    guide:
      "Prestigious Emirates Hills is a gated residential community, located around the rolling greens of Montgomerie Golf Club Dubai and its neighboring driving range. An upscale hotel overlooks the golf course and has onsite dining choices from gastropubs with terraces to high-end restaurants. Elegant villas with pools line leafy streets, and there are play areas for kids as well as schools and sports facilities.",
    link: "emirates-hills",
  },
  {
    id: "a19",
    name: "Mohammed Bin Rashid City",
    img: "/assets/areas/mbr.jpg",
    guide:
      "Mohammed Bin Rashid Al Maktoum City, District One, a part of master development Meydan, is an exclusive residential destination comprising mansions, villas and apartments. Spread across 45 Million sq. ft., District One is a premium lifestyle destination that is set amidst glorious surroundings, and designed for those who aspire to live an extraordinary life.",
    link: "mohammed-bin-rashid-city",
  },

  {
    id: "a20",
    name: "Meydan",
    img: "/assets/areas/meydan.jpg",
    guide:
      "A modern economic free zone in the heart of Dubai, Meydan is close to the most important landmarks of the emirate, which gives investors several strategic benefits. It offers open office spaces and fully serviced offices, accommodating all types of businesses including commercial, consulting, investment and administrative companies, alongside choices in high-end residences",
    link: "meydan",
  },
  {
    id: "a21",
    name: "palm-Jumeirah",
    img: "/assets/areas/palm-jumeirah.jpg",
    guide:
      "The tree-shaped Palm Jumeirah island is known for glitzy hotels, posh apartment towers and upmarket global restaurants. Food trucks offering snacks like shawarma dot the Palm Jumeirah Boardwalk, popular for its views of the Dubai coastline and the sail-shaped Burj Al Arab hotel. Beach clubs with spas and infinity pools turn into boisterous nightclubs with live DJs in the evening",
    link: "palm-jumeirah",
  },
];

export const developers = [
  {
    id: "d5",
    img: "/assets/developers/emaar.webp",
    brandImg: "/assets/developers/emaar-logo.png",
    link: "emaar",
  },
  {
    id: "d3",
    img: "/assets/developers/damac.webp",
    brandImg: "/assets/developers/damac-logo.png",
    link: "damac",
  },
  {
    id: "d12",
    img: "/assets/developers/danube.webp",
    brandImg: "/assets/developers/danube-logo.png",
    link: "danube-properties",
  },
  {
    id: "d20",
    img: "/assets/developers/ays.webp",
    brandImg: "/assets/developers/ays-logo.png",
    link: "ays-developers",
  },

  {
    id: "d4",
    img: "/assets/developers/ellington.webp",
    brandImg: "/assets/developers/ellington-logo.png",
    link: "ellington-properties",
  },
  {
    id: "d44",
    img: "/assets/developers/arada.jpg",
    brandImg: "/assets/developers/arada-logo.png",
    link: "arada",
  },
  {
    id: "d1",
    img: "/assets/developers/aurora.webp",
    brandImg: "/assets/developers/aurora-logo.png",
    link: "aurora-properties",
  },
  {
    id: "d7",
    img: "/assets/developers/myra.webp",
    brandImg: "/assets/developers/myra-logo.png",
    link: "myra-properties",
  },
  {
    id: "d8",
    img: "/assets/developers/sobha.webp",
    brandImg: "/assets/developers/sobha-logo.png",
    link: "sobha-realty",
  },

  {
    id: "d10",
    img: "/assets/developers/maf.webp",
    brandImg: "/assets/developers/maf-logo.png",
    link: "majid-al-futtaim-properties",
  },
  {
    id: "d11",
    img: "/assets/developers/azizi.webp",
    brandImg: "/assets/developers/azizi-logo.png",
    link: "azizi-developments",
  },

  {
    id: "d13",
    img: "/assets/developers/nshama.webp",
    brandImg: "/assets/developers/nshama-logo.jpg",
    link: "nshama-properties",
  },

  {
    id: "d15",
    img: "/assets/developers/samana.webp",
    brandImg: "/assets/developers/samana-logo.png",
    link: "samana-developers",
  },
  {
    id: "d16",
    img: "/assets/developers/vincitore.webp",
    brandImg: "/assets/developers/vincitore-logo.png",
    link: "vincitore",
  },

  {
    id: "d17",
    img: "/assets/developers/deyaar.webp",
    brandImg: "/assets/developers/deyaar-logo.png",
    link: "deyaar-properties",
  },
  {
    id: "d18",
    img: "/assets/developers/pantheon.webp",
    brandImg: "/assets/developers/pantheon-logo.jpg",
    link: "pantheon-development",
  },
  {
    id: "d19",
    img: "/assets/developers/grovy.webp",
    brandImg: "/assets/developers/grovy-logo.jpg",
    link: "grovy",
  },

  {
    id: "d21",
    img: "/assets/developers/lucky-ayeon.webp",
    brandImg: "/assets/developers/lucky-ayeon-logo.png",
    link: "lucky-ayeon",
  },
  {
    id: "d22",
    img: "/assets/developers/tiger.webp",
    brandImg: "/assets/developers/tiger-logo.jpg",
    link: "tiger-properties",
  },
  {
    id: "d23",
    img: "/assets/developers/bloom.webp",
    brandImg: "/assets/developers/bloom-logo.png",
    link: "bloom-properties",
  },
  {
    id: "d24",
    img: "/assets/developers/lootah.webp",
    brandImg: "/assets/developers/lootah-logo.jpg",
    link: "lootah-properties",
  },

  {
    id: "d26",
    img: "/assets/developers/nakheel.webp",
    brandImg: "/assets/developers/nakheel-logo.png",
    link: "nakheel-properties",
  },
];
