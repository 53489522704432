import "./featured.scss";

import { useState } from "react";
import { data } from "../../../data/data";
import Card from "../cardLoyout/card/Card";
const featuredProperties = data.filter((el) => el.featured);
const recentProperties = data.slice(-6);

const Featured = () => {
  const [value, setValue] = useState(0);

  return (
    <div className="featured">
      <h2>our featured properties</h2>
      <p className="sub">
        ASPECT GLOBAL PROPERTIES HAS THE LARGEST INVENTORY OF APARTMENTS IN THE
        UNITED ARAB EMIRATES.
      </p>
      <div className="tabs">
        <div
          className={!value ? "tabs--tab active" : "tabs--tab"}
          onClick={() => setValue(0)}
        >
          featured
        </div>
        <div
          className={value ? "tabs--tab active" : "tabs--tab"}
          onClick={() => setValue(1)}
        >
          recent
        </div>
      </div>
      {!value ? (
        <div className="tabs--content">
          <div className="cards">
            {featuredProperties.map((p) => (
              <Card card={p} key={p.id} />
            ))}
          </div>
        </div>
      ) : (
        <div className="tabs--content">
          <div className="cards">
            {recentProperties.map((p) => (
              <Card card={p} key={p.id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Featured;
