const CurrencyReducer = (state, action) => {
  switch (action.type) {
    case "USD":
      return { name: "$", type: "USD" };
    case "GBP":
      return { name: "£", type: "GBP" };
    case "EUR":
      return { name: "€", type: "EUR" };
    case "RUB":
      return { name: "₽", type: "RUB" };
    default:
      return { name: "AED", type: "AED" };
  }
};

export default CurrencyReducer;
