import useScrollAnimate from "../../hooks/useScrollAnimate";
import "./chairmanMsg.scss";

const ChairmanMsg = () => {
  const { ref, inView } = useScrollAnimate();
  return (
    <div className="cmmsg">
      <h2>Chairman Message</h2>
      <div
        className={inView ? "cmmsg--container animate" : "cmmsg--container"}
        ref={ref}
      >
        <div className="col">
          <h3>Dear Clients & Partners,</h3>
          <p>
            I am honored to welcome you to Aspect Global Real Estate. As the
            chairman of this esteemed organization, I take immense pride in our
            journey thus far.
          </p>
          <p>
            In the ever-evolving world of real estate, we have established
            Aspect Global Real Estate as a beacon of reliability, trust, and
            excellence. Our commitment to delivering top-notch real estate
            services tailored to your unique needs has been our guiding
            principle.
          </p>
          <p>
            We understand that real estate is not just about properties; it's
            about people and their dreams. We strive to fulfill those dreams by
            providing exceptional service and expertise. Our dedicated team of
            professionals is committed to going the extra mile to ensure your
            experience with us is nothing less than outstanding.
          </p>
          <p>
            We look forward to the future with optimism and excitement as we
            continue to evolve and grow to serve you better. Whether you are
            buying, selling, or renting, you can count on Aspect Global Real
            Estate to be your partner in real estate success.
          </p>
          <p>
            Thank you for choosing Aspect Global Real Estate. Together, we will
            make your real estate goals a reality.
          </p>
          <h3>Warm regards,</h3>

          <h3>
            JASIM AL ALI
            <p style={{ fontWeight: "500" }}>
              Chairman, Aspect Global Real Estate
            </p>
          </h3>
        </div>
        <div className="col">
          <img class="img" src="./assets/chairman.png" alt="chairman" />
        </div>
      </div>
    </div>
  );
};

export default ChairmanMsg;
