import "./actionBar.scss";
import { FaGripHorizontal, FaThList } from "react-icons/fa";
import Select from "react-select";
// import { BsArrowBarDown } from "react-icons/bs";
// import { useState } from "react";
// import classNames from "classnames";



const ActionBar = ({ listView, setSort, setListView, setPageSize }) => {
  const sortOptions = [
    { value: "priceh", label: "Price: Lowest to Highest" },
    { value: "pricel", label: "Price: Highest to Lowest" },
  ];
  const pageOptions = [
    { value: 24, label: 24 },
    { value: 12, label: 12 },
    { value: 6, label: 6 },
  ];
  return (
    <div className="actionbar">
      <div className="elements">
        <div className="element">
          <span>SORT:</span>
          <Select
            name="status"
            options={sortOptions}
            className="banner--select__container"
            placeholder="Sort By Price"
            classNamePrefix="banner--select"
            onChange={(choice) => setSort(choice.value)}
          />
        </div>
        <div className="element">
          <span>SHOW ON PAGE:</span>
          <Select
            name="pages"
            options={pageOptions}
            className="banner--select__container"
            classNamePrefix="banner--select"
            onChange={(choice) => setPageSize(choice.value)}
            defaultValue={pageOptions[0]}
          />
        </div>
        <div className="element">
          <span>VIEW:</span>

          <FaGripHorizontal
            className={!listView ? "icon active" : "icon"}
            onClick={() => setListView(false)}
          />
          <FaThList
            className={listView ? "icon active" : "icon"}
            onClick={() => setListView(true)}
          />
        </div>
      </div>
      {/* <BsArrowBarDown
        className="barIcon"
        onClick={() => {
          setOpenBar(!openBar);
        }}
      /> */}
    </div>
  );
};

export default ActionBar;
