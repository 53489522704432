import { createContext, useState } from "react";
import { data } from "../../../data/data";

const maxPrice = Math.max(...data.map((a) => a.price));
const maxRange = maxPrice > 10000000 ? maxPrice : 10000000;
const INITIAL_SEARCH_STATE = {
  price: [1000, maxRange],
  bedrooms: [0, 20],
  //   locations: [],
  area: "",
  status: "",
  type: "",
};

export const SearchContext = createContext(INITIAL_SEARCH_STATE);

export const SearchContextProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState(INITIAL_SEARCH_STATE);
  const [showChips, setShowChips] = useState(false); //for showing search info chips

  const [openModal, setOpenModal] = useState(false);

  return (
    <SearchContext.Provider
      value={{
        searchValue,
        setSearchValue,
        INITIAL_SEARCH_STATE,
        showChips,
        setShowChips,
        openModal,
        setOpenModal,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
