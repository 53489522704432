import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import "./whatsapp.scss";

const Whatsapp = () => {
  const [openWidget, setOpenwidget] = useState(false);
  return (
    <div className="whatsapp">
      <div className={openWidget ? "widget active" : "widget"}>
        <div className="widget--header">
          <span onClick={() => setOpenwidget(false)}>x</span>
          <p>Hello!</p>
          <p>
            Click on our representative icon below to chat on WhatsApp or send
            us <a href="mailto:sales@aspect-global.com">Email</a>
          </p>
        </div>
        <div className="widget--body">
          <a
            href="https://web.whatsapp.com/send?phone=+971552312811"
            targrt="_blank"
          >
            <img src="/assets/support.png" alt="contact us on whatsapp" />
          </a>
        </div>
        <div className="widget--footer">
          <small>
            Powered by <a href="/">Aspect-Global</a>
          </small>
        </div>
      </div>

      <div className="wtspBtn" onClick={() => setOpenwidget(!openWidget)}>
        <FaWhatsapp className="icon" />
        <span>how can i help you ?</span>
      </div>
    </div>
  );
};

export default Whatsapp;
