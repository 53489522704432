import { areas, data, developers } from "./data";
import { amentiesData } from "./amenties";

export const locationOptions = areas.map((area) => {
  return {
    value: area.name,
    label: area.name.toUpperCase(),
  };
});
export const amentiesOptions = amentiesData.map((el) => ({
  value: el.id,
  label: el.title,
}));

export const roomOptions = [
  {
    value: "studio",
    label: "studio",
  },
  {
    value: 1,
    label: "1BR",
  },
  {
    value: 2,
    label: "2BR",
  },
  {
    value: 3,
    label: "3BR",
  },
  {
    value: 4,
    label: "4BR",
  },
  {
    value: 5,
    label: "5BR",
  },
  {
    value: 6,
    label: "6BR",
  },
  {
    value: 7,
    label: "7BR",
  },
  {
    value: 8,
    label: "8BR",
  },

  {
    value: "office",
    label: "office",
  },
];
export const developerOptions = developers.map((developer) => {
  return {
    value: developer.link,
    label: developer.link.replace("-", " ").toUpperCase(),
  };
});
export const statusOptions = [
  { value: "offplan", label: "OffPlan", color: "#00B8D9" },
  {
    value: "ready to move",
    label: "Ready To Move",
    color: "#ae",
  },
];
// export const typeOptions = [
//   { value: "apartments/flats", label: "Apartments/Flats", color: "#00B8D9" },
//   {
//     value: "townhouses",
//     label: "Town Houses",
//   },
//   {
//     value: "villa/mansion",
//     label: "Villa/Mansion",
//   },
// ];

// const typeOptions = data
//   .filter(
//     (value, index, self) =>
//       index === self.findIndex((t) => t.type === value.type)
//   );
// above one is least perfomant. coz it does loop over loop

export const typeOptions = data
  .reduce((accumulator, current) => {
    if (!accumulator.find((item) => item.type === current.type)) {
      accumulator.push(current);
    }
    return accumulator;
  }, [])
  .map((el) => ({
    value: el.type,
    label: el.type.toUpperCase(),
  }));

// console.log(typeOptions);
