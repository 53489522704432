import { useInView } from "react-intersection-observer";

export function useStickyNav() {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  });

  const sentinel = <div ref={ref} style={{ visibility: "hidden" }} />;

  return {
    sentinel,
    isSticky: !inView,
  };
}
