import "./similarProperties.scss";
import { useCallback } from "react";
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "../../components/cardLoyout/card/Card";

const SimilarProperties = ({ properties }) => {
  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <div className="similar--properties">
      <div className="navigateBar">
        <h3>Similar Properties</h3>
        <div className="nav-arrows">
          <div onClick={handlePrev} className="nav-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 32 52"
            >
              <g fill="#fff">
                <path
                  opacity=".5"
                  d="M31.611 7.646l-6.787-7.057-24.435 25.406 6.787 7.057z"
                ></path>
                <path d="M.389 26.006l6.787-7.058 24.435 25.406-6.787 7.057z"></path>
              </g>
            </svg>
          </div>
          <div onClick={handleNext} className="nav-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 32 52"
            >
              <g fillRule="evenodd" clipRule="evenodd" fill="#fff">
                <path d="M.388 44.354l6.788 7.057 24.436-25.406-6.788-7.057-24.436 25.406z"></path>
                <path
                  opacity=".5"
                  d="M31.612 25.994l-6.788 7.058-24.436-25.406 6.788-7.057 24.436 25.405z"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <Swiper ref={sliderRef} className="property-similar" loop>
        {properties.map((card) => (
          <SwiperSlide key={card.id}>
            <Card card={card} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SimilarProperties;
