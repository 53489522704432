import useScrollAnimate from "../../hooks/useScrollAnimate";
import "./us.scss";

const Us = () => {
  const { ref, inView } = useScrollAnimate();
  return (
    <div className="us">
      <h2>why choose us?</h2>
      <div
        className={inView ? "us--container animate" : "us--container"}
        ref={ref}
      >
        <div className="col">
          <h3>Expertise Knowledge</h3>
          <div className="img">
            <img src="/assets/img1.jpg" alt="" />
          </div>
          <p>
            Our real estate experts possess a deep understanding of the Dubai
            property market, backed by years of experience in the industry.
            Whether you are looking to buy, sell, or invest in a property, our
            team is equipped with the latest market insights, trends, and data,
            empowering you to make well-informed decisions. We believe in
            transparency.
          </p>
        </div>

        <div className="col">
          <h3>Diverse Property Portfolio</h3>
          <div className="img">
            <img src="/assets/img2.jpg" alt="" />
          </div>
          <p>
            Dubai offers a rich tapestry of properties, ranging from luxurious
            apartments with stunning skyline views to lavish villas nestled in
            serene neighborhoods. We take the time to understand your unique
            preferences and requirements, matching you with properties that
            align perfectly with your lifestyle and investment goals.
          </p>
        </div>
        <div className="col">
          <h3>Client-Centric Approach</h3>
          <div className="img">
            <img src="/assets/img3.jpg" alt="" />
          </div>
          <p>
            Our clients are at the heart of everything we do. We prioritize
            building strong, long-lasting relationships by focusing on
            personalized service and attentiveness to your needs. Our dedicated
            team is always available to address your queries, provide guidance,
            and offer solutions tailored to your specific circumstances.
          </p>
        </div>
        <div className="col">
          <h3>Integrity and Trust</h3>
          <div className="img">
            <img src="/assets/img4.jpg" alt="" />
          </div>
          <p>
            Integrity and trust are fundamental principles guiding our
            operations. Honesty, reliability, and ethical conduct are the
            pillars of our company culture. With us, you can be assured that
            your interests are protected, and your dealings are conducted with
            the utmost professionalism.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Us;
