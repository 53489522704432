import React from "react";
import ReactDOM from "react-dom/client";
// import { AuthContextProvider } from "./admin/contexts/AuthContext";

import App from "./App";
import { CurrencyContextProvider } from "./home/contexts/currencyContext/currencyContext";
import { PropertyContextProvider } from "./home/contexts/propertyContext/propertyContext";
import { SearchContextProvider } from "./home/contexts/searchContext/searchContext";
import { SizeContextProvider } from "./home/contexts/sizeContext/SizeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PropertyContextProvider>
      <SearchContextProvider>
        <CurrencyContextProvider>
          <SizeContextProvider>
            {/* <AuthContextProvider> */}
            <App />
            {/* </AuthContextProvider> */}
          </SizeContextProvider>
        </CurrencyContextProvider>
      </SearchContextProvider>
    </PropertyContextProvider>
  </React.StrictMode>
);
