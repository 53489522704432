import "./area.scss";
import { Link } from "react-router-dom";
import { areas } from "../../../data/data";

const Area = () => {
  return (
    <div className="area">
      <div className="area--container">
        <h2>PROPERTIES BY AREAS</h2>
        <div className="areas">
          {areas.map((a) => (
            <div className="box" key={a.id}>
              <Link to={a.link}>
                <button className="btn">View available properties</button>
              </Link>
              <div className="img">
                <img src={a.img} alt="area" />
              </div>
              <p>{a.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Area;
