import { NavLink } from "react-router-dom";
import "./launch.scss";

const Launch = () => {
  return (
    <div className="launching">
      <h2>Launching Soon</h2>
      <NavLink to={"/properties"}>
        <button className="btn">Explore Our Other Properties</button>
      </NavLink>
    </div>
  );
};

export default Launch;
