import { useContext, useEffect, useState } from "react";

import PropertyHandler from "../../components/propertyHandler/PropertyHandler";
import ActionBar from "../../components/actionBar/ActionBar";
import { data } from "../../../data/data";
import { SearchContext } from "../../contexts/searchContext/searchContext";
import { PropertyContext } from "../../contexts/propertyContext/propertyContext";

const Properties = () => {
  const {
    searchValue,
    INITIAL_SEARCH_STATE,
    showChips,
    setShowChips,
    setOpenModal,
  } = useContext(SearchContext);

  const { properties, setProperties } = useContext(PropertyContext);

  // function to find price between given range in search
  function range(a, [min, max]) {
    return a >= min && a <= max;
  }

  /**below function is when there are array of bedrooms and you need to fetch result for all the array value**/
  // function roomRange(a, [min, max]) {
  //   if (Array.isArray(a))
  //     return a.reduce((acc, cur) => acc || (cur >= min && cur <= max), false);
  //   // if (Array.isArray(a)) return Math.min(...a) >= min && Math.max(...a) <= max;
  //   return a >= min && a <= max;
  // }

  //function to check if price and rooms range values are not initial value.
  function compare(searched, initial) {
    return !searched.every((el, i) => el === initial[i]);
  }

  //fetching chips values to be displayed from searchvalue
  const { price, type, status, bedrooms, area } = searchValue;

  // console.log(searchValue);
  // console.log(properties);
  const chipValue = [{ price }, { type }, { status }, { bedrooms }, { area }]
    .filter((el) => el.status !== "")
    .filter((el) => el.type !== "")
    .filter((el) => el.area !== "")
    .filter((el) => compare([el.price], [INITIAL_SEARCH_STATE.price]))
    .filter((el) => compare([el.bedrooms], [INITIAL_SEARCH_STATE.bedrooms]));

  // fetching search results
  const search = (searchValue) =>
    data
      .filter(
        (el) =>
          el.area === (searchValue.area === "" ? el.area : searchValue.area)
      )
      .filter(
        (el) =>
          el.status ===
          (searchValue.status === "" ? el.status : searchValue.status)
      )
      .filter(
        (el) =>
          el.type === (searchValue.type === "" ? el.type : searchValue.type)
      )
      .filter((el) => range(el.bedrooms, searchValue.bedrooms))
      .filter((el) => range(el.price, searchValue.price));

  const [sort, setSort] = useState("name");
  const [listView, setListView] = useState(false);
  const [pageSize, setPageSize] = useState(24);

  //changing property based on search
  useEffect(() => {
    setProperties(search(searchValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  //changing property based on closing chips
  const handleChip = (el) => {
    const chipSearch = Object.assign(searchValue);
    // eslint-disable-next-line no-unused-vars
    const [a, b] = [...el];
    chipSearch[a] = INITIAL_SEARCH_STATE[a];
    setProperties(search(chipSearch));
    // console.log(chipSearch);
  };

  //handling reset button
  const handleClick = () => {
    setShowChips(false);
    setProperties(data.slice());
  };

  return (
    <div className="properties">
      <div className="properties--container">
        {showChips && (
          <>
            <div className="search">
              <span>search results: {properties.length}</span>
              <span onClick={() => setOpenModal(true)}>Make a New Search?</span>
              <span onClick={handleClick} className="clear">
                Clear
              </span>
            </div>
            <div className="chips">
              {/* {Object.entries(searchValue)
                .filter((el) => el[1] !== "")
                .map((el, index) => (
                  <div className="chip" key={index}>
                    {el[0]} :{" "}
                    {Array.isArray(el[1])
                      ? el[0] === "price"
                        ? `AED ${el[1][0]} - AED ${el[1][1]}`
                        : `${el[1][0]} - ${el[1][1]}`
                      : el[1]}{" "}
                    <span onClick={() => handleChip(el)}>x</span>
                  </div>
                ))} */}

              {chipValue
                .map((el) => Object.entries(el).flat(1))
                .map((el, index) => (
                  <div className="chip" key={index}>
                    {el[0]}:
                    {Array.isArray(el[1])
                      ? el[0] === "price"
                        ? `AED ${el[1][0]} - AED ${el[1][1]}`
                        : `${el[1][0]} - ${el[1][1]}`
                      : el[1]}{" "}
                    <span onClick={() => handleChip(el)}>x</span>
                  </div>
                ))}
            </div>
          </>
        )}
        <h2>{properties.length > 0 ? "properties" : ""}</h2>
        <ActionBar
          listView={listView}
          setSort={setSort}
          setListView={setListView}
          setPageSize={setPageSize}
        />

        <PropertyHandler
          view={listView}
          sort={sort}
          pageSize={pageSize}
          properties={properties}
        />
      </div>
    </div>
  );
};

export default Properties;
