import { useContext } from "react";
import { SearchContext } from "../../contexts/searchContext/searchContext";
import BannerSearch from "../header/bannerSearch/BannerSearch";
import "./searchModal.scss";
import { AiOutlineClose } from "react-icons/ai";

const SearchModal = () => {
  const { setOpenModal } = useContext(SearchContext);

  return (
    <div className="searchModal">
      <div className="backdrop" onClick={() => setOpenModal(false)}></div>
      <div className="form--container">
        <AiOutlineClose className="close" onClick={() => setOpenModal(false)} />

        <BannerSearch />
      </div>
    </div>
  );
};

export default SearchModal;
