import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import the emailjs-com library
import "./form.css";

// Configure emailjs with your User ID
emailjs.init("ZOvc_f5THDM-XoSym"); // Replace YOUR_USER_ID with your actual User ID

const ListingForm = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gnyh1ph",
        "template_3a3hsyq",
        e.target,
        "ZOvc_f5THDM-XoSym"
      )
      .then(
        (result) => {
          console.log("Email sent successfully");
          setIsEmailSent(true);
          setIsFormVisible(false);
        },
        (error) => {
          console.error("Email sending failed", error);
        }
      );
  };

  return (
    <div className="maindiv">
      {isEmailSent ? (
        <div className="email-sent-message">
          <h2>Email Sent Successfully!</h2>
        </div>
      ) : (
        <div className="ppform">
          <h1>List Your Property With Us</h1>
          <p>
            When leasing or buying your property in Dubai, you’ll want to get
            the best price possible. If you own one or more Dubai properties,
            register with us and let your property benefit from professional
            property marketing services that result in the best possible leasing
            price. Whether you are leasing commercial or residential property,
            our knowledgeable Dubai real estate experts are on the case.
          </p>
          <h3>Landlord / Owner Details</h3>
          <form onSubmit={sendEmail}>
            <label htmlFor="fname">First name:</label>
            <input
              type="text"
              name="fname"
              placeholder="Your first Name"
              required
            />
            <label htmlFor="lname">Last name:</label>
            <input
              type="text"
              name="lname"
              placeholder="Your last Name"
              required
            />
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <label htmlFor="number">Mobile Number:</label>
            <input
              type="tel"
              name="number"
              placeholder="Your Mobile number"
              required
            />
            <label htmlFor="country">Country:</label>
            <input
              type="text"
              name="country"
              placeholder="Your Country"
              required
            />
            <h3>Property Details</h3>
            <label htmlFor="City">City:</label>
            <input type="text" name="city" placeholder="City" required />
            <label htmlFor="Community">Community:</label>
            <input
              type="text"
              name="community"
              placeholder="Community"
              required
            />
            <label htmlFor="PropertyAddress">Property Address:</label>
            <input
              type="text"
              name="propertyAddress"
              placeholder="Property Address"
              required
            />
            <label htmlFor="PropertyType">Property Type:</label>
            <select name="propertyType" id="propertyType">
              <option value="apartment">Apartment</option>
              <option value="villa">Villa</option>
              <option value="townhouse">Townhouse</option>
              <option value="penthouse">Penthouse</option>
              <option value="duplex">Duplex</option>
              <option value="duplex">Office</option>
            </select>
            <label htmlFor="interest">I am interested in</label>
            <div className="interestdiv">
              <label className="checkbx" htmlFor="sellmyproperty">
                <input
                  type="checkbox"
                  id="sellmyproperty"
                  name="sellmyproperty"
                />
                Selling My Property
              </label>
              <label className="checkbx" htmlFor="rentmyproperty">
                <input
                  type="checkbox"
                  id="rentmyproperty"
                  name="rentmyproperty"
                />
                Rent my property
              </label>
              <label className="checkbx" htmlFor="managemyproperty">
                <input
                  type="checkbox"
                  id="managemyproperty"
                  name="managemyproperty"
                />
                Manage My property
              </label>
            </div>
            <label htmlFor="bedrooms">No. of bedrooms:</label>
            <select name="bedrooms" id="bedrooms">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <label htmlFor="bathroom">No. of bathrooms:</label>
            <select name="bathroom" id="bathroom">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <label htmlFor="PropertyAddress">Size in sqft:</label>
            <input
              type="text"
              name="size"
              placeholder="Szie in sqft"
              required
            />
            <label htmlFor="carparking">Car Park:</label>
            <div className="interestdiv">
              <label className="checkbx2" htmlFor="yes">
                <input type="radio" id="yes" name="carparking" value="Yes" />
                Yes
              </label>
              <label className="checkbx2" htmlFor="no">
                <input type="radio" id="no" name="carparking" value="No" />
                No
              </label>
            </div>
            <label htmlFor="propertyimage">
              Upload Property Images | Title Deeds | Passport (Optional) jpg |
              png | pdf - (Max size 15 MB):
            </label>
            <input
              type="file"
              multiple
              accept="image/jpeg,image/png,application/pdf"
              name="propertyImages"
              disabled
            />
            <textarea
              id="message"
              name="message"
              rows="4"
              cols="50"
              placeholder="MESSAGE"
            ></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ListingForm;
