import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "../../hooks/usePagination";
import "./pagination.scss";
import { BiLeftArrow } from "react-icons/bi";
import { useEffect } from "react";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [currentPage]);

  if (
    currentPage === 0 ||
    paginationRange.length < 2
    // || totalCount < pageSize
  ) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <BiLeftArrow className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, i) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item dots" key={i}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={i}
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <BiLeftArrow className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;

//************material ui pagination logic below************
// import { Pagination } from "@mui/material";

/* const firstIndex = 0;
    const [page, setPage] = useState(1);
    

    const [data, setData] = useState(properties.slice(firstIndex, pageSize));
    useEffect(() => {
      setData(properties.slice(0, pageSize));
    }, [pageSize, properties]);

    const handleChange = (event, value) => {
      setPage(value);

      setData(
        properties.slice(firstIndex + pageSize * (value - 1), pageSize * value)
      );
    };*/
// import React from 'react'

// const Pagination = () => {
//   return (
//       {properties.length > pageSize && (
//     <div className="pagination">
//     <Pagination
//       count={Math.ceil(properties.length / pageSize)}
//       page={page}
//       onChange={handleChange}
//       variant="outlined"
//       shape="rounded"
//       // size="large"
//       color="primary"
//     />
//   </div>
// )}
//   )
// }

// export default Pagination
