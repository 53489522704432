import "./form.scss";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide, ToastContainer } from "react-toastify";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

const Form = () => {
  const form = useRef();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // toastify data
  const toastOptions = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    bodyClassName: "toast-font-size",
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async () => {
    try {
      await emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (result) => {
            // console.log(result);
            toast(
              "Thank you for contacting Aspect Global Real Estate. We will get back to you shortly.",
              toastOptions
            );
            reset();
          },
          (error) => {
            toast(
              "Sorry, There's an error occured. Try again later ",
              toastOptions
            );
            // console.log(error);
          }
        );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <form className="contactForm" onSubmit={handleSubmit(onSubmit)} ref={form}>
      <ToastContainer />
      <div className="input-group">
        <label htmlFor="name">name</label>
        <input
          type="text"
          name="name"
          {...register("name", {
            required: {
              value: true,
              message: "Please enter your name",
            },
            maxLength: {
              value: 30,
              message: "Please use 30 characters or less",
            },
          })}
        />
        {errors.name && (
          <span className="errorMessage">{errors.name.message}</span>
        )}
      </div>
      <div className="input-group">
        <label htmlFor="email">email</label>
        <input
          type="email"
          name="email"
          {...register("email", {
            required: true,
            pattern:
              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          })}
        />

        {errors.email && (
          <span className="errorMessage">enter valid email address</span>
        )}
      </div>
      <div className="input-group">
        <label htmlFor="name">phone number</label>
        <input
          type="tel"
          name="phone"
          {...register("phone", {
            required: {
              value: true,
              message: "Please enter your valid mobile number",
            },
          })}
        />
        {errors.phone && (
          <span className="errorMessage"> {errors.phone.message}</span>
        )}
      </div>
      <div className="input-group">
        <label htmlFor="name">Message</label>
        <textarea
          type="text"
          name="name"
          {...register("message", {
            required: true,
          })}
        />
        {errors.message && (
          <span className="errorMessage">Please enter some message</span>
        )}
      </div>
      <button className="btn">Submit</button>
    </form>
  );
};

export default Form;
