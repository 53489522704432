import React from "react";
import Featured from "../../components/featured/Featured";
import Header from "../../components/header/Header";
import Us from "../../components/us/Us";
import ChairmanMsg from "../../components/chairmanmsg/chairmanMsg";
import ListSection from "../../components/listsection/ListSection";

const Home = () => {
  return (
    <>
      <Header />
      <ListSection />
      <Featured />
      <Us />
      <ChairmanMsg />
    </>
  );
};

export default Home;
