import "./card.scss";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { CurrencyContext } from "../../../contexts/currencyContext/currencyContext";

const LoadingSpinner = () => {
  return <div className="loading-spinner"></div>;
};

const Card = ({ card }) => {
  const { currency, loading } = useContext(CurrencyContext);
  const priceString = (p) => {
    return `${currency.name} ${(p * currency.unit)
      .toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      })

      .split(",")
      .join(" ")}`;
  };
  return (
    <div className="card">
      <div className="tag">{card.status}</div>
      <div className="img">
        <div className="details">
          <div>
            <span>
              {card.roomType === "studio"
                ? "Studio"
                : ` Bedrooms: ${card.bedrooms.toString()}`}
            </span>
            <span>Type: {card.type}</span>
          </div>
          <Link to={`/properties/${card.link}`}>
            <button className="btn">view details</button>
          </Link>
        </div>
        <img
          src={
            card.img
              ? card.img
              : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
          }
          alt={card.title}
        />
      </div>
      <Link to={`/properties/${card.link}`} className="content">
        <h3>{card.title}</h3>
        <p>{card.area}</p>
      </Link>
      <span className="price">
        {card.price ? (
          loading ? (
            <LoadingSpinner />
          ) : (
            priceString(card.price)
          )
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default Card;
