import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../home/components/footer/Footer";
import Nav from "../home/components/navbar/Nav";
import SearchModal from "../home/components/searchModal/SearchModal";
import Topbar from "../home/components/topbar/Topbar";
import Whatsapp from "../home/components/whatsapp/Whatsapp";
import { SearchContext } from "../home/contexts/searchContext/searchContext";
import ScrollToTop from "../ScrollToTop";

const RootLayout = () => {
  const { openModal } = useContext(SearchContext);
  return (
    <>
      <Whatsapp />
      <ScrollToTop />
      {openModal && <SearchModal />}
      <div className="header-top">
        <Topbar />
        <Nav />
      </div>

      <Outlet />
      <Footer />
    </>
  );
};

export default RootLayout;
