import React, { useState } from "react";
import { data } from "../../../data/data";
import ActionBar from "../../components/actionBar/ActionBar";
import PropertyHandler from "../../components/propertyHandler/PropertyHandler";

const Sale = () => {
  const properties = data.filter((p) => !p.rent);
  console.log(properties);
  const [sort, setSort] = useState("name");
  const [listView, setListView] = useState(false);
  const [pageSize, setPageSize] = useState(24);
  return (
    <div className="properties">
      <div className="properties--container">
        <h2>properties for Sale</h2>
        <ActionBar
          listView={listView}
          setSort={setSort}
          setListView={setListView}
          setPageSize={setPageSize}
        />

        <PropertyHandler
          view={listView}
          sort={sort}
          pageSize={pageSize}
          properties={properties}
        />
      </div>
    </div>
  );
};

export default Sale;
