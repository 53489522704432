import { useInView } from "react-intersection-observer";

function useScrollAnimate() {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  });

  return {
    ref: ref,
    inView,
  };
}
export default useScrollAnimate;
