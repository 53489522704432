import { useContext, useEffect, useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import { SearchContext } from "../../contexts/searchContext/searchContext";
import { useStickyNav } from "../../hooks/useStickyNav";
import { RiMenuFoldFill } from "react-icons/ri";
import classnames from "classnames";
import { MdOutlineArrowDropDown } from "react-icons/md";
import "./nav.scss";

const Nav = () => {
  const menu = useRef(null);
  const { setOpenModal, openModal } = useContext(SearchContext);
  const { sentinel, isSticky } = useStickyNav();
  const [menuDrawer, setMenuDrawer] = useState(false);
  const [openSubLink, setOpenSubLink] = useState(false);

  const location = useLocation();
  const closeOpenMenus = (e) => {
    if (menu.current && openSubLink && !menu.current.contains(e.target)) {
      setOpenSubLink(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);
  useEffect(() => {
    setMenuDrawer(false);
  }, [location.pathname]);

  return (
    <>
      <nav className={isSticky ? "nav sticky" : "nav"}>
        <div className="nav--container">
          <Link to={"/"} className="logo">
            {isSticky ? (
              <img src="/logoAlt.png" alt="property logo" />
            ) : (
              <img src="/logo.png" alt="property logo" />
            )}
          </Link>

          {/* className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          > */}
          <ul
            className={classnames("nav--links", {
              active: menuDrawer,
            })}
          >
            <li className="nav--links__link">
              <NavLink
                to={"/"}
                className={({ isActive }) => (isActive ? "navActive" : "")}
                end
              >
                Home
              </NavLink>
            </li>
            <li className="nav--links__link">
              <NavLink
                to={"/areas"}
                className={({ isActive }) => (isActive ? "navActive" : "")}
                end
              >
                Area
              </NavLink>
            </li>

            <li className="nav--links__link" ref={menu}>
              <span onClick={() => setOpenSubLink(!openSubLink)}>
                properties{" "}
                <MdOutlineArrowDropDown
                  className={openSubLink ? "arrowIcon rotate" : "arrowIcon"}
                />
              </span>

              <ul className={openSubLink ? "sub--links active" : "sub--links"}>
                <li className="sub--link">
                  <NavLink
                    to={"/listingform"}
                    onClick={() => setOpenSubLink(false)}
                    className={({ isActive }) =>
                      isActive ? "subLinkActive" : ""
                    }
                    end
                  >
                    List Your Property
                  </NavLink>
                </li>
                <li className="sub--link">
                  <NavLink
                    to={"/properties"}
                    onClick={() => setOpenSubLink(false)}
                    className={({ isActive }) =>
                      isActive ? "subLinkActive" : ""
                    }
                    end
                  >
                    View All
                  </NavLink>
                </li>
                <li className="sub--link">
                  <NavLink
                    to={"/properties-for-rent"}
                    onClick={() => setOpenSubLink(false)}
                  >
                    For Rent
                  </NavLink>
                </li>
                <li className="sub--link">
                  <NavLink
                    to="/properties-for-sale"
                    onClick={() => setOpenSubLink(false)}
                  >
                    For Sale
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* <li className="nav--links__link">
              <NavLink
                to={"/properties-for-rent"}
                className={({ isActive }) => (isActive ? "navActive" : "")}
                end
              >
                For Rent
              </NavLink>
            </li> */}
            <li className="nav--links__link">
              <NavLink
                to={"/developers"}
                className={({ isActive }) => (isActive ? "navActive" : "")}
                end
              >
                Developers
              </NavLink>
            </li>
            <li className="nav--links__link">
              <NavLink
                to={"/contact"}
                className={({ isActive }) => (isActive ? "navActive" : "")}
                end
              >
                Contact Us
              </NavLink>
            </li>
            <li
              className="nav--links__link"
              onClick={() => setOpenModal(!openModal)}
            >
              <FaSearch className="searchIcon" />
            </li>
          </ul>
          <div
            className="searchIconOuter"
            onClick={() => setOpenModal(!openModal)}
          >
            <FaSearch />
          </div>

          <RiMenuFoldFill
            className={classnames("menu-icon", {
              active: menuDrawer,
            })}
            onClick={() => setMenuDrawer(!menuDrawer)}
          />
        </div>
      </nav>
      {sentinel}
    </>
  );
};

export default Nav;
