import PropertyPrint from "../../components/propertyPrint/PropertyPrint";
import { useReactToPrint } from "react-to-print";
import React, { useRef, useState } from "react";
import "./actionButtons.scss";
import { FaPrint, FaShareSquare } from "react-icons/fa";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
} from "react-share";

const ActionButtons = ({ property, amenties }) => {
  const [share, setShare] = useState(false);
  const url = window.location.href;

  const pageStyle = `
  
    @page { 
      size: auto;  margin: 25mm ; } @media print { body { -webkit-print-color-adjust: exact; } }
    @media print {
      .propertyPrint {
  
      page-break-after: always;
      }
    }
    `;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      return componentRef.current;
    },
    copyStyles: true,
    pageStyle: pageStyle,
    bodyClass: "print",
    documentTitle: `${property.title}-${property.area}`,
  });
  const handleShare = () => {
    setShare(!share);
  };

  // amenties is temporary
  return (
    <>
      <div className="action--btns">
        <button className="action--btns__btn" onClick={handlePrint}>
          <FaPrint className="icon" />
          print
        </button>
        <div className="share-btn">
          <button className="action--btns__btn" onClick={handleShare}>
            <FaShareSquare className="icon" />
            <span>share</span>
          </button>

          <div className={share ? "social--btns active" : "social--btns"}>
            <EmailShareButton
              subject={`Check this Property ${property.title} in ${property.area}`}
              body={property.detail}
              url={url}
            >
              <EmailIcon className="social-icon" />
            </EmailShareButton>
            <FacebookShareButton
              quote={property.detail}
              url={url}
              hashtag="#aspectGlobalRealEstate"
            >
              <FacebookIcon className="social-icon" />
            </FacebookShareButton>
            <WhatsappShareButton
              title={`Check this Property ${property.title} in ${property.area}`}
              url={url}
            >
              <WhatsappIcon className="social-icon" />
            </WhatsappShareButton>
            <TwitterShareButton
              title={`Check this Property ${property.title} in ${property.area}, `}
              // via="aspect-global.com"
              hashtags={["aspectGlobalRealEstate"]}
              url={url}
            >
              <TwitterIcon className="social-icon" />
            </TwitterShareButton>
            <LinkedinShareButton
              title={`Check this Property ${property.title} in ${property.area}`}
              url={<a href={url}>url</a>}
              source="www.aspect-global.com"
              summary={property.detail}
            >
              <LinkedinIcon className="social-icon" />
            </LinkedinShareButton>
          </div>
        </div>
      </div>

      <PropertyPrint
        property={property}
        amenties={amenties}
        ref={componentRef}
      />
    </>
  );
};

export default ActionButtons;
