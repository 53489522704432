import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./propertySlider.scss";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { FaTag } from "react-icons/fa";

const PropertySlider = ({ images, type, title }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState();

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        className="property-swiper"
      >
        <div className="tag">
          <FaTag className="icon" /> For {type}
        </div>
        {images.map((el, index) => (
          <SwiperSlide key={index}>
            <img src={el} alt="123" />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={5}
        slidesPerView={5}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="property-slider-thumbs"
      >
        {images.map((el, index) => (
          <SwiperSlide key={index}>
            <div className="property-slider-thumbs-wrapper">
              <img src={el} alt={`${title} pic-${index}`} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default PropertySlider;
