import { FaEnvelope, FaPhoneAlt, FaRegAddressCard } from "react-icons/fa";
import "./contact.scss";
import Form from "./form/Form";

const Contact = () => {
  return (
    <div className="contact ">
      <div className="contact--container">
        <div className="content">
          <div className="left">
            <h3>contact us</h3>
            <h4>Dubai office</h4>

            <ul>
              <li>
                <a href="mailto:sales@aspect-global.com">
                  <FaEnvelope className="icon" />
                  sales@aspect-global.com
                </a>
              </li>
              <li>
                <a href="tel:+97145708788">
                  <FaPhoneAlt className="icon" /> +971 4 570 8788
                </a>
              </li>
              <li>
                <FaRegAddressCard className="icon" /> Tower 5B, Al Safa Park
                Complex, Dubai - United Arab Emirates
              </li>
            </ul>
          </div>

          <Form />
        </div>
        <div className="map">
          <iframe
            title="aspect-business-group"
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d57771.35103797561!2d55.20688564541856!3d25.179287055765037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m5!1s0x3e5f69779644d3a9%3A0xf38fdf33a29ad47e!2sAspect%20Global%20Real%20Estates%20LLC%20-%20Al%20Safa%20-%20Al%20Safa%201%20-%20Dubai!3m2!1d25.1792135!2d55.2419051!4m0!5e0!3m2!1sen!2sae!4v1676299010842!5m2!1sen!2sae"
            width="100%"
            height="450"
            frameBorder="0"
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
