import "./developers.scss";
import { developers } from "../../../data/data";
import { Link } from "react-router-dom";

const Developers = () => {
  const nocDevelopers = [
    "emaar",
    "damac",
    "danube-properties",
    "ays-developers",
    "ellington-properties",
  ];

  return (
    <div className="developers">
      <h2>search property by developers</h2>
      <div className="dCards">
        {developers.map((el) => (
          <div className="dcard" key={el.id}>
            <div className="img">
              <img src={el.img} alt={el.link.toUpperCase()} />
            </div>
            <div className="brand">
              <img src={el.brandImg} alt={el.link.toUpperCase()} />
            </div>
            {nocDevelopers.includes(el.link) ? (
              <Link to={el.link}>
                <button className="btn">view all properties</button>
              </Link>
            ) : (
              <Link to={"/developers/launching_soon"}>
                <button className="btn">view all properties</button>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Developers;
