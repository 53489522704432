import { createContext, useEffect, useReducer, useState } from "react";
import CurrencyReducer from "./currencyReducer";
const API_KEY = "7Chomh6XWpXBZIWlSX0kVpiphlnfVoVn";

const INITIAL_STATE = {
  name: "AED",
  type: "AED",
};

export const CurrencyContext = createContext(INITIAL_STATE);

export const CurrencyContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CurrencyReducer, INITIAL_STATE);
  const [unit, setUnit] = useState(1);
  const [changeCurrency, setChangeCurrency] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", API_KEY);

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const data = async () => {
      setLoading(true);
      await fetch(
        `https://api.apilayer.com/fixer/convert?to=${state.type}&from=AED&amount=1`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => setUnit(JSON.parse(result).result))
        .catch((error) => console.log("error", error));
      setLoading(false);
    };

    if (changeCurrency) data();
  }, [changeCurrency, state]);
  // setUnit(JSON.parse(result).result))

  // console.log(unit);

  return (
    <CurrencyContext.Provider
      value={{
        currency: { unit, ...state },
        dispatch,
        // fetchApi,
        changeCurrency,
        setChangeCurrency,
        loading,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
